import {
  UserMatchErrorResponse,
  UserStateErrorTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { MockUtils } from '../../mock-utils/mock-utils';

export const mockErrorResponse: UserMatchErrorResponse = {
  successful: false,
  error_message: 'Error response message',
  error_type: MockUtils.randomArray(Object.values(UserStateErrorTypeEnum)),
};
