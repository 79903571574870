import { EventEmitter, Injectable } from '@angular/core';
import { ValidationErrorEvent } from '../../../models/validation-error-event';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorMessageService {
  validationErrorEvent = new EventEmitter<ValidationErrorEvent>();

  nameMaps = new Map([['landlord', 'Home telephone']]);

  mapControlToFriendlyName(control: string) {
    return this.nameMaps[control] ?? control;
  }
}
