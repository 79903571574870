import {
  CmsComponentMapping,
  CORE_CMS_COMPONENT_MAPPING,
} from '@domgen/dgx-fe-cms';
import { UpsellContainerComponent } from '../components/upsell-container.component';
import { LogoutComponent } from '../pages/logout/logout.component';

export const myAccountCmsComponentMapping: CmsComponentMapping = {
  ...CORE_CMS_COMPONENT_MAPPING,
  'dgx-myaccount:pages/logout': LogoutComponent,
  'dgx-myaccount:components/upsell-card-block': UpsellContainerComponent,
};
