import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  Injector,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { reducer } from '@athome-myaccount/my-account/data-access-login';
import {
  AccountService,
  AppProvidersConfig,
  AppProvidersConfigToken,
  ErrorEffects,
  GA_CLIENT,
} from '@athome-myaccount/my-account/data-access-shared';
import {
  ErrorBaseComponent,
  ErrorCtaComponent,
  FormBuilderModule,
  GenericErrorComponent,
  HoldingPageComponent,
  MyAccountFeatureSharedModule,
  PageNotFoundComponent,
  ResetStateComponent,
  ServerErrorComponent,
  UnAuthorizedErrorComponent,
  UnAuthorizedErrorHandlerInterceptor,
  UserAuthGuard,
} from '@athome-myaccount/my-account/feature-shared';
import {
  Environment,
  seoConfigFactory,
} from '@athome-myaccount/my-account/util-core';
import { EffectsModule } from '@ngrx/effects';
/* NgRx */
import { StoreModule } from '@ngrx/store';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Hammer from 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
// eslint-disable-next-line max-len
import { DOMAIN_ID } from '@domgen/dgx-fe-common';
import { DOMAIN_CONFIG, ENVIRONMENT } from '@domgen/dgx-fe-config';
import { SEO_CONFIG } from '@domgen/dgx-fe-seo';
import { appInitializerFactory } from './app.initializer';
import { CoreModule } from './core/core.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const gaTagsTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).gaTagsConfig;
};

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pan: {
      direction: Hammer.DIRECTION_HORIZONTAL,
      threshold: 1,
    },
    swipe: { velocity: 0.4, threshold: 20 }, // override default settings
  };
}

@NgModule({
  declarations: [
    PageNotFoundComponent,
    GenericErrorComponent,
    ErrorBaseComponent,
    ErrorCtaComponent,
    ServerErrorComponent,
    UnAuthorizedErrorComponent,
    ErrorBaseComponent,
    ResetStateComponent,
    HoldingPageComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MyAccountFeatureSharedModule,
    HttpClientModule,
    FlexLayoutModule,
    FormBuilderModule,
    StoreModule.forFeature('user', reducer),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule.forRoot(ENVIRONMENT),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: false,
        },
      }
    ),
    EffectsModule.forRoot([ErrorEffects]),
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnAuthorizedErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: GA_CLIENT,
      useFactory: gaTagsTokenFactory,
      deps: [Injector],
    },
    {
      provide: DOMAIN_ID,
      useFactory: (domainConfig: any): string => domainConfig.domainId,
      deps: [DOMAIN_CONFIG],
    },
    {
      provide: SEO_CONFIG,
      useFactory: seoConfigFactory,
      deps: [ENVIRONMENT, DOMAIN_CONFIG],
    },
    CookieService,
    AccountService,
    UserAuthGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AoDmaFeatureShellModule {
  static forRoot(
    environment: Environment,
    appConfig: AppProvidersConfig
  ): ModuleWithProviders<AoDmaFeatureShellModule> {
    return {
      ngModule: AoDmaFeatureShellModule,
      providers: [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: AppProvidersConfigToken, useValue: appConfig },
      ],
    };
  }
}
