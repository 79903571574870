import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollToElementDirective } from './scroll-to-element.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollToElementDirective],
  exports: [ScrollToElementDirective],
})
export class ScrollToElementDirectiveModule {}
