/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  LogIn,
  LoginService,
} from '@athome-myaccount/my-account/data-access-login';
import { ScriptLoaderService } from '@athome-myaccount/my-account/data-access-shared';
import {
  User,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private cookieService: CookieService,
    private scriptsService: ScriptLoaderService,
    private store: Store<any>
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let user: User;
    const userCookie: string = this.cookieService.get('AWS_IDT');
    const { journey } = route.queryParams;
    const quickbooking = journey === 'quickbooking';
    user = this.loginService.getApplicationUser();

    if (userCookie && !user && !quickbooking) {
      const decodedUser: User = jwt_decode(userCookie);
      this.store.dispatch(new LogIn({ decodedUser }));
      user = this.loginService.getApplicationUser();
    }

    if (quickbooking) {
      this.router.navigate(['my-account/quickbooking/my-plans'], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
      return false;
    }

    const isUserVerificationComplete =
      this.loginService.getUserVerificationCompleteStatus();
    return user && userCookie
      ? this.handleLoggedInUser(user, isUserVerificationComplete)
      : this.handleLoggedOffUser();
  }

  private handleLoggedOffUser(): boolean {
    this.router.navigateByUrl('my-account');
    return false;
  }

  private handleLoggedInUser(
    user: User,
    isUserVerificationComplete: boolean
  ): boolean {
    if (
      this.scriptsService.checkIsComingFromMobile() ||
      (user && user['custom:user_state'] === UserStateTypeEnum.COMPLETE)
    ) {
      return true;
    } else if (isUserVerificationComplete) {
      return true;
    } else {
      this.loginService.processUserAuthorizationSuccessResponse(
        user['custom:user_state']
      );
      return false;
    }
  }
}
