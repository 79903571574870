import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RemoteData } from 'ngx-remotedata';
import { filter, mergeMap, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaHttpClient {
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private httpClient: HttpClient
  ) {}

  private getStandardRecaptchaTokenHeaders(
    recaptchaToken: string,
    requestHeaders: any
  ): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
      'request-source': 'DandGUK',
      'request-action': 'MyAccount',
      'X-Recaptcha-Token': recaptchaToken,
      ...requestHeaders,
    });
  }

  post(
    url: string,
    body: any,
    requestHeaders: null | { [key: string]: string | boolean | number } = {},
    httpRequestProperties: null | {
      [key: string]: string | boolean | number;
    } = {}
  ): Observable<RemoteData<any, HttpErrorResponse>> {
    return this.recaptchaV3Service.execute('getToken').pipe(
      take(1),
      filter((recaptchaToken) => !!recaptchaToken),
      mergeMap((recaptchaToken) => {
        const httpHeaders = {
          headers: this.getStandardRecaptchaTokenHeaders(
            recaptchaToken,
            requestHeaders
          ),
        };
        return this.httpClient.post<RemoteData<any, HttpErrorResponse>>(
          url,
          body,
          { ...httpHeaders, ...httpRequestProperties }
        );
      })
    );
  }
}
