import { PlanActionButtonTypeEnum } from '../enums/applianceTypeEnum';
import { PlanType } from '../state/plan-detail.types';

export interface ApplianceViewModel {
  type: string;
  code?: string;
  createdOn: string;
  brand: string;
  model: string;
  modelNumber: string;
  icon: string;
  image: string;
  planNo: string;
  actionButtons: Array<PlanActionButtonTypeEnum>;
  planState: string;
  parentPlanNumber?: string;
  orderByPlanStateComparerValue: number;
}

export interface Appliance {
  applianceCode: string;
  brandCode: string;
  purchaseDate: Date;
  purchasePrice: number;
  working: boolean;
  identifiers: Identifier[];
  manufacturer?: string;
}

export interface Identifier {
  identifierType: string;
  identifier: string;
}

export interface ProcessedAppliance {
  appliance: ApplianceViewModel;
  selectedAction: PlanActionButtonTypeEnum;
}

export interface ProcessedPlan {
  plan: Plan;
  selectedAction: PlanActionButtonTypeEnum;
}

export interface Claim {
  id: string;
  status: string;
  date: string;
}

export interface Plan {
  plan_number: string;
  plan_state: string;
  plan_note?: string;
  plan_start: string;
  plan_expiry: string;
  plan_owner_type: PlanType;
  plan_contract_product_type?: string;
  plan_contract_type: string;
  parent_plan_number?: string;
  parent_plan_state?: string;
  repair_action: string;
  service_action: string;
  open_repair_id?: string;
  open_service_id?: string;
  // in_warranty_contact_name?: string, // TODO WL: These were missing from the getPlans API response so I added them here uncommented for now
  // in_warranty_contact_tel?: string,
  item: Item;
}

export interface CrossSell {
  decision?: boolean;
  store?: string;
  target_request_action?: string;
  target_request_source?: string;
}
export interface CrossSellPD {
  hasPD?: boolean;
}

export type CrossSellProductTypeCheck = CrossSell | CrossSellPD;

export interface GetPlansResponse {
  plans: Plan[];
  cross_sell?: CrossSell;
}

export interface Item {
  manufacturer: string;
  model: string;
  model_number: string;
  code: string;
  code_description: string;
}

export interface ApiResponse<R> {
  // TODO WL: Duplicate interface, might want to move this to util-core
  status: string;
  result: R;
}
