import {
  LoginErrorResponse,
  UserStateErrorTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { MockUtils } from '../../mock-utils/mock-utils';

export const mockLoginErrorResponse: LoginErrorResponse = {
  successful: true,
  error_message: 'Login Error',
  error_type: MockUtils.randomArray(Object.values(UserStateErrorTypeEnum)),
};
