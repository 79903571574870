import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { UnHandledError } from './global-error-handler.actions';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    this.ignoreExceptionOfRemoteData(error);
    console.error(error);
    const store = this.injector.get(Store);
    store.dispatch(new UnHandledError());
  }

  // At some point we have received a weird case of exception
  // Caused by RemoteData lib and Quantum Metrics not working together
  // This is a fail safe to prevent the error just in case
  ignoreExceptionOfRemoteData(error: Error | HttpErrorResponse | string): void {
    if (typeof error === 'string') {
      const errorAsString = error;
      if (errorAsString.includes('is not a RemoteData<T> instance')) {
        return;
      }
    }
  }
}
