import {
  SuccessResponse,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { MockUtils } from '../../mock-utils/mock-utils';

export const mockSuccessResponse: SuccessResponse = {
  successful: true,
  state: MockUtils.randomArray(Object.values(UserStateTypeEnum)),
};
