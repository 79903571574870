/* eslint-disable @typescript-eslint/no-empty-interface */

export interface ApiResponse<R> {
  // TODO WL: Might want to move this to util-core
  status: string;
  result: R;
}

/*================================================================================
  CREATE ACCOUNT
================================================================================*/

/*================================================================================
  EXTENDS
================================================================================*/
export interface SuccessResponse {
  successful: boolean;
  state: string;
}

export interface ErrorResponse {
  successful: boolean;
  error_message: string;
  error_type: string;
}

/*================================================================================
  MISC
================================================================================*/

export interface MatchPersonalDetailsPayload {
  firstname: string;
  surname: string;
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  token: string;
  password: string;
}

export interface SignUpAndMatchPayload {
  firstname: string;
  password: string;
  surname: string;
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  plan_number?: string;
  token: string;
}

export interface ErrorMessages {
  expiredCode?: boolean;
  invalidPassword?: boolean;
  limitReached?: boolean;
  userExists?: boolean;
  userNotExists?: boolean;
}

export interface LoginDataLayer {
  pagename?: string;
  genericpagename?: string;
  category?: string;
  event?: string;
  laEvent?: string;
  event_category?: string;
  event_action?: string;
  event_label?: string;
  customerID?: string;
  DigitalID?: string;
  MigrationStarted?: string;
  MigrationPsw?: string;
  RegistrationStarted?: string;
}

export interface CreateAccountPayload {
  name: string;
  surname: string;
  email: string;
  password: string;
}

export interface CreateAccountSuccessResponse extends SuccessResponse {}

export interface CreateAccountErrorResponse extends ErrorResponse {}

/*================================================================================
  LOGIN
================================================================================*/

export interface LoginPayload {
  email: string;
  password: string;
}

export interface LoginSuccessResponse extends SuccessResponse {}

export interface LoginErrorResponse extends ErrorResponse {}

/*================================================================================
  REQUEST PASSWORD
================================================================================*/

export interface RequestPasswordPayload {
  email: string;
  is_sign_up: boolean;
  recaptchaToken?: string;
}

export interface RequestPasswordSuccessResponse extends SuccessResponse {}

export interface RequestPasswordErrorResponse extends ErrorResponse {}

/*================================================================================
  CONFIRMATION CODE
================================================================================*/

export interface VerificationCodePayload {
  email: string;
  confirmation_code: string;
}

export interface ResetPasswordAutoAccountPayload {
  email: string;
  client_name: string;
  confirmation_code: string;
  password: string;
}

export interface VerificationCodeSuccessResponse extends SuccessResponse {}

export interface VerificationCodeErrorResponse extends ErrorResponse {}

export interface VerificationCodeParams
  extends VerificationCodeSuccessResponse {}

/*================================================================================
  REQUEST NEW CONFIRMATION CODE
================================================================================*/

export interface RequestVerificationCodePayload {
  email: string;
}

export interface RequestVerificationCodeSuccessResponse
  extends SuccessResponse {}

export interface RequestVerificationCodeErrorResponse extends ErrorResponse {}

/*================================================================================
  RESET PASSWORD
================================================================================*/

export interface ResetPasswordPayload {
  email: string;
  confirmation_code: string;
  is_sign_up: boolean;
  password?: string;
}

export interface ResetPasswordSuccessResponse extends SuccessResponse {}

export interface ResetPasswordErrorResponse extends ErrorResponse {}

/*================================================================================
  USER MATCH - used for address and plan number
================================================================================*/

export interface UserMatchPayload {
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  surname: string;
  plan_number?: string;
}

export interface UserMatchSuccessResponse extends SuccessResponse {}

export interface UserMatchErrorResponse extends ErrorResponse {}

export interface IUserSignUpAndMatchPayload {
  addressLine1: string;
  addressLine2: string;
  addressAutoComplete: string;
  firstName: string;
  lastName: string;
  postcode: string;
  planNumber: string;
  token: string;
  pass: string;
}
