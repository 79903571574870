import { SeoConfig } from '@domgen/dgx-fe-seo';

export const seoConfigFactory = (
  environment: any,
  domainConfig: any
): SeoConfig => {
  return {
    baseUrl: environment.baseUrl,
    hostUrl: environment.baseUrl,
    titleSuffix: domainConfig.clientName,
  };
};
