import { ApiResponse, GetPlansResponse } from '../../../models/appliance.model';
import { MockUtils } from '../../mock-utils/mock-utils';
import { mockPlan } from '../plan.mock';

export const mockGetPlansResponse: ApiResponse<GetPlansResponse> = {
  status: 'OK',
  result: {
    plans: Array.from(Array(MockUtils.randomNumberRange(60, 120)), (x, index) =>
      mockPlan()
    ),
    cross_sell: {
      decision: false,
      target_request_action: 'DandGUK',
      target_request_source: 'QandB',
    },
  },
};

export const mockGetEmptyPlansResponse: ApiResponse<GetPlansResponse> = {
  status: 'OK',
  result: {
    plans: [],
    cross_sell: {
      decision: false,
      target_request_action: 'DandGUK',
      target_request_source: 'QandB',
    },
  },
};
