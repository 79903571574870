<div class="label-container">
  <label [attr.for]="forElement"><ng-content></ng-content></label>
  <div (click)="toggleToolTip()" *ngIf="tooltip">
    <div style="user-select: none" class="label-tooltip"></div>
  </div>
</div>

<div *ngIf="showTooltip" [class]="tooltip.classes">
  <ng-template *ngIf="isArray(); then arrayText; else simpleText">
  </ng-template>

  <p></p>
</div>

<ng-template #simpleText>
  <p>
    {{ tooltip.text }}
  </p>
</ng-template>

<ng-template #arrayText>
  <p *ngFor="let item of tooltip.text">
    {{ item }}
  </p>
</ng-template>
