import { Component, HostBinding, Input } from '@angular/core';
import { CmsArea } from '@domgen/dgx-fe-cms';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  @Input() banner!: CmsArea;
  @Input() faqMain!: CmsArea;
  @Input() upsellMain!: CmsArea;
  @HostBinding('@fadeInAnimation') fadeInAnimation = true;
}
