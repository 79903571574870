import { PlanStateTypeEnum } from '../../enums/applianceTypeEnum';
import { Plan } from '../../models/appliance.model';
import { MockUtils } from '../mock-utils/mock-utils';
import { mockItem } from './item.mock';

export const mockPlan = (): Plan => {
  return {
    plan_number: MockUtils.randomNumberRange(100, 900).toString(),
    plan_state: MockUtils.randomArray(
      Object.keys(PlanStateTypeEnum).filter(
        (obj) => obj !== PlanStateTypeEnum.ALL
      )
    ),
    parent_plan_number: MockUtils.randomArray([null, '1234']),
    plan_expiry: MockUtils.datePast(1).split('T')[0],
    item: mockItem(),
    repair_action: 'REQUEST_REPAIR',
    service_action: 'NO_ACTION',
    plan_contract_type: '',
    plan_owner_type: 'CUSTOMER',
    plan_start: MockUtils.datePast(10).split('T')[0],
    plan_note: '',
  };
};
