import { VerfiyUserActivationLink } from '@athome-myaccount/my-account/data-access-shared';
import { User } from '@athome-myaccount/my-account/util-shared';
import { Action } from '@ngrx/store';
import {
  IMatchPersonalDetailsPayload,
  ISignUpAndMatchOnAddressPayload,
} from '../../models/api-payloads.model';

export enum NewLoginAndRegActionTypes {
  NewLogIn = '[New Login & Reg] Log In',

  SignUpNewAccount = '[New Login & Reg] Sign Up New Account',
  SignUpNewAccountSuccess = '[New Login & Reg] Sign Up New Account Success',
  SignUpNewAccountFailure = '[New Login & Reg] Sign Up New Account Failure',

  ResendNewSignUpAccountEmail = '[New Login & Reg] Resend New Sign Up Account Email',
  ResendNewSignUpAccountEmailSuccess = '[New Login & Reg] Resend New Sign Up Account Email Success',
  ResendNewSignUpAccountEmailFailure = '[New Login & Reg] Resend New Sign Up Account Email Failure',

  ResetUserPassword = '[New Login & Reg] Request New Password',
  ResetUserPasswordSuccess = '[New Login & Reg] Request New Password Success',
  ResetUserPasswordFailure = '[New Login & Reg] Request New Password Failure',

  ResetPasswordConfirm = '[New Login & Reg] Reset Password Confirm',
  ResetPasswordConfirmSuccess = '[New Login & Reg] Reset Password Confirm Success',
  ResetPasswordConfirmFailure = '[New Login & Reg] Reset Password Confirm Failure',

  ConfirmActivationAutoAccount = '[New Login & Reg] Confirm Activation Auto Account',
  ConfirmActivationAutoAccountSuccess = '[New Login & Reg] Confirm Activation Auto Account Success',
  ConfirmActivationAutoAccountFailure = '[New Login & Reg] Confirm Activation Auto Account Failure',

  RequestActivationAutoAccount = '[New Login & Reg] Request Activation Auto Account',
  RequestActivationAutoAccountSuccess = '[New Login & Reg] Request Activation Auto Account Success',
  RequestActivationAutoAccountFailure = '[New Login & Reg] Request Activation Auto Account Failure',

  MatchUserBasedOnAddress = '[New Login & Reg] Match User Based On New Address',
  MatchUserBasedOnAddressSuccess = '[New Login & Reg] Match User Based On New Address Success',
  MatchUserBasedOnAddressFailure = '[New Login & Reg] Match User Based On New Address Failure',

  MatchUserBasedOnPlanNumber = '[New Login & Reg] Match User Based On Plan Number',
  MatchUserBasedOnPlanNumberSuccess = '[New Login & Reg] Match User Based On Plan Number Success',
  MatchUserBasedOnPlanNumberFailure = '[New Login & Reg] Match User Based On Plan Number Failure',

  VerifyActivationLink = '[New Login & Reg] Verfiy activation link',
  VerifyActivationLinkSuccess = '[New Login & Reg] Verfiy activation link Success',
  VerifyActivationLinkFailure = '[New Login & Reg] Verfiy activation link Failue',
}
export class NewLogIn implements Action {
  readonly type = NewLoginAndRegActionTypes.NewLogIn;
  constructor(public payload: { decodedUser: User }) {}
}

export class ResendNewSignUpAccountEmail implements Action {
  readonly type = NewLoginAndRegActionTypes.ResendNewSignUpAccountEmail;
  constructor(public payload: { recaptchaToken: string; email: string }) {}
}
export class ResendNewSignUpAccountEmailSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.ResendNewSignUpAccountEmailSuccess;
  constructor(public payload: { success: any }) {}
}
export class ResendNewSignUpAccountEmailFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.ResendNewSignUpAccountEmailFailure;
  constructor(public payload: { error: any }) {}
}

export class SignUpNewAccount implements Action {
  readonly type = NewLoginAndRegActionTypes.SignUpNewAccount;
  constructor(public payload: { email: string }) {}
}
export class SignUpNewAccountSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.SignUpNewAccountSuccess;
  constructor(public payload: { success: any }) {}
}
export class SignUpNewAccountFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.SignUpNewAccountFailure;
  constructor(public payload: { error: any }) {}
}

export class MatchUserBasedOnPlanNumber implements Action {
  readonly type = NewLoginAndRegActionTypes.MatchUserBasedOnPlanNumber;
  constructor(
    public payload: {
      recaptchaToken: string;
      payload: IMatchPersonalDetailsPayload;
    }
  ) {}
}
export class MatchUserBasedOnPlanNumberSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.MatchUserBasedOnPlanNumberSuccess;
  constructor(public payload: { success: any }) {}
}
export class MatchUserBasedOnPlanNumberFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.MatchUserBasedOnPlanNumberFailure;
  constructor(public payload: { error: any }) {}
}

export class MatchUserBasedOnAddress implements Action {
  readonly type = NewLoginAndRegActionTypes.MatchUserBasedOnAddress;
  constructor(
    public payload: {
      recaptchaToken: string;
      payload: ISignUpAndMatchOnAddressPayload;
    }
  ) {}
}
export class MatchUserBasedOnAddressSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.MatchUserBasedOnAddressSuccess;
  constructor(public payload: { success: any }) {}
}
export class MatchUserBasedOnAddressFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.MatchUserBasedOnAddressFailure;
  constructor(public payload: { error: any }) {}
}

export class ResetUserPassword implements Action {
  readonly type = NewLoginAndRegActionTypes.ResetUserPassword;
  constructor(public payload: { email: string }) {}
}

export class ResetPasswordConfirm implements Action {
  readonly type = NewLoginAndRegActionTypes.ResetPasswordConfirm;
  constructor(
    public payload: {
      email: string;
      password: string;
      confirmationCode: string;
    }
  ) {}
}
export class ResetPasswordConfirmSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.ResetPasswordConfirmSuccess;
  constructor(public payload: { success: any }) {}
}
export class ResetPasswordConfirmFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.ResetPasswordConfirmFailure;
  constructor(public payload: { error: any; payload: any }) {}
}

export class ResetUserPasswordSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.ResetUserPasswordSuccess;
  constructor(public payload: { success: any }) {}
}
export class ResetUserPasswordFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.ResetUserPasswordFailure;
  constructor(public payload: { error: any }) {}
}

export class ConfirmActivationAutoAccount implements Action {
  readonly type = NewLoginAndRegActionTypes.ConfirmActivationAutoAccount;
  constructor(public payload: { email: string }) {}
}

export class ConfirmActivationAutoAccountSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.ConfirmActivationAutoAccountSuccess;
  constructor(public payload: any) {}
}
export class ConfirmActivationAutoAccountFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.ConfirmActivationAutoAccountFailure;
  constructor(public payload: any) {}
}

export class RequestActivationAutoAccount implements Action {
  readonly type = NewLoginAndRegActionTypes.RequestActivationAutoAccount;
  constructor(public payload: any) {}
}

export class RequestActivationAutoAccountSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.RequestActivationAutoAccountSuccess;
  constructor(public payload: any) {}
}
export class RequestActivationAutoAccountFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.RequestActivationAutoAccountFailure;
  constructor(public payload: any) {}
}

export class VerifyActivationLink implements Action {
  readonly type = NewLoginAndRegActionTypes.VerifyActivationLink;
  constructor(public payload: VerfiyUserActivationLink) {}
}

export class VerifyActivationLinkSuccess implements Action {
  readonly type = NewLoginAndRegActionTypes.VerifyActivationLinkSuccess;
  constructor(public payload: any) {}
}
export class VerifyActivationLinkFailure implements Action {
  readonly type = NewLoginAndRegActionTypes.VerifyActivationLinkFailure;
  constructor(public payload: any) {}
}

export type NewLoginAndRegActions =
  | SignUpNewAccount
  | SignUpNewAccountSuccess
  | SignUpNewAccountFailure
  | ResetUserPassword
  | ResetUserPasswordSuccess
  | ResetUserPasswordFailure
  | MatchUserBasedOnAddress
  | MatchUserBasedOnAddressSuccess
  | MatchUserBasedOnAddressFailure
  | MatchUserBasedOnPlanNumber
  | MatchUserBasedOnPlanNumberSuccess
  | MatchUserBasedOnPlanNumberFailure
  | ResendNewSignUpAccountEmail
  | ResendNewSignUpAccountEmailSuccess
  | ResendNewSignUpAccountEmailFailure
  | ResetPasswordConfirm
  | ResetPasswordConfirmSuccess
  | ResetPasswordConfirmFailure
  | RequestActivationAutoAccount
  | RequestActivationAutoAccountSuccess
  | RequestActivationAutoAccountFailure
  | ConfirmActivationAutoAccount
  | ConfirmActivationAutoAccountSuccess
  | ConfirmActivationAutoAccountFailure
  | VerifyActivationLink
  | VerifyActivationLinkSuccess
  | VerifyActivationLinkFailure;
