import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import { GAService } from '@domgen/dgx-fe-ga';

@Component({
  selector: 'athome-myaccount-cross-sell-plumbing-and-drainage',
  templateUrl: './cross-sell-plumbing-and-drainage.component.html',
  styleUrls: ['./cross-sell-plumbing-and-drainage.component.scss'],
})
export class CrossSellPlumbingAndDrainageComponent implements OnInit {
  @Input() isMobile = false;

  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    private GAService: GAService
  ) {}

  modalOpen = false;
  ngOnInit(): void {
    const captureBadge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (captureBadge) {
      captureBadge.remove();
    }
  }

  toggleModal() {
    this.modalOpen = !this.modalOpen;
    this.GAService.tag({
      clientName: 'DG My Account',
      event: 'genericGAEvent',
      eventCategory: 'cross-sell-widget',
      eventAction: 'click-my-account',
      eventLabel: this.modalOpen
        ? 'p&d pop up open 4.99'
        : 'p&d pop up close 4.99',
    });
  }

  navigateToGetQuote() {
    this.GAService.tag({
      clientName: 'DG My Account',
      event: 'genericGAEvent',
      eventCategory: 'cross-sell-widget',
      eventAction: 'click-my-account',
      eventLabel: this.isMobile ? 'p&d pop up click 4.99' : 'p&d 4.99',
    });

    window.open(
      `${this.config.salesAppHostPD}/plumbing-and-drainage/quote-details`,
      '_blank',
      'noopener'
    );
  }
}
