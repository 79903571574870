import { Action } from '@ngrx/store';

export enum ErrorActionTypes {
  GenericError = '[Error] Generic Error',
  PageNotFound = '[Error] Page Not Found',
  ServerError = '[Error] Server Error',
  UnAuthorized = '[Error] Not Authenticated',
  NavigateToDashBoardError = '[Error] Plans not found',
}

export class GenericError implements Action {
  readonly type = ErrorActionTypes.GenericError;
}

export class PageNotFound implements Action {
  readonly type = ErrorActionTypes.PageNotFound;
}

export class UnAuthorized implements Action {
  readonly type = ErrorActionTypes.UnAuthorized;
}

export class ServerError implements Action {
  readonly type = ErrorActionTypes.ServerError;
}

export class NavigateToDashBoardError implements Action {
  readonly type = ErrorActionTypes.NavigateToDashBoardError;
}
