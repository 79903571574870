import { UserMatchPayload } from '@athome-myaccount/my-account/util-shared';
import { MockAddress } from '../../mock-utils/mock-address';
import { MockUser } from '../../mock-utils/mock-user';
import { MockUtils } from '../../mock-utils/mock-utils';

export const mockUserMatchPayload: UserMatchPayload = {
  address_line_1: MockAddress.firstLine,
  address_line_2: MockAddress.secondLine,
  postcode: MockAddress.postCode,
  surname: MockUser.lastName(),
  plan_number: MockUtils.alphaNumeric(),
};
