<div class="check-container">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <ellipse cx="11.7857" cy="12" rx="11.7857" ry="12" fill="#008A00" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4237 7.24121C18.7396 7.56281 18.7396 8.08424 18.4237 8.40585L10.3355 16.6411C10.0196 16.9628 9.50753 16.9628 9.19166 16.6411L5.14754 12.5235C4.83168 12.2019 4.83168 11.6805 5.14754 11.3589C5.46341 11.0372 5.97553 11.0372 6.29139 11.3589L9.76359 14.8942L17.2799 7.24121C17.5958 6.9196 18.1079 6.9196 18.4237 7.24121Z"
      fill="white"
    />
  </svg>
  <p class="check-label" *ngIf="label">
    {{ label }}
  </p>
</div>
