import { HttpErrorResponse } from '@angular/common/http';
import {
  Failure,
  InProgress,
  NotAsked,
  RemoteData,
  Success,
} from 'ngx-remotedata';
import {
  IMatchPersonalDetailsResponse,
  ISignUpAndMatchAddressResponse,
  ISignUpNewAccountResponse,
} from '../../services/my-account-identity-http.service';
import {
  NewLoginAndRegActionTypes,
  NewLoginAndRegActions,
} from '../actions/new-login-and-reg.actions';

export interface NewLoginAndRegState {
  signUpNewAccountApiResponse: RemoteData<
    ISignUpNewAccountResponse,
    HttpErrorResponse
  >;
  resendNewSignUpAccountEmailApiResponse: RemoteData<
    ISignUpNewAccountResponse,
    HttpErrorResponse
  >;
  resetUserPasswordApiResponse: RemoteData<any, any>;
  matchUserBasedOnAddressApiResponse: RemoteData<
    ISignUpAndMatchAddressResponse,
    HttpErrorResponse
  >;
  matchUserBasedOnPlanNumberApiResponse: RemoteData<
    IMatchPersonalDetailsResponse,
    HttpErrorResponse
  >;

  confirmPasswordResetApiResponse: RemoteData<any, HttpErrorResponse>;

  requestActivationAutoAccountApiResponse: RemoteData<any, HttpErrorResponse>;
  confirmActivationAutoAccountApiResponse: RemoteData<any, HttpErrorResponse>;
  verifyActivationLinkApiResponse: RemoteData<any, HttpErrorResponse>;
}

export const inititalNewLoginAndRegState: NewLoginAndRegState = {
  signUpNewAccountApiResponse: NotAsked.of(),
  resetUserPasswordApiResponse: NotAsked.of(),
  matchUserBasedOnAddressApiResponse: NotAsked.of(),
  matchUserBasedOnPlanNumberApiResponse: NotAsked.of(),
  resendNewSignUpAccountEmailApiResponse: NotAsked.of(),
  confirmPasswordResetApiResponse: NotAsked.of(),
  requestActivationAutoAccountApiResponse: NotAsked.of(),
  confirmActivationAutoAccountApiResponse: NotAsked.of(),
  verifyActivationLinkApiResponse: NotAsked.of(),
};

export function newLoginAndRegReducer(
  state = inititalNewLoginAndRegState,
  action: NewLoginAndRegActions
): NewLoginAndRegState {
  switch (action.type) {
    case NewLoginAndRegActionTypes.SignUpNewAccount:
      return {
        ...state,
        signUpNewAccountApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.SignUpNewAccountSuccess:
      return {
        ...state,
        signUpNewAccountApiResponse: Success.of(action.payload.success),
      };
    case NewLoginAndRegActionTypes.SignUpNewAccountFailure:
      return {
        ...state,
        signUpNewAccountApiResponse: Failure.of(action.payload.error),
      };

    case NewLoginAndRegActionTypes.ResetPasswordConfirm:
      return {
        ...state,
        confirmPasswordResetApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.ResetPasswordConfirmSuccess:
      return {
        ...state,
        confirmPasswordResetApiResponse: Success.of(action.payload.success),
      };
    case NewLoginAndRegActionTypes.ResetPasswordConfirmFailure:
      return {
        ...state,
        confirmPasswordResetApiResponse: Failure.of(action.payload.error),
      };

    case NewLoginAndRegActionTypes.ResendNewSignUpAccountEmail:
      return {
        ...state,
        resendNewSignUpAccountEmailApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.ResendNewSignUpAccountEmailSuccess:
      return {
        ...state,
        resendNewSignUpAccountEmailApiResponse: Success.of(
          action.payload.success
        ),
      };
    case NewLoginAndRegActionTypes.ResendNewSignUpAccountEmailFailure:
      return {
        ...state,
        resendNewSignUpAccountEmailApiResponse: Failure.of(
          action.payload.error
        ),
      };

    case NewLoginAndRegActionTypes.ResetUserPassword:
      return {
        ...state,
        resetUserPasswordApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.ResetUserPasswordSuccess:
      return {
        ...state,
        resetUserPasswordApiResponse: Success.of(action.payload.success),
      };
    case NewLoginAndRegActionTypes.ResetUserPasswordFailure:
      return {
        ...state,
        resetUserPasswordApiResponse: Failure.of(action.payload.error),
      };

    case NewLoginAndRegActionTypes.MatchUserBasedOnAddress:
      return {
        ...state,
        matchUserBasedOnAddressApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.MatchUserBasedOnAddressSuccess:
      return {
        ...state,
        matchUserBasedOnAddressApiResponse: Success.of(action.payload.success),
      };
    case NewLoginAndRegActionTypes.MatchUserBasedOnAddressFailure:
      return {
        ...state,
        matchUserBasedOnAddressApiResponse: Failure.of(action.payload.error),
      };

    case NewLoginAndRegActionTypes.MatchUserBasedOnPlanNumber:
      return {
        ...state,
        matchUserBasedOnPlanNumberApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.MatchUserBasedOnPlanNumberSuccess:
      return {
        ...state,
        matchUserBasedOnPlanNumberApiResponse: Success.of(
          action.payload.success
        ),
      };
    case NewLoginAndRegActionTypes.MatchUserBasedOnPlanNumberFailure:
      return {
        ...state,
        matchUserBasedOnPlanNumberApiResponse: Failure.of(action.payload.error),
      };
    case NewLoginAndRegActionTypes.ConfirmActivationAutoAccount:
      return {
        ...state,
        confirmActivationAutoAccountApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.ConfirmActivationAutoAccountSuccess:
      return {
        ...state,
        confirmActivationAutoAccountApiResponse: Success.of(
          action.payload.success
        ),
      };
    case NewLoginAndRegActionTypes.ConfirmActivationAutoAccountFailure:
      return {
        ...state,
        confirmActivationAutoAccountApiResponse: Failure.of(
          action.payload.error
        ),
      };

    case NewLoginAndRegActionTypes.RequestActivationAutoAccount:
      return {
        ...state,
        requestActivationAutoAccountApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.RequestActivationAutoAccountSuccess:
      return {
        ...state,
        requestActivationAutoAccountApiResponse: Success.of(
          action.payload.success
        ),
      };
    case NewLoginAndRegActionTypes.RequestActivationAutoAccountFailure:
      return {
        ...state,
        requestActivationAutoAccountApiResponse: Failure.of(
          action.payload.error
        ),
      };
    case NewLoginAndRegActionTypes.VerifyActivationLink:
      return {
        ...state,
        verifyActivationLinkApiResponse: InProgress.of(),
      };
    case NewLoginAndRegActionTypes.VerifyActivationLinkSuccess:
      return {
        ...state,
        verifyActivationLinkApiResponse: Success.of(action.payload.success),
      };
    case NewLoginAndRegActionTypes.VerifyActivationLinkFailure:
      return {
        ...state,
        verifyActivationLinkApiResponse: Failure.of(action.payload.error),
      };
    default:
      return state;
  }
}
