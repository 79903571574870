import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import {
  GetMarketingPreferenceResponse,
  GetPersonalDetailsResponse,
  MarketingPreferenceUpdate,
  UpdateContactDetailsPayload,
  UpdateCorrespondenceAddressPayload,
  UpdateDocPreferencePayload,
} from '@athome-myaccount/my-account/util-login';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { PersonalDetailsHttpEnum } from './personal-details-http.enum';

@Injectable()
export class PersonalDetailsHttpService {
  readonly headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Request-Source': 'DandGUK',
  };

  readonly httpOptions = {
    headers: new HttpHeaders({
      ...this.headers,
    }),
    withCredentials: true,
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(CONFIG) private readonly config: AppConfig
  ) {}

  getPersonDetails(): Observable<GetPersonalDetailsResponse> {
    return this.httpClient
      .get(
        `${this.config.commonPersonServiceUrl}${PersonalDetailsHttpEnum.GET_PERSON_DETAILS}`,
        this.httpOptions
      )
      .pipe(map((response: any) => response));
  }

  getPersonMarketingPreference(): Observable<GetMarketingPreferenceResponse> {
    return this.httpClient
      .get(
        `${this.config.commonPersonServiceUrl}${PersonalDetailsHttpEnum.GET_PERSON_MARKETING_PREFERENCES}`,
        this.httpOptions
      )
      .pipe(
        map((response: any) => response),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateContactDetails(payload: UpdateContactDetailsPayload): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this.config.commonPersonServiceUrl}${PersonalDetailsHttpEnum.UPDATE_CONTACT_DETAILS}`,
        payload,
        this.httpOptions
      )
      .pipe(mapTo({}));
  }

  updateDocPreference(payload: UpdateDocPreferencePayload): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this.config.commonPersonServiceUrl}${PersonalDetailsHttpEnum.UPDATE_DOCUMENT_PREFERENCE}`,
        payload,
        this.httpOptions
      )
      .pipe(mapTo({}));
  }

  updateCorrespondenceAddress(
    payload: UpdateCorrespondenceAddressPayload
  ): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this.config.commonPersonServiceUrl}${PersonalDetailsHttpEnum.UPDATE_CORRESPONDENCE_ADDRESS}`,
        payload,
        this.httpOptions
      )
      .pipe(mapTo({}));
  }

  updateMarketingPreference(
    preference: MarketingPreferenceUpdate
  ): Observable<any> {
    const payload = {
      ...preference,
    };

    return this.httpClient
      .post(
        `${this.config.commonPersonServiceUrl}${PersonalDetailsHttpEnum.UPDATE_PERSON_MARKETING_PREFERENCES}`,
        payload,
        this.httpOptions
      )
      .pipe(
        map((response: any) => response),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
