export enum LoginApiEnum {
  CREATE_ACCOUNT_URL = '/signup', // WL: Dev tested
  LOGIN_USER_URL = '/signin', // WL: Dev tested
  REQUEST_PASSWORD_URL = '/forgot-password', // WL: Dev tested
  RESET_PASSWORD_URL = '/confirm-password', // WL: Dev tested
  REQUEST_VERIFICATION_CODE_URL = '/verification/resend-code', // TODO WL: Needs testing
  VERIFICATION_CODE_URL = '/verification/confirm-code', // WL: Dev tested
  REQUEST_VERIFICATION_TOKEN = '/verification/request-verification-token',
  AUTO_ACCOUNT = '/verification/confirm-auto-account', // TODO WL: Needs testing
  AUTO_ACCOUNT_CHECK = '/verification/confirm-auto-account-code', // TODO WL: Needs testing
  PASSWORD_UPDATE = '/update-password', // WL: Dev tested
  USER_MATCH_URL = '/match-user', // WL: Dev tested
  SIGNOUT = '/signout', // WL: Dev tested
  REFRESH = '/refresh', // TODO WL: Needs testing
  SIGNUP_AND_MATCH = '/verification/signup-and-match',
  MATCH_PERSONAL_DETAILS = '/verification/match-personal-details',
  CONFIRM_VERIFCATION_TOKEN = '/verification/confirm-verification-token',
}

export enum NewLoginAndRegApiEnum {
  REQUEST_NEW_PASSWORD_RESET = '/validation/passwordreset/request',
  UPDATE_USER_ID = '/changeuserid/request-userid-change',
  REQUEST_USER_ID_VERIFICATION_LINK = '/validation/changeuserid/request',
  CONFIRM_USER_ID_CHANGE = '/validation/changeuserid/confirm',
  VALIDATE_USER_ID_LINK = '/validation/changeuserid/check',
}
export enum NewLoginAndRegHttpEnum {
  SIGN_UP_NEW_ACCOUNT_URL = '/validation/signup/request',
  RESET_USER_PASSWORD_URL = '/validation/passwordreset/request',
  SIGN_UP_CONFIRM_AND_ADDRESS_MATCH_URL = '/validation/signup/confirm',
  MATCH_CUSTOMER_DETAILS_URL = '/confirm/personal-details',
  REFRESH_COOKIE = '/refresh',
  PASSWORD_RESET_CONFIRM = '/validation/passwordreset/confirm',
  AUTO_ACCOUNT_CONFIRM_ACTIVATION = '/validation/activateaccount/confirm',
  AUTO_ACCOUNT_REQUEST_ACTIVATION = '/validation/activateaccount/request',
  VERIFY_ACTIVATION_LINK = '/validation/signup/check',
}
