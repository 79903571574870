import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';

@Component({
  selector: 'app-dashboard-error.component',
  templateUrl: './dashboard-error.html',
  styleUrls: ['./dashboard-error.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashBoardErrorComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(CONFIG) private readonly config: AppConfig,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  sendGAEvent(label: string) {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventAction: 'interaction',
      eventCategory: 'dashboard-error',
      eventLabel: label,
      clientName: 'DG My Account',
    });
  }

  getMoreHelp() {
    this.sendGAEvent('get-more-help');
    this.document.location.href = this.config.contactUsUrl;
  }

  async tryAgain() {
    this.sendGAEvent('try-to-load-again');
    await this.router.navigate(['my-account/my-plans']);
  }

  ngOnInit(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      clientName: 'DG My Account',
      pagename: 'dashboard-error',
      genericpagename: 'error-page',
      category: 'my-account-dgx',
    });
  }
}
