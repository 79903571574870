import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Environment } from '@athome-myaccount/my-account/util-core';
import {
  LoginRoutesEnum,
  NewLoginAndRegRoutesEnum,
} from '@athome-myaccount/my-account/util-login';
import { ENVIRONMENT } from '@domgen/dgx-fe-config';
import { FormsService } from '../services/forms.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  public form: UntypedFormGroup;
  public pageValues: any;
  public pageItems: any;
  public useValidation = false;

  @Input() data: any;
  @Output() formData: EventEmitter<any> = new EventEmitter<UntypedFormGroup>();
  @Output()
  customButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formsService: FormsService,
    private router: Router,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}

  createForm(pageItems, pageValues) {
    this.form = new UntypedFormGroup(
      this.formsService.defineformControls(pageItems, pageValues)
    );
  }

  onCreateForm() {
    this.pageItems = this.data.meta;
    this.pageValues = this.data.formValues || {};
    this.createForm(this.pageItems, this.pageValues);
  }

  ngOnInit() {
    this.onCreateForm();
  }

  /**
   * return form and set default angular validation
   */
  onSubmit() {
    this.formData.emit(this.form);
    this.useValidation = true;
  }

  onForgotYourPasswordClick(): void {
    if (this.environment.loginRegVersion === 'v2') {
      this.router.navigateByUrl(
        NewLoginAndRegRoutesEnum.REQUEST_PASSWORD_RESET_PAGE
      );
    } else {
      this.router.navigateByUrl(LoginRoutesEnum.FORGOT_PASSWORD_PAGE);
    }
  }
}
