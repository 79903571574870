<mat-dialog-content
  style="width: 100%; margin: 0; padding: 1vh; position: relative"
>
  <svg-icon
    name="modal-close-icon"
    class="close-icon"
    (click)="closeModal()"
    style="position: absolute; right: 0"
  ></svg-icon>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="20px">
      <div
        class="success-container"
        [ngClass.xs]="'success-container-mobile'"
        fxLayout="row"
        fxLayoutAlign="start stretch"
        fxLayoutGap="20px"
        fxLayoutGap.xs="15px"
      >
        <div
          class="logo-section"
          fxLayout="column"
          fxLayoutAlign="start center"
        >
          <img
            src="./assets/images/warning-icon.png"
            alt="There was an error completing the task"
          />
        </div>
        <div style="display: flex; flex-direction: column">
          <div
            class="text-section"
            fxLayout="column"
            fxLayoutAlign="center stretch"
            fxFlex="1 1 0%"
          >
            <div class="text-section__title typog-body-large-bold">
              Email address already used

              <p class="mt-1">
                That email address is linked to an account already. Please try
                again.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap.gt-xs="20px"
    ></div>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap.gt-xs="20px"
    style="display: flex"
  >
    <app-button
      [text]="'Try again'"
      classes="btn btn--secondary mt-1"
      (click)="closeModal()"
    ></app-button>
  </div>
</mat-dialog-content>
