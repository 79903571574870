import { ApiResponse } from '../../models/appliance.model';
import { PlanDetailApiResponsePlan } from '../../state/plan.model';
import { MockUtils } from '../mock-utils/mock-utils';

export const mockPlanDetail: ApiResponse<PlanDetailApiResponsePlan> = {
  status: 'OK',
  result: {
    plan: {
      plan_number: MockUtils.alphaNumeric(),
      plan_state: MockUtils.randomArray(
        Object.values(['COVERED', 'LAPSED', 'CANCELLED', 'RENEWAL'])
      ),
      plan_start: MockUtils.datePast(1).split('T')[0],
      plan_expiry: MockUtils.dateFuture(1).split('T')[0],
      plan_contract_type: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
      plan_owner_type: MockUtils.randomArray(
        Object.values(['CUSTOMER', 'LANDLORD'])
      ),
      repair_action: MockUtils.randomArray(
        Object.values([
          'REQUEST_REPAIR',
          'BOOK_ENGINEER',
          'INCOMPLETE_FORM',
          'TRACK_REPAIR',
        ])
      ),
      service_action: MockUtils.randomArray(
        Object.values([
          'REQUEST_SERVICE',
          'INCOMPLETE_FORM',
          'AMEND_SERVICE',
          'BOOK_ENGINEER',
        ])
      ),
      item: {
        manufacturer: MockUtils.randomArray(['HOTPOINT', 'BEKO', 'APPLE']),
        model: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
        model_number: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
        code: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
        code_description: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
      },
      claims: [
        {
          id: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
          status: MockUtils.randomArray(Object.values(['OPEN', 'CLOSED'])),
          claim_type: MockUtils.randomArray(
            Object.values(['SERVICE', 'REPAIR'])
          ),
        },
      ],
      payment: {
        method: MockUtils.randomArray(
          Object.values(['CREDIT_CARD', 'DIRECT_DEBIT'])
        ),
      },
      contact_details: {
        email: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
      },
      repair_payment: 'NO',
    },
  },
};
