import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './+state/auth.effects';
import { AuthLibFacade } from './+state/auth.facade';
import { authReducer } from './+state/auth.reducer';
import { UserAuthGuard } from './common/guards/auth.guard';
import {
  AUTH_FORMS,
  AUTH_URLS_CONFIG_JSON,
  AuthModuleConfig,
} from './common/tokens/auth-config-token';
import { ForgotPasswordModule } from './components/forgot-password/forgot-password.module';
import { LogInModule } from './components/log-in/log-in.module';
import { PasswordResetModule } from './components/password-reset/password-reset.module';

import { SharedUtilModule } from '@athome-myaccount/shared/util';
import { AuthenticationApiService } from './services/authentication-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LogInModule,
    ForgotPasswordModule,
    PasswordResetModule,
    HttpClientModule,
    ReactiveFormsModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    SharedUtilModule,
  ],
  providers: [UserAuthGuard, AuthenticationApiService, AuthLibFacade],
  exports: [LogInModule, ForgotPasswordModule, PasswordResetModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedFeatureAuthenticationModule {
  static forRoot(
    config: AuthModuleConfig
  ): ModuleWithProviders<SharedFeatureAuthenticationModule> {
    return {
      ngModule: SharedFeatureAuthenticationModule,
      providers: [
        { provide: AUTH_FORMS, useValue: config.forms },
        { provide: AUTH_URLS_CONFIG_JSON, useValue: config.urlsConfigJsonPath },
      ],
    };
  }
}
