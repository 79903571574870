export interface IMyDetailsEditSectionsState {
  isContactDetailsEdited: boolean;
  isAddressEdited: boolean;
  isPasswordEdited: boolean;
  isMarketingPreferencessEdited: boolean;
  isUsernameEdited: boolean;
}
export class MyDetailsEditSectionsState implements IMyDetailsEditSectionsState {
  public isContactDetailsEdited: boolean;
  public isPasswordEdited: boolean;
  public isAddressEdited: boolean;
  public isMarketingPreferencessEdited: boolean;
  public isUsernameEdited: boolean;

  constructor(
    obj: IMyDetailsEditSectionsState = {
      isAddressEdited: false,
      isContactDetailsEdited: false,
      isPasswordEdited: false,
      isMarketingPreferencessEdited: false,
      isUsernameEdited: false,
    }
  ) {
    this.isContactDetailsEdited = obj.isContactDetailsEdited;
    this.isAddressEdited = obj.isAddressEdited;
    this.isPasswordEdited = obj.isPasswordEdited;
    this.isMarketingPreferencessEdited = obj.isMarketingPreferencessEdited;
  }
}
