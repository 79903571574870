import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit {
  @Input() group: UntypedFormGroup;
  @Input() item;
  @Input() label;
  @Input() hint;
  @Input() labelFor;
  @Input() showValidation;
  @Input() validators;
  name;
  id;

  public initValueIndex: number | null = null;

  ngOnInit() {
    this.initValueIndex =
      this.group.controls[this.item.control].value === null
        ? 0
        : this.group.controls[this.item.control].value;
  }
}
