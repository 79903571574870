import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { ValidationErrorMessageService } from '../../services/validation-error-message-service';

@Component({
  selector: 'app-validation-error-formcontrol',
  templateUrl: './validation-error-formcontrol.component.html',
})
export class ValidationErrorFormControlComponent {
  @Input() group: UntypedFormGroup;
  @Input() control;
  @Input() validators;

  error: any;

  constructor(
    private _analytics: AnalyticsService,
    private validationErrorMessageService: ValidationErrorMessageService
  ) {}

  getFieldError(control) {
    if (this.group.get(control).errors) {
      const error = Object.keys(this.group.get(control).errors)[0];
      if (error !== this.error) {
        this.error = error;
        this.validationErrorMessageService.validationErrorEvent.emit({
          control: control,
          message: this.validators.filter((v) => v.type == error)[0]?.message,
        });
      }

      return Object.keys(this.group.get(control).errors)[0];
    }
  }
  // TODO: FIX AND REFACTOR
  triggerGA(error: any) {
    if (error) {
      this._analytics.trigger('ClaimsGenericGAEvent', {
        eventClaims: 'validation-error',
        eventCategory: 'validation-error',
        eventAction: `validation-error-${this.control}`,
        eventLabel: error, // Short form of the error text (eg 'insert phone number', 'insert model number', 'answer all questions', etc)
      });
    }
  }
}
