/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  NewLoginAndRegFacadeService,
  ResetUserPassword,
} from '@athome-myaccount/my-account/data-access-new-login-and-reg';
import {
  AccountService,
  AnalyticsService,
} from '@athome-myaccount/my-account/data-access-shared';
import { ResetAccountState } from '@athome-myaccount/my-account/util-account';
import {
  ENVIRONMENT,
  Environment,
  ModalDialogService,
  OpenAlertModal,
  OpenUsernameExistsModal,
} from '@athome-myaccount/my-account/util-core';
import {
  AlertType,
  ModalContent,
  RequestPasswordErrorResponse,
  RequestPasswordPayload,
  RequestVerificationCodePayload,
  ResetPasswordErrorResponse,
  User,
  UserStateErrorTypeEnum,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { Store, select } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import {
  FORGOT_PASSWORD_FORM_INVALID_SUBMISSION_EVENT,
  LOGIN_FORM_INVALID_SUBMISSION_EVENT,
  USER_AUTO_LOGGED_IN_EVENT,
} from '../common/tracking-analytics/login.tracking';
import { LoginComponentHelper } from '../helpers';
import {
  LogIn,
  LogOut,
  RequestForgottenPassword,
  ResetUserState,
  UserAuthentication,
} from '../state/user.actions';
import {
  isUserLoggedIn,
  selectApplicationUser,
  selectCreateNewAccountFormCredentials,
  selectLoginFormCredentials,
  selectVerificationCompleteStatus,
} from '../state/user.selectors';
import { NotifyToastService } from './notify-toast.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    private store: Store<unknown>,
    private accountService: AccountService,
    private loginHelper: LoginComponentHelper,
    private analyticsService: AnalyticsService,
    private toastService: NotifyToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private modalDialogService: ModalDialogService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dialog: MatDialog,
    private newLoginAndRegFacadeService: NewLoginAndRegFacadeService,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}

  getApplicationUser(): User {
    let applicationUser: User;
    this.store
      .pipe(select(selectApplicationUser), take(1))
      .subscribe((user) => (applicationUser = user));
    return applicationUser;
  }

  getCreateNewAccountFormCredentials(): unknown {
    let createNewAccountForm: unknown;
    this.store
      .pipe(select(selectCreateNewAccountFormCredentials), take(1))
      .subscribe((form) => (createNewAccountForm = form));
    return createNewAccountForm;
  }

  getLoginFormCredentials(): unknown {
    let loginFormCredentials: unknown;
    this.store
      .pipe(select(selectLoginFormCredentials), take(1))
      .subscribe((form) => (loginFormCredentials = form));
    return loginFormCredentials;
  }

  public handleUserCookieSession(): void {
    const cookie_AWS_IDT: string = this.cookieService.get('AWS_IDT');
    if (cookie_AWS_IDT) {
      try {
        const decodedUser: User = jwt_decode(cookie_AWS_IDT);
        if (decodedUser) {
          this.handleLogin(decodedUser);
        }
      } catch {
        this.store.dispatch(new LogOut());
      }
    }
  }

  private handleLogin(decodedUser: User): void {
    if (
      decodedUser &&
      decodedUser['custom:user_state'] === UserStateTypeEnum.COMPLETE
    ) {
      USER_AUTO_LOGGED_IN_EVENT(this.analyticsService);
      this.store.dispatch(new LogIn({ decodedUser }));
    }
  }

  decodeUserFromJWTCookie(): User | null {
    const cookie = this.cookieService.get('AWS_IDT');
    if (cookie) {
      return jwt_decode(cookie);
    } else {
      return null;
    }
  }

  getUserVerificationCompleteStatus(): boolean {
    let isUserVerificationComplete: boolean;
    this.store
      .pipe(select(selectVerificationCompleteStatus), take(1))
      .subscribe(
        (userVerificationStatus) =>
          (isUserVerificationComplete = userVerificationStatus)
      );
    return isUserVerificationComplete;
  }

  getIsUserLoggedInStatus(): boolean {
    let isUserLoggedInStatus: boolean;
    this.store
      .pipe(select(isUserLoggedIn), take(1))
      .subscribe((loggedIn) => (isUserLoggedInStatus = loggedIn));
    return isUserLoggedInStatus;
  }

  getTitleErrorMessage(userState: UserStateTypeEnum): string {
    switch (userState) {
      case UserStateTypeEnum.BAD_CREDENTIALS:
        if (['AOCARE', 'SKYPUK'].includes(this.environment.requestSource)) {
          return "Hmm. We don't recognise that username and/ or password";
        } else {
          return 'Login unsuccessful';
        }
      case UserStateTypeEnum.CODE_EXPIRED:
        return 'The validation link didn’t work';
      case UserStateTypeEnum.CONFIRMATION_NOT_REQUIRED:
        return 'Email already verified';
      case UserStateTypeEnum.PASSWORD_RESET_REQ:
        return 'New password required';
      default:
        return 'UNKNOWN ERROR';
    }
  }

  getContentErrorMessage(userState: UserStateTypeEnum): ModalContent {
    switch (userState) {
      case UserStateTypeEnum.BAD_CREDENTIALS: {
        if (['AOCARE', 'SKYPUK'].includes(this.environment.requestSource)) {
          // Temporary message for AO, SKY users. AOWLP-476, AOWLP-710
          return {
            html: true,
            content: "Please note, this won't be your Sky ID.",
          };
        } else {
          return {
            content:
              'Check your email and password are right, then try again. You can also reset your password or create a new account.',
          };
        }
      }
      case UserStateTypeEnum.CODE_EXPIRED:
        return {
          content: 'The link may have expired. Shall we send you a new one?',
        };
      case UserStateTypeEnum.CONFIRMATION_NOT_REQUIRED:
        return {
          content:
            'You have already validated your email address. Please log in to finish creating your account.',
        };
      case UserStateTypeEnum.PASSWORD_RESET_REQ:
        return {
          content:
            'Your profile requires a password reset. Please check your email for further instructions.',
        };
      default:
        return { content: 'Unknown error has occurred' };
    }
  }

  processUserAuthorizationSuccessResponse(userState: string) {
    switch (userState) {
      case UserStateTypeEnum.MATCH_REQ:
        this.handleAddressCheckStatus();
        break;
      case UserStateTypeEnum.COMPLETE:
        this.handleCompleteStatus();
        break;
      case UserStateTypeEnum.CONFIRMATION_REQ:
        this.handleConfirmationStatus();
        break;
      case UserStateTypeEnum.LOCKED:
        this.handleLockedStatus();
        break;
      case UserStateTypeEnum.PASSWORD_RESET_REQ:
        this.handlePasswordResetStatus();
        break;
      case UserStateTypeEnum.CREATE_ACCOUNT:
        this.handleCreateAccountStatus();
        break;
      default:
        // TODO: handle unknown status
        break;
    }
  }

  processUserAuthorizationErrorResponse(state: string) {
    switch (state) {
      // 1.
      case UserStateErrorTypeEnum.UserNotFoundException:
        this.toastService.create({
          msg: ['User does not exist '],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 2.
      case UserStateErrorTypeEnum.UsernameExistsException:
        this.toastService.create({
          msg: ['User already exists'],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 3.
      case UserStateErrorTypeEnum.InvalidParameterException:
        this.toastService.create({
          msg: ['User is already confirmed - please go to login page '],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 4.
      case UserStateErrorTypeEnum.CodeMismatchException:
      case UserStateErrorTypeEnum.ExpiredCodeException:
        this.toastService.create({
          msg: [
            'Your code has expired or is invalid - a new verification link has been sent - please check your email',
          ],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 5.
      case UserStateErrorTypeEnum.NotAuthorizedException:
        this.toastService.create({
          msg: ['Incorrect username or password'],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 6.
      // Geneic error
      case UserStateErrorTypeEnum.LimitExceededException:
        this.toastService.create({
          msg: [
            'LimitExceededException - too many requests - handle this accordingly',
          ],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 7.
      default:
        this.toastService.create({
          msg: ['Unexpected Error - handle this accordingly'],
          theme: 'error',
          icon: 'error',
        });
        return '404';
    }
  }

  public async handleAccountCreationForNewLoginAndReg(
    userEmail: string
  ): Promise<void> {
    localStorage.setItem('D&GLastUsedEmailAddress', userEmail);
    this.newLoginAndRegFacadeService.signUpNewAccount({
      email: userEmail,
    });
  }

  private handleAddressCheckStatus(): void {
    this.router.navigateByUrl('my-account/verification/address');
  }

  public handleCompleteStatus(route: string = 'my-plans'): void {
    const awsIdtCookie = this.cookieService.get('AWS_IDT');

    if (awsIdtCookie) {
      const decodedUser: User = jwt_decode(awsIdtCookie);
      this.store.dispatch(new LogIn({ decodedUser }));
    }

    this.router.navigateByUrl(route);
  }

  private handleConfirmationStatus(): void {
    this.toastService.create({
      msg: [
        'Confirmation of your account is required - please check your email for a verification link',
      ],
      theme: 'error',
      icon: 'error',
    });
    this.router.navigateByUrl(
      this.activatedRoute.snapshot.children[0].url[0].path
    );
  }

  private handleLockedStatus(): void {
    this.router.navigateByUrl('404');
  }

  private handlePlanCheckStatus(): void {
    this.router.navigateByUrl('my-account/verification/plan-number');
  }

  private handleCreateAccountStatus(): void {
    this.router.navigateByUrl('my-account/verify-email?registration');
  }

  private handlePasswordResetStatus(): void {
    this.loginHelper.handleUserStateSuccess(UserStateTypeEnum.CONFIRMATION_REQ);
  }

  /**
   * handleRequestPasswordError
   * This is called when a user enters an email address that is valid (registered with) BUT did not confirm it through the email link
   * 1. On error type InvalidParameterException - call requestVerificationCode service and handle error
   * 2. Carry requestPasswordErrorResponse through
   */
  handleRequestPasswordError(
    err: RequestPasswordErrorResponse,
    payload: RequestVerificationCodePayload
  ) {
    if (err.error_type === UserStateErrorTypeEnum.InvalidParameterException) {
      this.accountService.requestVerificationCode(payload).subscribe(
        () => {
          this.analyticsService.push({
            pagename: 'password-reset-confirmation',
            event: 'maEvent',
            genericpagename: 'password-reset',
            category: 'my-account',
          });

          this.processUserAuthorizationSuccessResponse(
            UserStateTypeEnum.CONFIRMATION_REQ
          );
        },
        () => {
          this.analyticsService.push({
            pagename: 'password-reset-confirmation',
            event_category: 'form - login journey - errors',
            event_action: 'Unexpected error - routing to 404',
            event_label: 'password-reset',
          });
          this.router.navigateByUrl('404');
        }
      );
    } else {
      this.analyticsService.push({
        pagename: 'password-reset-confirmation',
        event_category: 'form - login journey - errors',
        event_action: err.error_message,
        event_label: 'password-reset',
      });
      this.processUserAuthorizationErrorResponse(err.error_type);
    }
  }

  /**
   * handleRequestPasswordError
   * This is called when a user enters a valid password BUT the confirmation_code has expired
   * 1. On error typeof ExpiredCodeException OR ExpiredCodeException - call requestPassword service - PROMPT user with TOAST (in helper file) and send the user a new link. Handle errors accordingly
   * 2. Carry the error through and in this case call handleUserStateError even though we have a succefull response
   * 3. Carry the original ResetPasswordErrorResponse through
   */
  handleResetPasswordError(
    err: ResetPasswordErrorResponse,
    payload: RequestPasswordPayload
  ) {
    if (
      err.error_type === UserStateErrorTypeEnum.ExpiredCodeException ||
      err.error_type === UserStateErrorTypeEnum.CodeMismatchException
    ) {
      // 1.
      this.accountService.requestPassword(payload).subscribe(
        () => {
          this.processUserAuthorizationErrorResponse(err.error_type); // this is correct calling the handleUserStateError - do not edit
        },
        () => {
          // 2.
          this.analyticsService.push({
            event_category: 'form - login journey - errors',
            event_action: 'Unexpected error - routing to 404',
            event_label: 'new-password',
          });
          this.router.navigateByUrl('404');
        }
      );
    } else {
      // 3.
      this.analyticsService.push({
        event_category: 'form - login journey - errors',
        event_action: err.error_message,
        event_label: 'new-password',
      });

      this.processUserAuthorizationErrorResponse(err.error_type);
    }
  }

  getTRSTrackingParams(routeParams: Params): {
    trs_med: string;
    trs_sou: string;
    trs_cam: string;
    trs_con: string;
  } {
    if (routeParams) {
      const { trs_med, trs_sou, trs_cam, trs_con } = routeParams;
      if (trs_med && trs_sou && trs_cam && trs_con) {
        return {
          trs_med: trs_med,
          trs_sou: trs_sou,
          trs_cam: trs_cam,
          trs_con: trs_con,
        };
      }
    }
  }

  handleValidLoginFormSubmission(formData: UntypedFormGroup): void {
    const emailAddress = formData.get('email').value;

    localStorage.setItem(
      'D&GLastUsedEmailAddress',
      emailAddress ? emailAddress : ''
    );

    this.store.dispatch(new ResetAccountState());
    this.store.dispatch(new ResetUserState());
    this.store.dispatch(
      new UserAuthentication({
        email: formData.get('email').value,
        password: formData.get('password').value,
      })
    );
  }

  /**
   * There was a bug in our code whereby we were storing the formcontrol object in localStorage using code
   * localStorage.setItem("D&GLastUsedEmailAddress", JSON.stringify(formData.controls["email"]));
   * This resulted in the user on the login page seeing a JSON string representation of the object as the remembered email. In order to fix it,
   * if we can parse JSON, then clean that property in localStorage.
   */
  resetRememberMeIfNeeded() {
    let lastUsedEmailAddress = localStorage.getItem('D&GLastUsedEmailAddress');
    lastUsedEmailAddress = (lastUsedEmailAddress || '').trim();
    if (!lastUsedEmailAddress) {
      return;
    }
    try {
      const parsedFormControl = JSON.parse(lastUsedEmailAddress);
      if (parsedFormControl) {
        localStorage.removeItem('D&GRememberMe');
        localStorage.removeItem('D&GLastUsedEmailAddress');
      }
      return;
    } catch {
      // Indicates its a string that is stored which is fine. so do nothing.
      return;
    }
  }

  handleInvalidLoginFormSubmission(formData: UntypedFormGroup): void {
    formData.markAllAsTouched();
    this.analyticsService.push(LOGIN_FORM_INVALID_SUBMISSION_EVENT(formData));
  }

  handleValidForgotPasswordFormSubmission(
    formData: UntypedFormGroup
  ): RequestPasswordPayload {
    const formPayload: RequestPasswordPayload = {
      email: formData.get('email').value,
      is_sign_up: false,
    };
    this.store.dispatch(new RequestForgottenPassword(formPayload));
    return formPayload;
  }

  handleValidForgotPasswordFormSubmissionV2(
    formData: UntypedFormGroup
  ): RequestPasswordPayload {
    const formPayload = {
      email: formData.get('email').value,
      is_sign_up: false,
    };

    this.store.dispatch(new ResetUserPassword(formPayload));
    return formPayload;
  }

  handleInvalidForgotPasswordFormSubmission(formData: UntypedFormGroup): void {
    formData.markAllAsTouched();
    this.analyticsService.push(FORGOT_PASSWORD_FORM_INVALID_SUBMISSION_EVENT);
  }

  openUsernameAlreadyExistsErrorModal(): void {
    this.modalDialogService.openModal(new OpenUsernameExistsModal());
  }

  openUnexpectedErrorModal(): void {
    const httpErrorDialog = {
      title: 'Something Went Wrong',
      content: {
        content: 'An unexpected error has occurred. Please try again later',
      },
      alertType: AlertType.ERROR,
      closeButtonLabel: 'Close',
    };
    this.modalDialogService.openModal(
      new OpenAlertModal(httpErrorDialog, '600px', '200px', true)
    );
  }

  getUserType(): string {
    let userType = 'unknown user type - no cookie present';
    const cookie = this.cookieService.get('AWS_IDT');
    if (cookie) {
      const decodedUser: User = jwt_decode(cookie);
      userType =
        decodedUser && decodedUser['custom:wcs_created_at']
          ? 'migrated-user'
          : 'new-user';
    }
    return userType;
  }

  public checkIfCookiesArePresent(): boolean {
    return this.cookieService.get('AWS_ACC') ||
      this.cookieService.get('AWS_IDT')
      ? true
      : false;
  }
}
