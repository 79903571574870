import { UntypedFormGroup } from '@angular/forms';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { User } from '@athome-myaccount/my-account/util-shared';
import { getFormValidationErrors } from '../helpers/form-error-capture';

export const USER_LANDED_ON_LOGIN_PAGE_EVENT = {
  clientName: 'DG My Account',
  event: 'spa-pageview',
  pagename: 'user-login',
  genericpagename: 'user-login',
  category: 'my-account-dgx',
};

export const USER_MANUALLY_LOGS_IN_EVENT = {
  event: 'laEvent',
  laEvent: 'user-attempted-to-manually-log-in',
  event_category: 'user-login-journey-attempt',
  event_action: 'user-authentication-manual-attempt',
  event_label: 'user-login-page',
};

export const LOGIN_FORM_INVALID_SUBMISSION_EVENT = (
  formData: UntypedFormGroup
) => {
  return {
    event: 'laEvent',
    laEvent: 'user-login-invalid-form',
    event_category: 'User',
    event_action: getFormValidationErrors(formData),
    event_label: 'log in page',
  };
};

export const USER_LANDED_ON_FORGOT_PASSWORD_PAGE_EVENT = {
  event: 'laEvent',
  laEvent: 'user-landed-on-forgot-password-page',
  pagename: 'forgot-password-page',
  genericpagename: 'forgot-password-page',
  category: 'my-account-dgx',
};

export const FORGOT_PASSWORD_FORM_INVALID_SUBMISSION_EVENT = (
  formData: UntypedFormGroup
) => {
  return {
    event: 'laEvent',
    laEvent: 'user-forgot-password-form-is-invalid',
    event_category: 'User',
    event_action: getFormValidationErrors(formData),
    event_label: 'forgot-password-page',
  };
};

export const RESEND_FORGOT_PASSWORD_EMAIL_EVENT = {
  event: 'laEvent',
  event_action: 'user-requested-new-email-for-password-reset-resend',
  event_category: 'user-registration-journey',
  event_label: 'forgot-password-page',
  laEvent: 'user-requested-new-password-success',
};

export const USER_REDIRECTS_TO_LOGIN_PAGE_FROM_FORGOT_PASSWORD_PAGE_EVENT = (
  user: User
) => {
  return {
    event: 'laEvent',
    laEvent: 'forgot-password-page',
    customerID: user && user.sub ? user.sub : '',
    DigitalID:
      user && user['custom:digital_id'] ? user['custom:digital_id'] : '',
    pagename: 'password-reset',
    genericpagename: 'password-reset',
    category: 'my-account',
    event_category: 'user-registration-journey',
    event_action: 'userLogin',
    event_label: 'Back-to-login-page',
  };
};

export const USER_LANDS_ON_RESET_PASSWORD_PAGE_EVENT = {
  event: 'laEvent',
  laEvent: 'create-new-password',
  pagename: 'new-password',
  genericpagename: 'new-password',
  category: 'my-account-dgx',
};

export const USER_LANDS_ON_MIGRATED_USER_PAGE_EVENT = {
  event: 'laEvent',
  laEvent: 'landed-on-migrated-user-page',
  pagename: 'migrated-user',
  genericpagename: 'migrated-user',
  category: 'my-account-dgx',
};

export const RESET_PASSWORD_EMAIL_FOR_MIGRATED_USER_SENT_EVENT = {
  event: 'laEvent',
  laEvent: 'migrated-user-page',
  pagename: 'migrated-user',
  genericpagename: 'migrated-user',
  category: 'my-account-dgx',
  event_category: 'user-migration-journey',
  event_action: 'Resend Email',
  event_label: 'resend-password-email-for-migrated-user',
};

export const MIGRATED_USER_REDIRECTS_BACK_TO_LOGIN_PAGE_EVENT = {
  event: 'laEvent',
  laEvent: 'migrated-user-page',
  pagename: 'migrated-user',
  genericpagename: 'migrated-user',
  category: 'my-account-dgx',
  event_category: 'user-registration-journey',
  event_action: 'Back to login',
  event_label: 'migrated-user',
};

export const USER_LANDED_ON_VERIFY_EMAIL_PAGE_EVENT = {
  event: 'laEvent',
  laEvent: 'user-landed-on-verify-email-page',
  pagename: 'user-verify-email-page',
  genericpagename: 'enter-your-email',
  category: 'my-account-dgx',
};

export const USER_REQUESTED_NEW_VERIFICATION_EMAIL_EVENT = (user: User) => {
  return {
    event: 'laEvent',
    laEvent: 'validate-registration-email-page',
    customerID: user && user.sub ? user.sub : '',
    DigitalID:
      user && user['custom:digital_id'] ? user['custom:digital_id'] : '',
    event_category: 'user-registration-journey',
    event_action: 'Resend email',
    event_label: 'verify-email-page',
  };
};

export const USER_DECIDED_TO_RETURN_TO_LOGIN_PAGE_FROM_EMAIL_VERIFICATION_EVENT =
  (user: User) => {
    return {
      event: 'laEvent',
      laEvent: 'validate-registration-email-page',
      customerID: user && user.sub ? user.sub : '',
      DigitalID:
        user && user['custom:digital_id'] ? user['custom:digital_id'] : '',
      pagename: 'validate-registration-email-sent',
      genericpagename: 'validate-registration-email-sent',
      category: 'my-account-dgx',
      event_category: 'user-registration-journey',
      event_action: 'Back to login',
      event_label: 'verify-email-page',
    };
  };

// OLD EVENTS

export const USER_LANDED_ON_ADDRESS_VERIFICATION_PAGE = (user: User) => {
  return {
    event: 'laEvent',
    laEvent: 'user-landed-on-address-verification-page',
    pagename: 'user-verify-address-page',
    genericpagename: 'enter-your-address-registration',
    category: 'my-account-dgx',
    user_type:
      user && user['custom:wcs_created_at'] ? 'migrated-user' : 'new-user',
  };
};

export const USER_AUTO_LOGGED_IN_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-auto-logged-in',
    event_category: 'user-login-journey-success',
    event_action: 'user-authentication-response-success',
    event_label: 'auto-login',
  });
};

export const USER_MANUALLY_LOGGED_IN_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-attempted-to-manually-log-in',
    event_category: 'user-login-journey-attempt',
    event_action: 'user-authentication-manual-attempt',
    event_label: 'user-login-page',
  });
};

export const USER_LOGGED_IN_SUCCESSFULLY_EVENT = (
  analyticsService: AnalyticsService,
  userType: string
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-succesfully-logged-in',
    event_category: 'user-login-journey-success',
    event_action: 'user-authentication-response-success',
    event_label: 'user-login-page',
    user_type: userType,
  });
};

export const USER_LOGGED_IN_FAILURE_EVENT = (
  analyticsService: AnalyticsService,
  payload: any
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-failed-to-log-in',
    event_category: 'user-login-journey-errors',
    event_action: payload.error.error_message,
    event_label: 'user-login-page',
  });
};

export const USER_VERIFIED_ADDRESS_SUCCESS_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-address-successfuly-verified',
    event_category: 'user-registration-journey',
    event_action: 'address-verification-response-success',
    event_label: 'user-address-verification-page',
  });
};

export const USER_VERIFIED_ADDRESS_FAILURE_EVENT = (
  analyticsService: AnalyticsService,
  payload: any
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-address-not-verified-successfuly',
    event_category: 'user-registration-journey',
    event_action: payload.error.error_message,
    event_label: 'user-address-verification-page',
  });
};

export const USER_ATTEMPTED_TO_REGISTER_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user_registration',
  });
};

export const USER_FAILURE_TO_REGISTER_EVENT = (
  analyticsService: AnalyticsService,
  payload: any
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user_failed_to_register',
    event_category: 'user-registration-journey',
    event_action: 'create-new-account-response-failure',
    event_label: 'user-address-verification-page',
  });
};

export const USER_FORGOTTEN_PASSWORD_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-requested-forgot-password',
    event_category: 'User',
    event_action: 'userLogin',
    event_label: 'Resend email click',
  });
};

export const USER_LANDED_ON_ADDRESS_VERIFICATION_PAGE_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-landed-on-address-verification-page',
    pagename: 'user-verify-address-page',
    genericpagename: 'enter-your-address-registration',
    category: 'my-account-dgx',
  });
};

export const USER_LANDED_ON_PLAN_VERIFICATION_PAGE_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'user-landed-on-plan-verification-page',
    pagename: 'user-verify-plan-page',
    genericpagename: 'enter-your-plan-number-registration',
    category: 'my-account-dgx',
  });
};

export const USER_ADDRESS_VERIFICATION_SUCCESS_EVENT = (
  analyticsService: AnalyticsService,
  user: User
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'address-verification-success',
    customerID: user && user.sub ? user.sub : '',
    DigitalID: user['custom:digital_id'] ? user['custom:digital_id'] : '',
    RegistrationVal: '1',
  });
};

export const USER_CLICKED_OPTION_TO_MANUALLY_ENTER_ADDRESS_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'address-manual-entry',
    event_category: 'form-registration-journey-address',
    event_action: 'interaction',
    event_label: 'enterAddressManually',
  });
};

export const USER_SUBMITTED_ADDRESS_FOR_REGISTRATION_EVENT = (
  analyticsService: AnalyticsService,
  controls: any
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'address-field-complete',
    event_category: 'form-registration-journey-address',
    event_action: 'complete',
    event_label: 'user-submitted-address-for-verification',
  });
};

export const USER_ENTERED_PASSWORD_RESET_PAGE_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'password-reset',
    pagename: 'password-reset',
    genericpagename: 'password-reset',
    category: 'my-account',
  });
};

export const USER_ENTERED_LOGIN_PAGE_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'login-page',
    pagename: 'user-login',
    genericpagename: 'user-login',
    category: 'my-account-dgx',
  });
};

export const USER_ENTERED_CREATE_NEW_PASSWORD_PAGE_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'create-new-password',
    pagename: 'new-password',
    genericpagename: 'new-password',
    category: 'my-account-dgx',
  });
};

export const USER_PLAN_NUMBER_VERIFICATION_SUCCESS_EVENT = (
  analyticsService: AnalyticsService,
  user: User,
  plan_number: string
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'plan-number-verification-success',
    pagename: 'registration-completed',
    genericpagename: 'registration-completed',
    customerID: user && user.sub ? user.sub : '',
    DigitalID:
      user && user['custom:digital_id'] ? user['custom:digital_id'] : '',
    RegistrationVal: '1',
    PlanNumber: plan_number,
  });
};

export const USER_PLAN_NUMBER_VERIFICATION_FAILURE_EVENT = (
  analyticsService: AnalyticsService,
  plan_number: string
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'plan-number-verification-failure',
    event_category: 'form-registration-journey-plan-errors',
    event_action: 'enter-plan-number-page',
    event_label: plan_number,
  });
};

export const USER_CLICKED_WHERE_IS_PLAN_NUMBER_EVENT = (
  analyticsService: AnalyticsService
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'plan-number-field-complete',
    event_category: 'form-registration-journey-plan-number',
    event_action: 'interaction',
    event_label: 'where-is-plan-number',
  });
};

export const ATTEMPTED_TO_SEND_VERIFICATION_EMAIL_FOR_USER_EVENT = (
  analyticsService: AnalyticsService,
  user: User
) => {
  analyticsService.push({
    event: 'laEvent',
    laEvent: 'validate-registration-email-page',
    pagename: 'validate-registration-email-sent',
    genericpagename: 'validate-registration-emaill-sent',
    category: 'my-account-dgx',
    customerID: user && user.sub ? user.sub : '',
    DigitalID: user['custom:digital_id'] ? user['custom:digital_id'] : '',
  });
};
