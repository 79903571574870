import { HttpErrorResponse } from '@angular/common/http';
import {
  IMyDetailsEditSectionsState,
  PersonDetails,
} from '@athome-myaccount/my-account/util-login';
import {
  CreateAccountErrorResponse,
  CreateAccountPayload,
  IUserSignUpAndMatchPayload,
  LoginErrorResponse,
  LoginPayload,
  RequestPasswordErrorResponse,
  RequestPasswordPayload,
  RequestVerificationCodeErrorResponse,
  RequestVerificationCodePayload,
  ResetPasswordErrorResponse,
  ResetPasswordPayload,
  User,
  UserMatchPayload,
  UserMatchSuccessResponse,
  VerificationCodePayload,
} from '@athome-myaccount/my-account/util-shared';
import { Action } from '@ngrx/store';

export enum UserActionTypes {
  UserAuthentication = '[Login] User Authentication',
  UserAuthenticationSuccess = '[Login] User Authentication Success',
  UserAuthenticationFailure = '[Login] User Authentication Failure',
  VerifyAddress = '[Login] VerifyAddress',
  VerifyAddressSuccess = '[Login] VerifyAddress Success',
  VerifyAddressFailure = '[Login] VerifyAddress Failure',
  CreateAccount = '[Login] CreateAccount',
  CreateAccountSuccess = '[Login] CreateAccount Success',
  CreateAccountFailure = '[Login] CreateAccount Failure',
  RequestForgottenPassword = '[Login] RequestForgottenPassword',
  RequestForgottenPasswordSuccess = '[Login] RequestForgottenPassword Success',
  RequestForgottenPasswordFailure = '[Login] RequestForgottenPassword Failure',
  RequestVerificationCode = '[Login] RequestVerificationCode',
  RequestVerificationCodeSuccess = '[Login] RequestVerificationCode Success',
  RequestVerificationCodeFailure = '[Login] RequestVerificationCode Failure',
  RequestVerificationToken = '[Login] RequestVerificationToken',
  RequestVerificationTokenSuccess = '[Login] RequestVerificationToken Success',
  RequestVerificationTokenFailure = '[Login] RequestVerificationToken Failure',
  VerifyUserEmailInbox = '[Login] VerifyUserEmailInbox',
  VerifyUserEmailInboxSuccess = '[Login] VerifyUserEmailInbox Success',
  VerifyUserEmailInboxFailure = '[Login] VerifyUserEmailInbox Failure',
  VerifyConfirmationCode = '[Login] VerifyConfirmationCode',
  VerifyConfirmationCodeSuccess = '[Login] VerifyConfirmationCode Success',
  VerifyConfirmationCodeFailure = '[Login] VerifyConfirmationCode Failure',
  ResetPassword = '[Login] ResetPassword',
  ResetPasswordSuccess = '[Login] ResetPassword Success',
  ResetPasswordFailure = '[Login] ResetPassword Failure',
  VerifyPlanNumber = '[Login] VerifyPlanNumber',
  VerifyPlanNumberSuccess = '[Login] VerifyPlanNumber Success',
  VerifyPlanNumberFailure = '[Login] VerifyPlanNumber Failure',
  LogIn = '[Login] Log In',
  LogOut = '[Login] Log Out',
  RedirectToLogout = '[Logout] Redirect to logout',
  ResetUserState = '[Login] Reset User State',
  ResetUserResponses = '[Login] Reset User Responses',
  ResetFormCredentials = '[Login] Reset Form Credentails',
  UpdateMyDetailsActiveEditSection = '[My Details Component] Update My Details Active Edit Section',
  UpdatePersonalDetails = '[My Details Component] Update Personal Details',
  SetSignUpAndMatchPayload = '[Login] Set Sign Up And Match Payload',
  SetCorrelationId = '[Login] Set Correlation Id',
}

export class SetSignUpAndMatchPayload implements Action {
  readonly type = UserActionTypes.SetSignUpAndMatchPayload;
  constructor(public payload: IUserSignUpAndMatchPayload) {}
}

export class ResetUserState implements Action {
  readonly type = UserActionTypes.ResetUserState;
}

export class ResetUserResponses implements Action {
  readonly type = UserActionTypes.ResetUserResponses;
}

export class UserAuthentication implements Action {
  readonly type = UserActionTypes.UserAuthentication;
  constructor(public payload: LoginPayload) {}
}

export class UserAuthenticationSuccess implements Action {
  readonly type = UserActionTypes.UserAuthenticationSuccess;
  constructor(
    public payload: { response: any; correlationId: string; email: string }
  ) {}
}

export class SetCorrelationId implements Action {
  readonly type = UserActionTypes.SetCorrelationId;
  constructor(public payload: { correlationId: string }) {}
}

export class UserAuthenticationFailure implements Action {
  readonly type = UserActionTypes.UserAuthenticationFailure;
  constructor(
    public payload: {
      error: LoginErrorResponse | RequestPasswordErrorResponse;
      loginFormCredentials: any;
    }
  ) {}
}

export class VerifyAddress implements Action {
  readonly type = UserActionTypes.VerifyAddress;
  constructor(public payload: { userMatch: UserMatchPayload }) {}
}

export class VerifyAddressSuccess implements Action {
  readonly type = UserActionTypes.VerifyAddressSuccess;
  constructor(
    public payload: { userMatchSuccessResponse: UserMatchSuccessResponse }
  ) {}
}

export class VerifyAddressFailure implements Action {
  readonly type = UserActionTypes.VerifyAddressFailure;
  constructor(public payload: { error: any }) {}
}

export class VerifyPlanNumber implements Action {
  readonly type = UserActionTypes.VerifyPlanNumber;
  constructor(public payload: { userMatch: UserMatchPayload }) {}
}

export class VerifyPlanNumberSuccess implements Action {
  readonly type = UserActionTypes.VerifyPlanNumberSuccess;
  constructor(
    public payload: {
      userMatchSuccessResponse: UserMatchSuccessResponse;
      planNumber: string;
    }
  ) {}
}

export class VerifyPlanNumberFailure implements Action {
  readonly type = UserActionTypes.VerifyPlanNumberFailure;
  constructor(public payload: { error: any; planNumber: string }) {}
}

export class CreateAccount implements Action {
  readonly type = UserActionTypes.CreateAccount;
  constructor(public payload: { createAccountPayload: CreateAccountPayload }) {}
}

export class CreateAccountSuccess implements Action {
  readonly type = UserActionTypes.CreateAccountSuccess;
  constructor(public payload: any) {}
}

export class CreateAccountFailure implements Action {
  readonly type = UserActionTypes.CreateAccountFailure;
  constructor(public payload: { error: CreateAccountErrorResponse }) {}
}

export class RequestVerificationToken implements Action {
  readonly type = UserActionTypes.RequestVerificationToken;
  constructor(public payload: { email: string; token: string }) {}
}

export class RequestVerificationTokenSuccess implements Action {
  readonly type = UserActionTypes.RequestVerificationTokenSuccess;
  constructor(public payload: any) {}
}

export class RequestVerificationTokenFailure implements Action {
  readonly type = UserActionTypes.RequestVerificationTokenFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class RequestVerificationCode implements Action {
  readonly type = UserActionTypes.RequestVerificationCode;
  constructor(public payload: RequestVerificationCodePayload) {}
}

export class RequestVerificationCodeSuccess implements Action {
  readonly type = UserActionTypes.RequestVerificationCodeSuccess;
  constructor(public payload: any) {}
}

export class RequestVerificationCodeFailure implements Action {
  readonly type = UserActionTypes.RequestVerificationCodeFailure;
  constructor(
    public payload: { error: RequestVerificationCodeErrorResponse }
  ) {}
}

export class VerifyUserEmailInbox implements Action {
  readonly type = UserActionTypes.VerifyUserEmailInbox;
  constructor(public payload: VerificationCodePayload) {}
}

export class VerifyUserEmailInboxSuccess implements Action {
  readonly type = UserActionTypes.VerifyUserEmailInboxSuccess;
  constructor(public payload: any) {}
}

export class VerifyUserEmailInboxFailure implements Action {
  readonly type = UserActionTypes.VerifyUserEmailInboxFailure;
  constructor(public payload: { error: any; email: any }) {}
}
export class VerifyConfirmationCode implements Action {
  readonly type = UserActionTypes.VerifyConfirmationCode;
  constructor(public payload: VerificationCodePayload) {}
}

export class VerifyConfirmationCodeSuccess implements Action {
  readonly type = UserActionTypes.VerifyConfirmationCodeSuccess;
  constructor(public payload: any) {}
}

export class VerifyConfirmationCodeFailure implements Action {
  readonly type = UserActionTypes.VerifyConfirmationCodeFailure;
  constructor(public payload: { error: any }) {}
}

export class RequestForgottenPassword implements Action {
  readonly type = UserActionTypes.RequestForgottenPassword;
  constructor(public payload: RequestPasswordPayload) {}
}

export class RequestForgottenPasswordSuccess implements Action {
  readonly type = UserActionTypes.RequestForgottenPasswordSuccess;
  constructor(public payload: RequestPasswordPayload) {}
}

export class RequestForgottenPasswordFailure implements Action {
  readonly type = UserActionTypes.RequestForgottenPasswordFailure;
  constructor(
    public payload: {
      error: RequestPasswordErrorResponse | any;
      payload: RequestVerificationCodePayload | any;
    }
  ) {}
}

export class ResetPassword implements Action {
  readonly type = UserActionTypes.ResetPassword;
  constructor(
    public payload: {
      resetPasswordPayload: ResetPasswordPayload;
      loginPayload: LoginPayload;
    }
  ) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = UserActionTypes.ResetPasswordSuccess;
  constructor(public payload: LoginPayload) {}
}

export class ResetPasswordFailure implements Action {
  readonly type = UserActionTypes.ResetPasswordFailure;
  constructor(
    public payload: {
      error: ResetPasswordErrorResponse | LoginErrorResponse | any;
      payload: LoginPayload;
    }
  ) {}
}

export class ResetFormCredentials implements Action {
  readonly type = UserActionTypes.ResetFormCredentials;
}

export class LogIn implements Action {
  readonly type = UserActionTypes.LogIn;
  constructor(public payload: { decodedUser: User }) {}
}

export class LogOut implements Action {
  readonly type = UserActionTypes.LogOut;
}

export class RedirectToLogout implements Action {
  readonly type = UserActionTypes.RedirectToLogout;
}

export class UpdateActiveEditSection implements Action {
  readonly type = UserActionTypes.UpdateMyDetailsActiveEditSection;
  constructor(public payload: { editSection: IMyDetailsEditSectionsState }) {}
}

export class UpdatePersonalDetails implements Action {
  readonly type = UserActionTypes.UpdatePersonalDetails;
  constructor(public payload: { personalDetails: PersonDetails }) {}
}

export type UserActions =
  | UserAuthentication
  | UserAuthenticationSuccess
  | UserAuthenticationFailure
  | VerifyAddress
  | VerifyAddressSuccess
  | VerifyAddressFailure
  | VerifyPlanNumber
  | VerifyPlanNumberSuccess
  | VerifyPlanNumberFailure
  | VerifyUserEmailInbox
  | VerifyUserEmailInboxSuccess
  | VerifyUserEmailInboxFailure
  | VerifyConfirmationCode
  | VerifyConfirmationCodeSuccess
  | VerifyConfirmationCodeFailure
  | CreateAccount
  | CreateAccountSuccess
  | CreateAccountFailure
  | RequestVerificationCode
  | RequestVerificationCodeSuccess
  | RequestVerificationCodeFailure
  | RequestVerificationToken
  | RequestVerificationTokenSuccess
  | RequestVerificationTokenFailure
  | RequestForgottenPassword
  | RequestForgottenPasswordSuccess
  | RequestForgottenPasswordFailure
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | LogIn
  | LogOut
  | ResetUserState
  | ResetUserResponses
  | ResetFormCredentials
  | UpdateActiveEditSection
  | UpdatePersonalDetails
  | SetSignUpAndMatchPayload
  | SetCorrelationId
  | RedirectToLogout;
