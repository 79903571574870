export interface User {
  sub?: string | null; // User id
  'custom:user_state': string; // is completed, re password etc
  iss: string; // issuer (cognito user pool)
  'cognito:username': string; // email
  aud: string; // api to target with IGNORE
  event_id: string; // IGNORE internal
  token_use: string; // identifies as type of access token
  auth_time: number; // when loggin happened from server time
  'custom:digital_id'; // digital ID
  name: string; // user name
  exp: number; // cookie expeiry time unix seconds
  iat: number; // issued cookie from this time
  family_name: string; // user surname
  email: string; // email
  'custom:wcs_created_at'?; // if present the user is a migrated one else its a new user
}

export interface CookieDef {
  name: string;
  value: string;
  expires: number;
  path: string;
  domain: string;
  secure: boolean;
}
