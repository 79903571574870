import {
  ModalAlertData,
  ModalConfirmData,
} from '@athome-myaccount/my-account/util-shared';
import { Action } from '@ngrx/store';

export enum ModalActionTypes {
  OpenAlertModal = '[Modal] Open Alert',
  OpenConfirmModal = '[Modal] Open Confirm Modal',
  OpenUnsupportedModal = '[Modal] Open Unsupported Modal',
  OpenUsernameExistsModal = '[Modal] Username Already Exists Modal',
  OpenUserIdExistsModal = '[Modal] User Id Exists Modal',
  CloseModals = '[Modal] Close Modals',
}

export class OpenAlertModal implements Action {
  readonly type = ModalActionTypes.OpenAlertModal;
  constructor(
    public modalData: ModalAlertData,
    public modalWidth: string,
    public modalHeight: string,
    public disableClose: boolean,
    // eslint-disable-next-line @typescript-eslint/ban-types
    public callback?: Function
  ) {}
}

export class OpenConfirmModal implements Action {
  readonly type = ModalActionTypes.OpenConfirmModal;
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    public callBackFunction: Function,
    public modalConfirmData: ModalConfirmData,
    public modalWidth: string,
    public modalHeight: string,
    public disableClose: boolean
  ) {}
}

export class OpenUnsupportedModal implements Action {
  readonly type = ModalActionTypes.OpenUnsupportedModal;
}

export class OpenUsernameExistsModal implements Action {
  readonly type = ModalActionTypes.OpenUsernameExistsModal;
}

export class OpenUserIdExistsModel implements Action {
  readonly type = ModalActionTypes.OpenUserIdExistsModal;
}

export class CloseModal implements Action {
  readonly type = ModalActionTypes.CloseModals;
}
