import { Component, Input } from '@angular/core';

@Component({
  selector: 'athome-myaccount-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent {
  @Input() label?: string;
}
