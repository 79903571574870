import { MockAddress } from '../mock-utils/mock-address';
import { MockUser } from '../mock-utils/mock-user';
import { MockUtils } from '../mock-utils/mock-utils';

const generateRandomMarketingPreferences = {
  clientCode: 'client code',
  clientCodeDescription: 'client code description',
  clientGroupCode: 'client group',
  clientGroupCodeDescription: 'client group description',
  email: MockUtils.randomArray([true, false, null]),
  telephone: MockUtils.randomArray([true, false, null]),
  post: MockUtils.randomArray([true, false, null]),
  isEdited: false,
};

export const generateRandomPersonalDetails = {
  title: MockUtils.randomArray(['Mr', 'Mrs', 'Miss', 'Ms', 'Sir', 'Dr']),
  name: MockUser.firstName(),
  surname: MockUser.lastName(),
  email: MockUser.emailAddress(),
  mobile: MockUser.phoneNumber,
  landline: MockUser.phoneNumber,
  address: MockAddress.firstLine,
  marketingPreferences: generateRandomMarketingPreferences,
};
