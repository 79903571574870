import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'athome-myaccount-username-already-exists',
  templateUrl: './modal-dialog-userid-invalid.component.html',
  styleUrls: ['./modal-dialog-userid-invalid.component.scss'],
})
export class ModalDialogUserIdInvalidComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<ModalDialogUserIdInvalidComponent>
  ) {}

  closeModal() {
    this.dialogRef.close();
  }
}
