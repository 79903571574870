import { mockEnvironment } from './environment.spec.helper';

export const mockAppConfig = {
  ...mockEnvironment,
  api: 'http://dummy.url.com',
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  cookieProDomainScript: 'd4b1686c-ce8f-4234-95da-664710923870-test',
  footerLinksBuilder: (basePath: string) => {
    return {
      protectLinks: [],
      usefulLinks: [],
      legalLinks: [],
    };
  },
  headerLinksBuilder: (basePath: string) => [],
};
