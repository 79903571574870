<div>
  <div
    class="error-info-container"
    fxLayout="column"
    fxLayoutAlign="stretch center"
    fxLayoutGap="40px"
  >
    <div class="typog-h3">Something's gone wrong</div>
    <div class="typog-body-large">
      We're sorry, your plans aren't loading right now. This might be because
      you don't have an active plan, or it might be a temporary error.
    </div>
    <div class="typog-body-large">
      Please try again later or talk to us if you need help sooner.
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayout.xs="column"
      fxLayoutAlign.xs="center stretch"
    >
      <div class="cta-container-button">
        <app-button
          [text]="'Get more help'"
          classes="btn btn--secondary"
          (click)="getMoreHelp()"
        ></app-button>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayout.xs="column"
      fxLayoutAlign.xs="center stretch"
    >
      <div class="cta-container-button">
        <app-button
          [text]="'Try to load again'"
          classes="btn btn--primary"
          (click)="tryAgain()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
