import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GetPersonalDetailsResponse } from '../../containers/my-details/models/get-personal-details.model';
import {
  EMAIL_MAX_LENGTH_VALIDATION,
  EMAIL_VALIDATION,
  EMAIL_VALIDATION_PERSONAL_DETAILS,
  LANDLINE_MAX_LENGTH_VALIDATION,
  LANDLINE_MIN_LENGTH_VALIDATION,
  NAME_MAX_LENGTH_VALIDATION,
  NAME_MIN_LENGTH_VALIDATION,
  NAME_SURNAME_VALIDATION,
  PASSWORD_VALIDATION,
  PLAN_NUMBER_VALIDATION,
  RegexFormValidationsEnum,
  REQUIRED_FIELD_VALIDATION,
  SURNAME_MAX_LENGTH_VALIDATION,
  SURNAME_MIN_LENGTH_VALIDATION,
  UK_MOBILE_AND_LANDLINE_NUMBER,
  UK_MOBILE_NUMBER,
} from '../regex-form-validations/regex';

export const NEW_LOGIN_AND_REG_FORM = (
  translateService: TranslateService,
  localStorage: Storage,
  autoAccountEmail?: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.9'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.1'
              ),
              EMAIL_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.2'
              ),
            ],
            control: 'email',
            value: autoAccountEmail
              ? autoAccountEmail
              : localStorage?.getItem('D&GRememberMe') !== null
              ? localStorage.getItem('D&GLastUsedEmailAddress')
              : '',
            placeholder: '',
            classes: 'form-control',
            addressOptions: {},
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.10'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.3'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
            useShowPassword: true,
          },
        },
        {
          checkbox: {
            control: 'rememberMeNewLoginAndReg',
            value:
              localStorage?.getItem('D&GRememberMe') !== null ? true : false,
            text: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Login_Form_Page.13'
            ),
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.14'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              showTick: true,
              submitted: false,
              classes: 'btn btn--primary btn--block',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const NEW_LOGIN_FORM = (
  translateService: TranslateService,
  localStorage: Storage,
  autoAccountEmail?: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.9'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.1'
              ),
              EMAIL_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.2'
              ),
            ],
            control: 'email',
            value: autoAccountEmail
              ? autoAccountEmail
              : localStorage?.getItem('D&GRememberMe') !== null
              ? localStorage.getItem('D&GLastUsedEmailAddress')
              : '',
            placeholder: '',
            classes: 'form-control',
            addressOptions: {},
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.10'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.3'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
            useShowPassword: true,
          },
        },
        {
          checkbox: {
            control: 'rememberMe',
            value:
              localStorage?.getItem('D&GRememberMe') !== null ? true : false,
            text: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Login_Form_Page.13'
            ),
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.14'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const LOGIN_FORM = (
  translateService: TranslateService,
  localStorage: Storage,
  autoAccountEmail?: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.9'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.1'
              ),
              EMAIL_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.2'
              ),
            ],
            control: 'email',
            value: autoAccountEmail
              ? autoAccountEmail
              : localStorage?.getItem('D&GRememberMe') !== null
              ? localStorage.getItem('D&GLastUsedEmailAddress')
              : '',
            placeholder: '',
            classes: 'form-control',
            addressOptions: {},
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.10'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.3'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control',
            useShowPassword: true,
            dgxQmSanitise: 'block',
          },
        },
        {
          checkbox: {
            control: 'rememberMe',
            value:
              localStorage?.getItem('D&GRememberMe') !== null ? true : false,
            text: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Login_Form_Page.13'
            ),
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Login_Form_Page.14'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const CREATE_NEW_ACCOUNT_FORM = (translateService: TranslateService) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant('Email_Modal.email'),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'Email_Modal.email_validation_required'
              ),
              EMAIL_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.5'
              ),
            ],
            control: 'email',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Continue',
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              showTick: true,
              classes: 'btn btn--primary btn--block',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const FORGOT_PASSWORD_FORM = (translateService: TranslateService) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant('Email_Modal.email'),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'Email_Modal.email_validation_required'
              ),
              EMAIL_VALIDATION(
                translateService,
                'Email_Modal.email_validation_format'
              ),
            ],
            control: 'email',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Email me a link',
              showTick: true,
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const CAPTURE_PERSONAL_DETAILS_FORM = (
  translateService: TranslateService
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.9'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.1'
              ),
              NAME_MIN_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.4'
              ),
              NAME_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.5'
              ),
              NAME_SURNAME_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.2'
              ),
            ],
            control: 'name',
            placeholder: '',
            classes: 'form-control',
            value: '',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.10'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.3'
              ),
              SURNAME_MIN_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.4'
              ),
              SURNAME_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.6'
              ),
              NAME_SURNAME_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.2'
              ),
            ],
            control: 'surname',
            placeholder: '',
            classes: 'form-control',
            value: '',
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.3'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const CREATE_NEW_ACCOUNT_PERSONAL_DETAILS_FORM = (
  translateService: TranslateService,
  previousCredentials: any
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.9'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.1'
              ),
              NAME_MIN_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.4'
              ),
              NAME_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.5'
              ),
              NAME_SURNAME_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.2'
              ),
            ],
            control: 'name',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.name : '',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.10'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.3'
              ),
              SURNAME_MIN_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.4'
              ),
              SURNAME_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.6'
              ),
              NAME_SURNAME_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.2'
              ),
            ],
            control: 'surname',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.surname : '',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.12'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.6'
              ),
              PASSWORD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.7'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.password : '',
            useShowPassword: true,
            PasswordStrengthChecker: {
              showLength: true,
              showUppercase: true,
              showLowercase: true,
              showNumber: true,
              showComplex: true,
            },
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.1'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const CREATE_ACCOUNT_FORM = (
  translateService: TranslateService,
  previousCredentials: any
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.9'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.1'
              ),
              NAME_MIN_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.4'
              ),
              NAME_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.5'
              ),
              NAME_SURNAME_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.2'
              ),
            ],
            control: 'name',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.name : '',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.10'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.3'
              ),
              SURNAME_MIN_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.4'
              ),
              SURNAME_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Login_Form_Validations.6'
              ),
              NAME_SURNAME_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.2'
              ),
            ],
            control: 'surname',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.surname : '',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.11'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.4'
              ),
              EMAIL_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.5'
              ),
              //EMAIL_MIN_LENGTH_VALIDATION(translateService, "IAM_Registration_Pages_v0.6.Create_Account_Form.5"),
              EMAIL_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.5'
              ),
            ],
            control: 'email',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.email : '',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.12'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.6'
              ),
              PASSWORD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.7'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control',
            value: previousCredentials ? previousCredentials.password : '',
            useShowPassword: true,
            PasswordStrengthChecker: {
              showLength: true,
              showUppercase: true,
              showLowercase: true,
              showNumber: true,
              showComplex: true,
            },
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Create_Account_Page.1'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const ADDRESS_VERIFICATION_FORM = (
  translateService: TranslateService
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Manual_Address.4'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Form.1'
              ),
            ],
            control: 'addressLine1',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Manual_Address.5'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Form.2'
              ),
            ],
            control: 'addressLine2',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Manual_Address.7'
              ),
              classes: 'form-label',
            },
            control: 'postcode',
            placeholder: '',
            classes: 'form-control',
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Form.3'
              ),
            ],
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Registration_Pages_v0.6.Address_Verfication_Page.Form.4'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const PASSWORD_RESET_FORM = (translateService: TranslateService) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Password_Reset_Page.3'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.1'
              ),
              PASSWORD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.7'
              ),
            ],
            control: 'password',
            placeholder: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.2'
            ),
            classes: 'form-control',
            useShowPassword: true,
            PasswordStrengthChecker: {
              showLength: true,
              showUppercase: true,
              showLowercase: true,
              showNumber: true,
              showComplex: true,
            },
            dgxQmSanitise: 'block',
          },
        },
        {
          hint: {
            classes: 'link link-underline align-right d-block',
            markup: {
              link: true,
              href: '/my-account/forgot-password',
            },
            text: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Login_Form_Page.12'
            ),
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Password_Reset_Page.6'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const AUTO_ACCOUNT_FORM = (translateService: TranslateService) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Password_Reset_Page.3'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.1'
              ),
              PASSWORD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.7'
              ),
            ],
            control: 'password',
            placeholder: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.2'
            ),
            classes: 'form-control',
            useShowPassword: true,
            PasswordStrengthChecker: {
              showLength: true,
              showUppercase: true,
              showLowercase: true,
              showNumber: true,
              showComplex: true,
            },
            dgxQmSanitise: 'block',
          },
        },
        {
          hint: {
            classes: 'link link-underline align-right d-block',
            markup: {
              link: true,
              href: '/my-account/forgot-password',
            },
            text: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Login_Form_Page.12'
            ),
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Auto_Account.3'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const MY_DETAILS_PASSWORD_RESET_FORM = (
  translateService: TranslateService
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Password_Reset_Page.3'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.1'
              ),
              PASSWORD_VALIDATION(
                translateService,
                'IAM_Registration_Pages_v0.6.Create_Account_Form.7'
              ),
            ],
            control: 'password',
            placeholder: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Password_Reset_Form_Validations.2'
            ),
            classes: 'form-control',
            useShowPassword: true,
            PasswordStrengthChecker: {
              showLength: true,
              showUppercase: true,
              showLowercase: true,
              showNumber: true,
              showComplex: true,
            },
            dgxQmSanitise: 'block',
          },
        },
        {
          hint: {
            classes: 'link link-underline align-right d-block',
            markup: {
              link: true,
              href: '/my-account/forgot-password',
            },
            text: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Login_Form_Page.12'
            ),
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Save Changes',
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const VERIFY_USERID_WITH_PASSWORD = (
  translateService: TranslateService
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.VerifyFormPasswordLabel'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.PasswordValidationMessage'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control display-flex',
            value: '',
            useShowPassword: true,
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Continue',
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
  };
};

export const MY_DETAILS_EDIT_USERID_FORM = (
  translateService: TranslateService,
  email: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.EmailUsernameLabel'
              ),
              classes: 'form-label display-flex',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.UsernameRequired'
              ),
              EMAIL_VALIDATION_PERSONAL_DETAILS(
                translateService,
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.EmailValidationMessage'
              ),
              EMAIL_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.EmailValidationMessage'
              ),
            ],
            control: 'email',
            value: email ?? '',
            placeholder: '',
            classes: 'form-control',
            addressOptions: {},
            dgxQmSanitise: 'block',
            labelTooltip: {
              text: [
                'You can change your email address here.',
                "Remember, you'll need to verify it using the link we email you.",
              ],
              classes: 'my-details-tooltip',
            },
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.PasswordLabel'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Update_UserId_form.PasswordValidationMessage'
              ),
            ],
            control: 'password',
            placeholder: '',
            classes: 'form-control display-flex',
            value: '',
            useShowPassword: true,
            dgxQmSanitise: 'block',
            labelTooltip: {
              text: 'For security purposes, this password needs to be the current one associated with this account.',
              classes: 'my-details-tooltip  margin-3',
            },
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Save changes',
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
  };
};

// TODO
export const MY_DETAILS_CONTACT_DETAILS_FORM = (
  translateService: TranslateService,
  personalDetails: GetPersonalDetailsResponse
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: 'Home Telephone',
              classes: 'form-label',
            },
            validators: [
              UK_MOBILE_AND_LANDLINE_NUMBER(
                translateService,
                'Please enter a valid telephone number'
              ),
              LANDLINE_MIN_LENGTH_VALIDATION(
                translateService,
                'Minimum 11 characters are required'
              ),
              LANDLINE_MAX_LENGTH_VALIDATION(
                translateService,
                'Maximum 13 characters are allowed'
              ),
            ],
            control: 'landline',
            value:
              personalDetails?.result?.person?.contact?.landlinePhoneNumber ??
              '',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: 'Mobile',
              classes: 'form-label',
            },
            validators: [
              UK_MOBILE_NUMBER(
                translateService,
                'Please enter a valid phone number'
              ),
            ],
            control: 'mobile',
            value:
              personalDetails?.result?.person?.contact?.mobilePhoneNumber ?? '',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Save Changes',
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
  };
};

export const MY_DETAILS_EMAIL_CONFIRMATION_FORM = (
  translateService: TranslateService,
  email: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Personal_Details_v0.3.Document_Preference.email'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Personal_Details_v0.3.Document_Preference.Validators.required'
              ),
              EMAIL_VALIDATION_PERSONAL_DETAILS(
                translateService,
                'IAM_Personal_Details_v0.3.Document_Preference.Validators.valid_format'
              ),
              EMAIL_MAX_LENGTH_VALIDATION(
                translateService,
                'IAM_Personal_Details_v0.3.Document_Preference.Validators.valid_format'
              ),
            ],
            control: 'email',
            value: email ?? '',
            placeholder: '',
            classes: 'form-control form-control-email',
            addressOptions: {},
          },
          classes: 'input-form-group',
          dgxQmSanitise: 'block',
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Confirm',
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary',
            },
          ],
          classes: 'button-form-group',
        },
      ],
    },
  };
};

export const PLAN_NUMBER_VERIFICATION_FORM_WITH_ADDRESS = (
  translateService: TranslateService
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: '',
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.1'
              ),
              PLAN_NUMBER_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.6'
              ),
            ],
            control: 'planNumber',
            placeholder: translateService.instant(
              'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.2'
            ),
            classes: 'form-control',
            dgxQmSanitise: 'encrypt',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Address_Page.3'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.3'
              ),
            ],
            control: 'addressLookup',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Address_Page.5'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.4'
              ),
            ],
            control: 'addressLine1',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Address_Page.6'
              ),
              classes: 'form-label',
            },
            control: 'addressLine2',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Address_Page.7'
              ),
              classes: 'form-label',
            },
            control: 'city',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Address_Page.8'
              ),
              classes: 'form-label',
            },
            control: 'postcode',
            placeholder: '',
            classes: 'form-control',
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.5'
              ),
            ],
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.5'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const CHECK_YOUR_DETAILS_PLAN_NUMBER_VERIFICATION_FORM = (
  translateService: TranslateService,
  isModal?: boolean,
  planNo?: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: '',
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.1'
              ),
              PLAN_NUMBER_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.6'
              ),
            ],
            control: 'planNumber',
            placeholder: 'Example: B1C1234567',
            classes: 'form-control',
            value: planNo ?? '',
            dgxFdnQmSanitise: 'encrypt',
          },
        },
      ],
    },
    formValues: {},
  };
};

export const PLAN_NUMBER_VERIFICATION_FORM = (
  translateService: TranslateService,
  isModal?: boolean,
  planNo?: string
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: '',
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.1'
              ),
              PLAN_NUMBER_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Plan_Policy_Verification_Page.Form_Validations.6'
              ),
            ],
            control: 'planNumber',
            placeholder: 'Example: B1C1234567',
            classes: 'form-control',
            value: planNo ?? '',
            dgxQmSanitise: 'encrypt',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: 'Continue',
              type: 'submit',
              disabled: false,
              showTick: true,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const REQUEST_VERIFICATION_CODE_DIALOG_FORM = (
  translateService: TranslateService
) => {
  return {
    meta: {
      header: '',
      groups: [
        {
          input: {
            label: {
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Email_Form.1'
              ),
              classes: 'form-label',
            },
            validators: [
              REQUIRED_FIELD_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Verify_Email_Form.2'
              ),
              EMAIL_VALIDATION(
                translateService,
                'IAM_Migration_And_Login_v0.6.Verify_Email_Form.3'
              ),
            ],
            control: 'email',
            placeholder: '',
            classes: 'form-control',
            dgxQmSanitise: 'block',
          },
        },
        {
          button: [
            {
              control: 'submitButton',
              text: translateService.instant(
                'IAM_Migration_And_Login_v0.6.Verify_Email_Form.4'
              ),
              type: 'submit',
              disabled: false,
              stretch: false,
              submitted: false,
              classes: 'btn btn--primary btn--block row--spacer-top-medium',
            },
          ],
        },
      ],
    },
    formValues: {},
  };
};

export const ADDRESS_VERIFICATION_FORM_OLD_LOGIN_AND_REG = (
  isManualAddress: boolean
) => {
  return {
    // firstName: ["", [Validators.required, Validators.maxLength(40), Validators.pattern(RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED)]],
    // lastName: ["", [Validators.required, Validators.maxLength(40), Validators.pattern(RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED)]],
    // password: ["", [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,128}$")]],
    address: ['', isManualAddress ? [] : [Validators.required]],
    addressLine1: [
      // this.contactDetails.formValues.addressLine1,
      '',
      [
        Validators.required,
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    addressLine2: [
      // this.contactDetails.formValues.addressLine2,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    // city: [this.contactDetails.formValues.city],
    city: [''],
    county: [
      // this.contactDetails.formValues.county,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    postcode: [
      // this.contactDetails.formValues.postcode,
      '',
      [
        Validators.required,
        Validators.pattern('[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}'),
      ],
    ],
  };
};

export const CHECK_MY_DETAILS_FORM = (isManualAddress: boolean) => {
  return {
    firstName: [
      '',
      [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(
          RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED
        ),
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(
          RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED
        ),
      ],
    ],
    planNumber: [
      '',
      [
        Validators.required,
        Validators.pattern(RegexFormValidationsEnum.PLAN_NUMBER_VALIDATION),
      ],
    ],
    address: ['', isManualAddress ? [] : [Validators.required]],
    addressLine1: [
      // this.contactDetails.formValues.addressLine1,
      '',
      [
        Validators.required,
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    addressLine2: [
      // this.contactDetails.formValues.addressLine2,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    // city: [this.contactDetails.formValues.city],
    city: [
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    county: [
      // this.contactDetails.formValues.county,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    postcode: [
      // this.contactDetails.formValues.postcode,
      '',
      [
        Validators.required,
        Validators.pattern('[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}'),
      ],
    ],
  };
};

export const ADDRESS_VERIFICATION_FORM_ORIGINAL = (
  isManualAddress: boolean
) => {
  return {
    firstName: [
      '',
      [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(
          RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED
        ),
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(
          RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED
        ),
      ],
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,128}$)[^\\s]+(\\s+[^\\s]+)*$'
        ),
      ],
    ],
    address: ['', isManualAddress ? [] : [Validators.required]],
    addressLine1: [
      // this.contactDetails.formValues.addressLine1,
      '',
      [
        Validators.required,
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    addressLine2: [
      // this.contactDetails.formValues.addressLine2,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    // city: [this.contactDetails.formValues.city],
    city: [
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    county: [
      // this.contactDetails.formValues.county,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    postcode: [
      // this.contactDetails.formValues.postcode,
      '',
      [
        Validators.required,
        Validators.pattern('[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}'),
      ],
    ],
  };
};

export const ADDRESS_VERIFICATION_FORM_ORIGINAL_MODAL = (
  isManualAddress: boolean
) => {
  return {
    firstName: [
      '',
      [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(
          RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED
        ),
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(
          RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED
        ),
      ],
    ],
    address: ['', isManualAddress ? [] : [Validators.required]],
    addressLine1: [
      // this.contactDetails.formValues.addressLine1,
      '',
      [
        Validators.required,
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    addressLine2: [
      // this.contactDetails.formValues.addressLine2,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    // city: [this.contactDetails.formValues.city],
    city: [''],
    county: [
      // this.contactDetails.formValues.county,
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    postcode: [
      // this.contactDetails.formValues.postcode,
      '',
      [
        Validators.required,
        Validators.pattern('[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}'),
      ],
    ],
  };
};

export const ADDRESS_UPDATE_VERIFICATION_FORM = (isManualAddress: boolean) => {
  return {
    address: ['', isManualAddress ? [] : [Validators.required]],
    addressLine1: [
      '',
      [
        Validators.required,
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    addressLine2: [
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    city: [''],
    county: [
      '',
      [
        Validators.pattern(
          // eslint-disable-next-line max-len
          "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$"
        ),
      ],
    ],
    postcode: [
      '',
      [
        Validators.required,
        Validators.pattern('[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}'),
      ],
    ],
  };
};
