<section class="login-screen">
  <ng-container [ngTemplateOutlet]="logoutHeader"></ng-container>
</section>

<section class="upsell-section">
  <div editable-area [content]="upsellMain"></div>
</section>

<section>
  <ng-container [ngTemplateOutlet]="faqs"></ng-container>
</section>

<ng-template #logoutHeader>
  <div editable-area [content]="banner"></div>
</ng-template>

<ng-template #faqs>
  <div editable-area [content]="faqMain"></div>
</ng-template>
