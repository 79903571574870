export class MockAddress {
  static address = {
    firstLine: '25 Trinity Street',
    secondLine: 'Cradley Heath',
    postCode: 'B64 6HS',
    zipCode: 80970,
  };

  static zipCode: number = this.address.zipCode;
  static postCode: string = this.address.postCode;
  static firstLine: string = this.address.firstLine;
  static secondLine: string = this.address.secondLine;
}
