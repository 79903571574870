import { Component, Input } from '@angular/core';
import { UpsellCardDetails } from '../../models/upsell-card';

@Component({
  selector: 'cms-plumbing-upsell',
  templateUrl: './plumbing-upsell-card.component.html',
  styleUrls: ['./plumbing-upsell-card.component.scss'],
})
export class CmsUpsellPlumbingComponent {
  @Input() cardDetails: UpsellCardDetails;
  navigate() {
    window.open(this.cardDetails.buttonUrl, '_blank', 'noopener');
  }
}
