import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Injectable()
export class CorrelationIdRequestTrackerInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const correlationId = sessionStorage.getItem('dg-x-correlation-id');
    if (correlationId) {
      const newHeaders = httpRequest.headers.append(
        'x-correlation-id',
        correlationId
      );
      const newReq = httpRequest.clone({ headers: newHeaders });

      return next.handle(newReq).pipe(
        filter((response) => response instanceof HttpResponse),
        map((response: any) => {
          const modifiedResponse = response.clone({
            headers: response.headers.set('x-correlation-id', correlationId),
          });
          return modifiedResponse;
        })
      );
    } else {
      return next.handle(httpRequest);
    }
  }
}
