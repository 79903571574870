import { UntypedFormControl } from '@angular/forms';
import { whitespacesOnlyInMiddleAuthLib } from './regex-validations';
export interface IDefaults {
  passwordLength: boolean;
  passwordUpperCase: boolean;
  passwordLowerCase: boolean;
  passwordNumber: boolean;
  passwordComplexCase: boolean;
}

export const passwordDefaults: IDefaults = {
  passwordLength: false,
  passwordUpperCase: false,
  passwordLowerCase: false,
  passwordNumber: false,
  passwordComplexCase: false,
};

export function validatePassword(passwordInput: string) {
  return {
    passwordLength: passwordInput && passwordInput.length >= 8,
    passwordLowerCase: passwordInput && !!passwordInput.match('[a-z]'),
    passwordUpperCase: passwordInput && !!passwordInput.match('[A-Z]'),
    passwordNumber: passwordInput && !!passwordInput.match('[0-9]'),
    passwordComplexCase:
      passwordInput && whitespacesOnlyInMiddleAuthLib.test(passwordInput),
  };
}

export function passwordLengthValidator(control: UntypedFormControl) {
  if (control.value.length >= 8) {
    return null;
  } else {
    return { invalidPassword: true };
  }
}

export function passwordComplexCaseValidator(control: UntypedFormControl) {
  const value: string = control.value;
  if (whitespacesOnlyInMiddleAuthLib.test(value)) {
    return null;
  } else {
    return { invalidPassword: true };
  }
}

export function passwordNumberValidator(control: UntypedFormControl) {
  if (control.value.match('[0-9]')) {
    return null;
  } else {
    return { invalidPassword: true };
  }
}

export function passwordUpperCaseValidator(control: UntypedFormControl) {
  if (control.value.match('[A-Z]')) {
    return null;
  } else {
    return { invalidPassword: true };
  }
}

export function passwordLowerCaseValidator(control: UntypedFormControl) {
  if (control.value.match('[a-z]')) {
    return null;
  } else {
    return { invalidPassword: true };
  }
}
