import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { VerfiyUserActivationLink } from '@athome-myaccount/my-account/data-access-shared';
import {
  AppConfig,
  CONFIG,
  RecaptchaHttpClient,
} from '@athome-myaccount/my-account/util-core';
import { NewLoginAndRegHttpEnum } from '@athome-myaccount/my-account/util-login';
import { RemoteData } from 'ngx-remotedata';
import { Observable } from 'rxjs';
import {
  IMatchPersonalDetailsPayload,
  ISignUpAndMatchOnAddressPayload,
} from '../models/api-payloads.model';

export interface ISignUpNewAccountResponse {
  status: string;
}

export interface ISignUpNewAccountSuccessResponse {
  status: string;
}

export interface ISignUpNewAccountFailureResponse {
  status: string;
}

export interface ISignUpAndMatchAddressResponse {
  status: string;
  message: string;
  additionalInfo: AdditionalInfo;
}

export interface AdditionalInfo {
  lastAttempt: boolean;
}

export interface IMatchPersonalDetailsResponse
  extends ISignUpAndMatchAddressResponse {
  status: string;
  message: string;
  additionalInfo: AdditionalInfo;
}

@Injectable({
  providedIn: 'root',
})
export class MyAccountIdentityHttpService {
  baseUrl = `${this.env.identityApiUrl}/${this.env.identityApiUrlVersion}`;

  constructor(
    private recaptchaClient: RecaptchaHttpClient,
    private http: HttpClient,
    @Inject(CONFIG) private readonly env: AppConfig
  ) {}

  public signUpNewAccount(
    email: string
  ): Observable<RemoteData<ISignUpNewAccountResponse, HttpErrorResponse>> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.SIGN_UP_NEW_ACCOUNT_URL,
      { email }
    );
  }

  public resetUserPassword(
    email: string
  ): Observable<RemoteData<any, HttpErrorResponse>> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.RESET_USER_PASSWORD_URL,
      { email }
    );
  }

  public signUpAndMatchOnAddress(
    payload: ISignUpAndMatchOnAddressPayload
  ): Observable<RemoteData<ISignUpAndMatchAddressResponse, HttpErrorResponse>> {
    return this.recaptchaClient.post(
      this.baseUrl +
        NewLoginAndRegHttpEnum.SIGN_UP_CONFIRM_AND_ADDRESS_MATCH_URL,
      payload,
      null,
      {
        withCredentials: true,
      }
    );
  }

  public matchPersonalDetails(
    payload: IMatchPersonalDetailsPayload
  ): Observable<RemoteData<IMatchPersonalDetailsResponse, HttpErrorResponse>> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.MATCH_CUSTOMER_DETAILS_URL,
      payload,
      null,
      {
        withCredentials: true,
      }
    );
  }

  requestActivationAutoAccount(payload: any): Observable<any> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.AUTO_ACCOUNT_REQUEST_ACTIVATION,
      { email: payload },
      null,
      {
        withCredentials: true,
      }
    );
  }

  confirmActivationAutoAccount(payload: any): Observable<any> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.AUTO_ACCOUNT_CONFIRM_ACTIVATION,
      payload,
      null,
      {
        withCredentials: true,
      }
    );
  }

  verifyActivationLink(payload: VerfiyUserActivationLink): Observable<any> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.VERIFY_ACTIVATION_LINK,
      payload,
      null,
      {
        withCredentials: true,
      }
    );
  }

  public refreshCookie(): Observable<RemoteData<any, any>> {
    return this.http.post<RemoteData<any, any>>(
      this.baseUrl + NewLoginAndRegHttpEnum.REFRESH_COOKIE,
      { refresh_token: 'refresh_token' },
      { headers: this.getStandardHeaders(), withCredentials: true }
    );
  }

  private getStandardHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
      'request-source': 'DandGUK',
      'request-action': 'MyAccount',
    });
  }

  public passwordResetConfirm(
    email: string,
    confirmationCode: string,
    password: string
  ): Observable<RemoteData<any, HttpErrorResponse>> {
    return this.recaptchaClient.post(
      this.baseUrl + NewLoginAndRegHttpEnum.PASSWORD_RESET_CONFIRM,
      { email, confirmationCode, password }
    );
  }
}
