import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNewLoginAndReg from '../reducers/new-login-and-reg.reducer';

export const selectNewLoginAndRegState =
  createFeatureSelector<fromNewLoginAndReg.NewLoginAndRegState>(
    'newLoginAndReg'
  );

export const selectMatchUserBasedOnAddressApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) =>
    newLoginAndRegState.matchUserBasedOnAddressApiResponse
);

export const selectMatchUserBasedOnPlanNumberApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) =>
    newLoginAndRegState.matchUserBasedOnPlanNumberApiResponse
);

export const selectResetUserPasswordApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) => newLoginAndRegState.resetUserPasswordApiResponse
);

export const selectSignUpNewAccountApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) => newLoginAndRegState.signUpNewAccountApiResponse
);

export const selectResendNewSignUpAccountEmailApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) =>
    newLoginAndRegState.resendNewSignUpAccountEmailApiResponse
);

export const selectConfirmPasswordResetApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) => newLoginAndRegState.confirmPasswordResetApiResponse
);

export const selectRequestActivationAutoAccountApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) =>
    newLoginAndRegState.requestActivationAutoAccountApiResponse
);

export const selectConfirmActivationAutoAccountApiResponse = createSelector(
  selectNewLoginAndRegState,
  (newLoginAndRegState) =>
    newLoginAndRegState.confirmActivationAutoAccountApiResponse
);

export const selectVerifyActivationLinkApiResponse = createSelector(
  selectNewLoginAndRegState,
  (state) => state.verifyActivationLinkApiResponse
);
