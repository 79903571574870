<div class="xs--hidden md--flex-row">
  <ng-container *ngTemplateOutlet="plumbingAndDrains"> </ng-container>
</div>

<ng-template #plumbingAndDrains>
  <div class="cross-sell-card" [ngClass]="{ 'fab-max-width': modalOpen }">
    <div class="appliance appliance--Protected cross-sell-container">
      <svg
        class="md--hidden xs--flex-row fab-modal-close"
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        (click)="toggleModal()"
      >
        <rect
          x="21.9204"
          width="31"
          height="31"
          rx="15.5"
          transform="rotate(45 21.9204 0)"
          fill="white"
        />
        <path
          d="M15.7862 26.5946L26.5947 15.7861L28.0546 17.246L17.2461 28.0545L15.7862 26.5946ZM15.7862 17.2375L17.2376 15.7861L28.0546 26.6031L26.6032 28.0545L15.7862 17.2375Z"
          fill="#6F4CA2"
        />
      </svg>
      <div class="cross-sell-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="55"
          viewBox="0 0 56 55"
          fill="none"
        >
          <g clip-path="url(#clip0_484_1413)">
            <path
              d="M18.7335 31.6298V23.4634C17.4309 23.4098 16.144 23.3389 14.8698 23.4918C8.42453 24.2611 3.53483 28.7653 2.30015 35.0729C2.12309 35.9778 2.09622 36.9206 2.09306 37.8476C2.07409 42.9019 2.08673 47.9578 2.08199 53.0121C2.08199 53.4141 2.06144 53.8209 1.99188 54.2166C1.90651 54.71 1.59666 54.9985 1.06548 55.0001C0.540619 55.0017 0.181756 54.7021 0.0742551 54.1251C0.02999 53.8902 0.045799 53.6411 0.045799 53.3999C0.0426372 48.0209 -0.0696063 42.6402 0.0663506 37.2643C0.273448 29.1783 6.06584 23.1828 13.0755 21.7907C14.2565 21.5558 15.4864 21.5621 16.6974 21.4896C17.3345 21.4518 17.9763 21.4833 18.7494 21.4833C18.7683 20.8101 18.7636 20.2584 18.8047 19.7097C18.8648 18.8931 19.1256 18.6046 19.9319 18.5904C21.5934 18.562 23.2581 18.5636 24.9196 18.5936C25.6436 18.6062 25.9108 18.912 25.9835 19.6451C26.0072 19.8847 25.9914 20.1307 25.9914 20.3719C25.9914 25.144 25.9914 29.9161 25.9914 34.6882C25.9914 34.89 26.0009 35.0934 25.9835 35.2952C25.9155 36.1323 25.6278 36.4461 24.7678 36.487C23.9189 36.528 23.0652 36.4981 22.2131 36.4981C21.5238 36.4981 20.833 36.5186 20.1453 36.4933C19.1351 36.4555 18.8442 36.1686 18.7984 35.1864C18.7762 34.7119 18.7952 34.2358 18.7952 33.6603C17.5985 33.6603 16.4997 33.5374 15.4421 33.6887C13.6826 33.941 12.4036 35.5191 12.2029 37.4582C12.1618 37.8586 12.176 38.2654 12.176 38.6705C12.176 43.4836 12.176 48.2952 12.176 53.1083C12.176 53.4725 12.1886 53.8382 12.1491 54.1992C12.0985 54.6533 11.8156 54.9292 11.3618 54.9891C10.8544 55.0569 10.4718 54.8377 10.32 54.3553C10.2268 54.0558 10.2252 53.7184 10.2252 53.3984C10.222 48.2226 10.2204 43.0454 10.2283 37.8696C10.2315 35.5001 11.2227 33.6651 13.2605 32.4149C14.2612 31.8001 15.3631 31.6046 16.5219 31.6282C17.2396 31.6424 17.9573 31.6314 18.7351 31.6314L18.7335 31.6298ZM23.9078 34.425C24.1465 32.675 24.0754 21.4155 23.8256 20.5815H20.9594C20.6796 21.663 20.6923 33.3781 20.98 34.425H23.9078Z"
              fill="white"
            />
            <path
              d="M37.3233 21.3855C38.5011 21.5179 39.584 21.592 40.6527 21.335C42.6509 20.8558 43.8398 19.366 43.8445 17.3165C43.8571 12.1818 43.8477 7.04861 43.8492 1.91389C43.8492 1.55129 43.8382 1.18081 43.9093 0.829249C44.0184 0.283773 44.4674 -0.0504486 44.9511 0.0204947C45.4491 0.0945911 45.7542 0.387824 45.7906 0.906498C45.8111 1.18869 45.8111 1.47247 45.8111 1.75467C45.8111 6.88939 45.7084 12.0257 45.8459 17.1557C45.9376 20.5799 43.0224 23.6462 39.4591 23.4334C38.7825 23.3924 38.1027 23.4271 37.3391 23.4271V31.5619C38.0331 31.5855 38.7414 31.6344 39.448 31.6265C43.1932 31.5871 46.5447 30.4599 49.3286 27.9091C52.375 25.1171 53.997 21.6456 54.0239 17.5025C54.0587 12.2874 54.0318 7.07226 54.0334 1.85714C54.0334 1.53395 54.0096 1.20604 54.0571 0.889156C54.1377 0.35314 54.5709 -0.0110357 55.0499 -5.6898e-08C55.5431 0.00945905 55.9051 0.364176 55.9715 0.939605C56.0031 1.21865 55.9889 1.50558 55.9889 1.78777C55.9889 7.0029 55.9447 12.218 55.9984 17.4331C56.0822 25.5033 49.7776 32.511 41.9 33.4049C40.4187 33.5735 38.9232 33.6129 37.2806 33.7217C37.2711 34.2089 37.2696 34.7197 37.249 35.2305C37.2111 36.1575 36.9376 36.4712 36.0048 36.4901C34.4239 36.5216 32.8431 36.5216 31.2622 36.4901C30.4654 36.4743 30.1729 36.1638 30.1476 35.3676C30.116 34.3981 30.1381 33.4269 30.1381 32.4574C30.1381 28.4546 30.1381 24.4534 30.1381 20.4506C30.1381 20.1274 30.1255 19.8011 30.1587 19.481C30.2188 18.9198 30.5381 18.5966 31.1183 18.595C32.8209 18.5872 34.5235 18.5682 36.2262 18.5998C36.9518 18.614 37.1889 18.9135 37.249 19.6765C37.2711 19.9587 37.2522 20.2425 37.2617 20.5247C37.2711 20.7643 37.2949 21.004 37.3233 21.3871V21.3855ZM32.2297 20.6004C32.002 22.0571 32.0542 33.5373 32.2755 34.4028H35.2318V20.6004H32.2297Z"
              fill="white"
            />
            <path
              d="M28.0609 53.6254C25.209 53.6443 22.836 51.4561 22.6874 48.6042C22.6574 48.0177 22.7918 47.3934 22.9815 46.8306C23.7277 44.6235 24.937 42.6765 26.4784 40.9328C26.6887 40.6948 26.9495 40.4898 27.2135 40.3085C27.9154 39.8277 28.3597 39.7709 28.962 40.3432C29.7461 41.0873 30.4797 41.9087 31.112 42.7852C32.0115 44.0323 32.7056 45.4054 33.1245 46.8984C34.0967 50.3683 31.659 53.6033 28.0625 53.627L28.0609 53.6254ZM28.0925 42.0884C26.6128 43.7958 25.4998 45.5032 24.8438 47.4785C24.7094 47.8837 24.6888 48.3582 24.7426 48.7855C24.9766 50.682 26.8151 51.9763 28.6964 51.6106C30.6298 51.2338 31.8282 49.2884 31.2353 47.395C30.6235 45.4448 29.4837 43.7926 28.0941 42.0884H28.0925Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_484_1413">
              <rect width="56" height="55" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="cross-sell-title">Plumbing and drainage cover</div>

      <div class="cross-sell-subtitle">
        You can call us day or night, to claim for problems big or small.
      </div>
      <div class="cross-sell-check-container">
        <athome-myaccount-check [label]="'Know we only use expert engineers'">
        </athome-myaccount-check>
        <athome-myaccount-check
          [label]="'Claim up to £2000 in repairs per year'"
        >
        </athome-myaccount-check>
        <athome-myaccount-check
          [label]="'Relax, with no surprise repair costs'"
        >
        </athome-myaccount-check>
      </div>
      <div class="cross-sell-shield-check">
        <app-button
          [text]="'Get cover from £4.99 a month'"
          classes="btn btn--secondary btn-crosssell"
          (click)="navigateToGetQuote()"
        ></app-button>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="xs--flex-row md--hidden fab-modal fab-holder"
  [ngClass]="{
    'fab-modal-open': modalOpen,
    'fab-aligh-right': !modalOpen,
    'fab-max-width': modalOpen
  }"
>
  <div *ngIf="modalOpen">
    <ng-container *ngTemplateOutlet="plumbingAndDrains"> </ng-container>
  </div>
  <div
    *ngIf="!modalOpen"
    class="col col-row--gutter col-12 col-md-4 row--spacer-bottom ng-star-inserted fab-row"
    [ngClass]="{ 'fab-aligh-right': !modalOpen, 'fab-max-width': modalOpen }"
  >
    <div class="fab-container">
      <div class="fab-button">
        <div class="fab-plus" (click)="toggleModal()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
          >
            <rect width="31" height="31" rx="15.5" fill="white" />
            <path
              d="M14.4678 23.1428V7.8573H16.5324V23.1428H14.4678ZM7.85132 16.5263V14.4738H23.1489V16.5263H7.85132Z"
              fill="#6F4CA2"
            />
          </svg>
          <p>Protect your plumbing and drains</p>
        </div>
      </div>
    </div>
  </div>
</div>
