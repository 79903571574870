import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';

@Directive({
  selector: '[dgxGATaggingClick]',
})
export class GATaggingClickDirective {
  constructor(private analyticsService: AnalyticsService) {}
  clicked = false;
  @Input() dgxGATaggingClick: { [key: string]: string };
  @Input() multiGAClick = false;

  @HostListener('click') onClick() {
    if (this.multiGAClick) {
      this.analyticsService.push(this.dgxGATaggingClick);
    } else {
      if (!this.clicked) {
        this.analyticsService.push(this.dgxGATaggingClick);
        this.clicked = !this.clicked;
      }
    }
  }
}
