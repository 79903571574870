import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RUNTIME_CONFIG as DE_RUNTIME_CONFIG } from '@athome-myaccount/my-account/util-core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Hammerjs must be imported for gestures
 */
import {
  CMS_CONFIG,
  isInMagnoliaEditor,
  isInMagnoliaPreview,
} from '@domgen/dgx-fe-cms';
import { BYPASS_ROUTE_GUARD, DomainId } from '@domgen/dgx-fe-common';
import {
  DOMAIN_CONFIG,
  RUNTIME_CONFIG,
  domainIdFromDomain,
} from '@domgen/dgx-fe-config';
import 'hammerjs';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { firstValueFrom } from 'rxjs';
import { myAccountCmsComponentMapping } from '../../../libs/my-account/feature-cms/src/lib/constants/myaccount-cms-component-mapping.constants';

/*
  This code fetches the runtime config that is changed per deployed environment by Jenkins
  Once the runtime config is fetched, we provide it as a token to merge with the config
  represented by environment.ts or environment.prod.ts
  I am using XMLHttpRequest for IE11
*/
let configXhr = null;
configXhr = new XMLHttpRequest();
configXhr.open('GET', 'config.json');
configXhr.send();
configXhr.onreadystatechange = function () {
  if (this.status === 200 && this.readyState === 4) {
    const config = JSON.parse(configXhr.responseText);
    // initializeCookiePro(config);
    if (environment.production) {
      enableProdMode();
    }

    Promise.all([
      loadDomainConfig(),
      determineInEditor(),
      determineInPreview(config),
    ]).then(([domainConfig, inEditor, inPreview]) => {
      if (environment.production) {
        enableProdMode();
      }

      platformBrowserDynamic([
        {
          provide: DOMAIN_CONFIG,
          useValue: domainConfig,
        },
        {
          provide: BYPASS_ROUTE_GUARD,
          useValue: inEditor || inPreview,
        },
        {
          provide: CMS_CONFIG,
          useValue: {
            ...config.cms,
            cmsRootPath: domainConfig.cmsRootPath,
            componentMapping: myAccountCmsComponentMapping,
            inEditor,
            inPreview,
          },
        },
        {
          provide: RUNTIME_CONFIG,
          useValue: config,
        },
        {
          provide: DE_RUNTIME_CONFIG,
          useValue: config,
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: config.recaptchaSiteKey },
      ]).bootstrapModule(AppModule);
    });
  }
};

export function initializeCookiePro(config: any) {
  if (!config?.cookieProSrc && !config?.cookieProDomainScript) {
    return config;
  }
  const scriptElement = document.createElement('script');
  const head = document.getElementsByTagName('head')[0];
  scriptElement.src = config.cookieProSrc;
  scriptElement.setAttribute(
    'data-domain-script',
    config.cookieProDomainScript
  );
  // Write CookiePro script to top of head
  head.insertBefore(scriptElement, head.childNodes[0]);
  return config;
}

async function loadDomainConfig(): Promise<any> {
  const domainId: DomainId = domainIdFromDomain(
    DomainId.DomesticAndGeneral,
    {}
  );

  const response = await fetch(`configs/domains/${domainId}.json`);
  return response.json();
}

async function determineInEditor(): Promise<boolean> {
  const response = await firstValueFrom(isInMagnoliaEditor());
  return response;
}

async function determineInPreview(runtimeConfig: any): Promise<boolean> {
  if (runtimeConfig.cms.usePreviewApi) {
    return Promise.resolve(true);
  }
  const response = await firstValueFrom(isInMagnoliaPreview());
  return response;
}
