export interface RuntimeConfig {
  api: string;
  commonPersonServiceUrl: string;
  claimsAppHost: string;
  homepageAppHost?: string;
  salesAppHost: string;
  salesAppHostPD: string;
  faqsUrl: string;
  debugMode: string;
  contactUsUrl: string;
  cookiesPolicyUrl?: string;
  domesticAndGeneralUrl: string;
  reportIssueUrl?: string;
  cookieProSrc: string;
  cookieUrlPattern: string;
  cookieProDomainScript: string;
  recaptchaSiteKey: string;
  imeiApi?: string;
  mobileModelApi?: string;
  identityApiUrlVersion?: string;
  identityApiUrl?: string;
  notAllowedUrlsForUnauthorizedRedirect: Array<string>;
  lightstepAccessToken: string;
  lightstepServiceName: string;
  repairAndCareUrl?: string;
  plumbingAndDrainageUrl?: string;
}
