import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UnHandledErrorActionTypes } from '@athome-myaccount/my-account/util-core';
import { ErrorRoutesEnum } from '@athome-myaccount/my-account/util-login';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ErrorActionTypes } from './error.actions';

@Injectable()
export class ErrorEffects {
  genericError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ErrorActionTypes.GenericError,
          UnHandledErrorActionTypes.UnHandledError
        ),
        tap(() => {
          this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  plansNotFound$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActionTypes.NavigateToDashBoardError),
        tap(() => {
          this.router.navigate([`/${ErrorRoutesEnum.DASHBOARD_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  serverError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActionTypes.ServerError),
        tap(() => {
          this.router.navigate([`/${ErrorRoutesEnum.SERVER_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  pageNotFound$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActionTypes.PageNotFound),
        tap(() => {
          this.router.navigate([`/${ErrorRoutesEnum.NOTFOUND_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  unauthorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActionTypes.UnAuthorized),
        tap(() => {
          this.router.navigate([
            `/${ErrorRoutesEnum.NOT_AUTHORIZED_ERROR_ROUTE}`,
          ]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router
  ) {}
}
