import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as userActions from '@athome-myaccount/my-account/data-access-login';
import {
  LogIn,
  LogOut,
  LoginService,
  USER_AUTO_LOGGED_IN_EVENT,
  selectApplicationUser,
} from '@athome-myaccount/my-account/data-access-login';
import {
  AnalyticsService,
  ScriptLoaderService,
} from '@athome-myaccount/my-account/data-access-shared';
import {
  ModalDialogAlertComponent,
  ModalDialogConfirmComponent,
  ModalDialogUnsupportedBrowsersComponent,
} from '@athome-myaccount/my-account/feature-shared';
import {
  AppConfig,
  CONFIG,
  CloseModal,
  ModalActionTypes,
  ModalDialogService,
  OpenAlertModal,
  OpenConfirmModal,
  OpenUnsupportedModal,
} from '@athome-myaccount/my-account/util-core';
import {
  AlertType,
  ModalAlertData,
  ModalConfirmData,
  User,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { Store, select } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ao-dma-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public oWebViewInterface = (window as any).nsWebViewInterface;
  expiredTime = 10;
  public isComponentActive = true;
  public loading = true;
  public user: User;

  public footerLinks = {
    protectLinks: undefined,
    legalLinks: undefined,
    usefulLinks: undefined,
  };

  constructor(
    @Inject(CONFIG) readonly config: AppConfig,
    private store: Store<any>,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService,
    private readonly router: Router,
    private scriptLoaderService: ScriptLoaderService,
    private modalDialogService: ModalDialogService,
    public dialog: MatDialog,
    private loginService: LoginService
  ) {
    this.loadFooterLinks();
  }

  // #####################
  // async applied due to SonarCloud now erroring.
  // https://sonarcloud.io/project/issues?resolved=false&types=BUG&sinceLeakPeriod=true&severities=BLOCKER%2CCRITICAL%2CMAJOR%2CMINOR&id=domgen_dgx-myaccount-spa
  // PR (https://github.com/domgen/dgx-myaccount-spa/pull/895/files)
  // ########################
  async ngOnInit(): Promise<void> {
    await this.loadScriptsForBrowser();
    this.handleAppModalSubscription();

    // COMMENTED OUT FOR NOW WILL BE ENABLED NEXT RELEASE
    // this.unsupportedBrowserService.checkIfBrowserSupported();
    // this.handleServiceWorkerUpdate();

    this.store
      .pipe(select(selectApplicationUser))
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((user) => {
        this.user = user;
      });

    this.user = this.loginService.decodeUserFromJWTCookie();
  }

  loadFooterLinks(): void {
    this.footerLinks.protectLinks = this.config.footerLinksBuilder(
      this.config.homepageAppHost
    ).protectLinks;

    this.footerLinks.legalLinks = this.config.footerLinksBuilder(
      this.config.homepageAppHost
    ).legalLinks;

    this.footerLinks.usefulLinks = this.config.footerLinksBuilder(
      this.config.homepageAppHost
    ).usefulLinks;
  }

  isUserStatusComplete(): boolean {
    if (this.user) {
      if (this.user['custom:user_state'] === UserStateTypeEnum.COMPLETE) {
        return true;
      } else if (this.loginService.getUserVerificationCompleteStatus()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  handleAppModalSubscription(): void {
    this.modalDialogService
      .getModal()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(
        (
          modalData:
            | OpenAlertModal
            | OpenConfirmModal
            | CloseModal
            | OpenUnsupportedModal
        ) => {
          switch (modalData?.type) {
            case ModalActionTypes.OpenAlertModal:
              this.openAlertModal(
                modalData?.modalData,
                modalData?.modalWidth,
                modalData?.modalHeight,
                modalData?.disableClose
              );
              break;

            case ModalActionTypes.OpenConfirmModal:
              this.openConfirmModal(
                modalData?.callBackFunction,
                modalData?.modalConfirmData,
                modalData?.modalWidth,
                modalData?.modalHeight,
                modalData?.disableClose
              );
              break;

            case ModalActionTypes.OpenUnsupportedModal:
              this.openUnsupportedBrowserModal();
              break;

            case ModalActionTypes.CloseModals:
              this.dialog.closeAll();
              break;

            default:
              this.dialog.closeAll();
              break;
          }
        }
      );
  }

  async loadScriptsForBrowser(): Promise<void> {
    this.scriptLoaderService.isComingFromMobile.next(false);
    const webAppScripts = [
      'optimizely',
      'trustpilot',
      'googletagmanager',
      'chatwidget',
      'cookiepro',
    ];
    await this.scriptLoaderService
      .loadScripts('body', webAppScripts)
      .then()
      .catch();

    this.handleUserCookieSession();
    this.toggleChatWidget();
  }

  toggleChatWidget(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: RouterEvent) => {
        const chatWidget = document.getElementById('chat-client-container');

        if (
          (event.url.endsWith('my-account') ||
            event.url.endsWith('create-account')) &&
          chatWidget
        ) {
          chatWidget.style.display = 'block';
        } else {
          chatWidget.style.display = 'none';
        }
      });
  }

  handleUserCookieSession(): void {
    const cookie_AWS_IDT: any = this.cookieService.get('AWS_IDT');
    if (cookie_AWS_IDT) {
      try {
        const decodedUser: User = jwt_decode(cookie_AWS_IDT);
        if (decodedUser) {
          this.handleLogin(decodedUser);
        }
      } catch {
        this.loading = false;
        this.store.dispatch(new LogOut());
      }
    }
    this.loading = false;
  }

  handleLogin(decodedUser: User): void {
    if (
      decodedUser &&
      decodedUser['custom:user_state'] === UserStateTypeEnum.COMPLETE
    ) {
      USER_AUTO_LOGGED_IN_EVENT(this.analyticsService);
      this.store.dispatch(new LogIn({ decodedUser }));
    }
  }

  openAlertModal(
    modalAlertData: ModalAlertData,
    modalWidth: string = '300px',
    modalHeight: string = '300px',
    disableClose: boolean = true
  ): void {
    this.dialog.open(ModalDialogAlertComponent, {
      width: modalWidth,
      height: modalHeight,
      data: modalAlertData,
      panelClass: this.getModalPanelClass(modalAlertData.alertType),
      disableClose: disableClose,
    });
  }

  openConfirmModal(
    // eslint-disable-next-line @typescript-eslint/ban-types
    callBackFunction: Function,
    modalConfirmData?: ModalConfirmData,
    modalWidth: string = '800px',
    modalHeight: string = '300px',
    disableClose: boolean = true
  ): void {
    const dialogRef = this.dialog.open(ModalDialogConfirmComponent, {
      width: modalWidth,
      height: modalHeight,
      data: modalConfirmData,
      disableClose: disableClose,
    });

    dialogRef.afterClosed().subscribe((result) => callBackFunction(result));
  }

  getModalPanelClass(alertType: AlertType): string {
    switch (alertType) {
      case AlertType.ERROR:
        return 'custom-alert-dialog-container-error';
      case AlertType.SUCCESS:
        return 'custom-alert-dialog-container-success';
      default:
        break;
    }
  }

  openUnsupportedBrowserModal(): void {
    this.dialog.open(ModalDialogUnsupportedBrowsersComponent, {
      disableClose: true,
      panelClass: 'unsupport-browser-width',
    });
  }

  logout(): void {
    this.store.dispatch(new userActions.LogOut());
  }

  ngOnDestroy(): void {
    this.isComponentActive = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.oWebViewInterface.off('tokenEvent');
  }
}
