export class MockUtils {
  static #setDate(years: number, past = true): string {
    let setYears: number;
    // basic date
    const currentDate = new Date();
    const randomDay = Math.floor(this.randomNumber(28));
    const randomMonth = Math.floor(this.randomNumber(12));

    if (past) {
      setYears = years
        ? currentDate.getFullYear() - years
        : currentDate.getFullYear() - Math.floor(this.randomNumber(49));
    } else {
      setYears = currentDate.getFullYear() + years;
    }

    let setDate = new Date(currentDate.setDate(randomDay));
    setDate = new Date(setDate.setMonth(randomMonth));
    setDate = new Date(setDate.setFullYear(setYears));

    return setDate.toISOString();
  }

  static datePast(years = 0): string {
    return this.#setDate(years);
  }
  static dateFuture(years = 0): string {
    return this.#setDate(years, false);
  }

  static randomNumber(value = 10): number {
    const randomNumber = this.generateRandomNumber();
    const randomNumberRange = randomNumber * value + 1;

    return Math.floor(randomNumberRange);
  }

  static randomNumberRange(min, max): number {
    const randomNumber = this.generateRandomNumber();
    const randomNumberRange = randomNumber * (max - min + 1) + min;

    return Math.floor(randomNumberRange);
  }

  static alphaNumeric(max = 8): string {
    let alphaNumericString = '';
    const newArray = Array(max).fill(0);

    newArray.forEach((_) => {
      const floatingNumber = this.generateRandomNumber();
      alphaNumericString += floatingNumber.toString(36).substring(3, 4);
    });
    return alphaNumericString.replace(/[a-z]/, 'Z');
  }

  static randomArray<T>(array: Array<T>): T {
    const randonNumber = this.randomNumberRange(0, array.length - 1);
    return array[randonNumber];
  }

  static randomObjectElement(object: Record<string, string>): string {
    const objectValues = Object.values(object);
    const randonNumber = this.randomNumberRange(0, objectValues.length - 1);
    return objectValues[randonNumber];
  }

  static generateRandomNumber = () => {
    const uint32Array = new Uint32Array(1);
    window.crypto.getRandomValues(uint32Array);
    return uint32Array[0] / (0xffffffff + 1);
  };
}
