import { ResetPasswordPayload } from '@athome-myaccount/my-account/util-shared';
import { MockUser } from '../../mock-utils/mock-user';
import { MockUtils } from '../../mock-utils/mock-utils';
import { requestPasswordPayload } from './request-password-payload.mock';

export const resetPasswordPayload: ResetPasswordPayload = {
  ...requestPasswordPayload,
  confirmation_code: MockUtils.randomArray(['USD', 'EUR', 'AUD', 'GBP']),
  password: MockUser.password,
};
