<ng-container>
  <div *ngIf="cardDetails" class="repair-container">
    <section>
      <ui-section-title
        *ngIf="cardDetails.title"
        [text]="cardDetails.title"
      ></ui-section-title>
    </section>
    <section *ngIf="cardDetails.cardImage?.cmsSrc">
      <img
        *ngIf="cardDetails.cardImage | cmsImage as contentImage"
        class="repair-image"
        [src]="contentImage.src"
        [alt]="contentImage.alt"
      />
    </section>
    <section>
      <button (click)="navigate()" class="repair-button">
        {{ cardDetails.buttonText }}
      </button>
    </section>
  </div>
</ng-container>
