import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  LoginTrackingService,
  MyAccountIdentityHttpService,
} from '@athome-myaccount/my-account/data-access-new-login-and-reg';
import {
  AccountService,
  AnalyticsService,
} from '@athome-myaccount/my-account/data-access-shared';
import {
  ModalDialogService,
  OpenAlertModal,
  OpenUserIdExistsModel,
} from '@athome-myaccount/my-account/util-core';
import {
  ErrorRoutesEnum,
  ErrorStatus,
  GetMarketingPreferenceResponse,
  GetPersonalDetailsResponse,
} from '@athome-myaccount/my-account/util-login';
import {
  AlertType,
  ModalAlertData,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mapTo,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';
import { LoginService } from '../../../../services/login.service';
import { PersonalDetailsHttpService } from './../../services/personal-details-http.service';
import * as PersonalDetailsActions from './../actions/personal-details.actions';
import { PersonalDetailsActionTypes } from './../actions/personal-details.actions';
import { PersonalDetailsState } from './../reducers/personal.details.reducer';

@Injectable()
export class PersonalDetailsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<PersonalDetailsState>,
    private personalDetailsHttpService: PersonalDetailsHttpService,
    private accountService: AccountService,
    private router: Router,
    private modalDialogService: ModalDialogService,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private analyticsService: AnalyticsService,
    private loginTrackingService: LoginTrackingService,
    private loginService: LoginService,
    private identityService: MyAccountIdentityHttpService
  ) {}

  getPersonalDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.GetPersonDetails>(
        PersonalDetailsActionTypes.GetPersonDetails
      ),
      exhaustMap(() => {
        const personDetails =
          this.personalDetailsHttpService.getPersonDetails();

        return personDetails.pipe(
          map((response: GetPersonalDetailsResponse) => {
            return new PersonalDetailsActions.GetPersonDetailsSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              new PersonalDetailsActions.GetPersonDetailsFailure(error)
            );
          })
        );
      })
    )
  );

  getPersonalDetailsFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.GetPersonDetailsFailure>(
          PersonalDetailsActionTypes.GetPersonDetailsFailure
        ),
        tap(() => {
          this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  getMarketingPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.GetMarketingPreference>(
        PersonalDetailsActionTypes.GetMarketingPreference
      ),
      exhaustMap(() => {
        const personMarketingPreference =
          this.personalDetailsHttpService.getPersonMarketingPreference();

        return personMarketingPreference.pipe(
          map((response: GetMarketingPreferenceResponse) => {
            return new PersonalDetailsActions.GetMarketingPreferenceSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              new PersonalDetailsActions.GetMarketingPreferenceFailure(error)
            );
          })
        );
      })
    )
  );

  updateContactDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateContactDetails>(
        PersonalDetailsActionTypes.UpdateContactDetails
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateContactDetails) =>
        this.personalDetailsHttpService
          .updateContactDetails(action.payload)
          .pipe(
            map(
              (response: unknown) =>
                new PersonalDetailsActions.UpdateContactDetailsSuccess({
                  response,
                })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateContactDetailsFailure(error)
              );
            })
          )
      )
    )
  );

  updateUserIdRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateUserIdDetails>(
        PersonalDetailsActionTypes.UpdateUserIdDetails
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateUserIdDetails) => {
        const token = this.recaptchaV3Service
          .execute('getToken')
          .pipe(take(1))
          .pipe(filter((token) => !!token));
        return token.pipe(
          mergeMap((t) => {
            return this.accountService.updateUserId(action.payload, t).pipe(
              map(
                (response: unknown) =>
                  new PersonalDetailsActions.UpdateUserIdSuccess({
                    response,
                  })
              ),
              catchError((error) => {
                return of(
                  new PersonalDetailsActions.UpdateUserIdFailure(error)
                );
              })
            );
          })
        );
      })
    )
  );

  validateUserIdLinkRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.ValidateUserIdLinkRequest>(
        PersonalDetailsActionTypes.ValidateUserIdLinkRequest
      ),
      exhaustMap((action: PersonalDetailsActions.ValidateUserIdLinkRequest) => {
        const token = this.recaptchaV3Service
          .execute('getToken')
          .pipe(take(1))
          .pipe(filter((token) => !!token));
        this.store.dispatch(
          new PersonalDetailsActions.SetUserIdRequestEmail(action.payload.email)
        );
        return token.pipe(
          mergeMap((t) => {
            return this.accountService
              .validateUserIdLink(action.payload, t)
              .pipe(
                map(
                  (response: unknown) =>
                    new PersonalDetailsActions.ValidateUserIdLinkRequestSuccess(
                      {
                        response,
                      }
                    )
                ),
                catchError((error) => {
                  return of(
                    new PersonalDetailsActions.ValidateUserIdLinkRequestFailure(
                      error
                    )
                  );
                })
              );
          })
        );
      })
    )
  );

  validateUserIdFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.ValidateUserIdLinkRequestFailure>(
          PersonalDetailsActionTypes.ValidateUserIdLinkRequestFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const status = error?.payload?.error?.status;
          if (status) {
            if (status == 'CODE_EXPIRED') {
              this.router.navigate(['my-account/my-details/user-id-expired']);
            } else if (status == UserStateTypeEnum.ALREADY_ACTIVATED) {
              //send GA event
              this.analyticsService.push({
                event: 'genericGAEvent',
                eventCategory: 'myDetails',
                eventAction: 'change-email-flow-link-already-used',
                eventLabel: 'modal-shown',
              });

              const modalDataIncorrect: ModalAlertData = {
                title: "Your email's verified",
                content: {
                  content:
                    'It looks like you’ve verified your login email already. Simply log in with your email and password now.',
                },
                alertType: AlertType.ERROR,
                closeButtonLabel: 'Go to login',
              };
              this.modalDialogService.openModal(
                new OpenAlertModal(
                  modalDataIncorrect,
                  '600px',
                  '200px',
                  true,
                  () => {
                    this.analyticsService.push({
                      event: 'genericGAEvent',
                      eventCategory: 'myDetails',
                      eventAction: 'change-email-flow-link-already-used',
                      eventLabel: 'go-to-login',
                    });
                    this.router.navigate(['my-account']);
                  }
                )
              );
            } else {
              this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
            }
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );

  confirmUserIdChangeRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.ConfirmUserIdChangeRequest>(
        PersonalDetailsActionTypes.ConfirmUserIdChangeRequest
      ),
      exhaustMap(
        (action: PersonalDetailsActions.ConfirmUserIdChangeRequest) => {
          const token = this.recaptchaV3Service
            .execute('getToken')
            .pipe(take(1))
            .pipe(filter((token) => !!token));
          this.store.dispatch(
            new PersonalDetailsActions.SetUserIdRequestEmail(
              action.payload.email
            )
          );
          return token.pipe(
            mergeMap((t) => {
              return this.accountService
                .confirmUserIdChange(action.payload, t)
                .pipe(
                  map(
                    (response: unknown) =>
                      new PersonalDetailsActions.ConfirmUserIdChangeRequestSuccess(
                        {
                          response,
                        }
                      )
                  ),
                  catchError((error) => {
                    return of(
                      new PersonalDetailsActions.ConfirmUserIdChangeRequestFailure(
                        error
                      )
                    );
                  })
                );
            })
          );
        }
      )
    )
  );

  confirmUserIdChangeRequestSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.ConfirmUserIdChangeRequestSuccess>(
          PersonalDetailsActionTypes.ConfirmUserIdChangeRequestSuccess
        ),
        tap(() => {
          this.loginService.handleCompleteStatus('my-account/my-details');
          const modalDataIncorrect: ModalAlertData = {
            title: 'Success',
            content: {
              content: 'Your login email address has been changed.',
            },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };

          this.analyticsService.push({
            event: 'genericGAEvent',
            eventCategory: 'myDetails',
            eventAction: 'change email - flow',
            eventLabel: 'User id successfully changed',
          });

          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataIncorrect, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  confirmUserIdChangeRequestFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.ConfirmUserIdChangeRequestFailure>(
          PersonalDetailsActionTypes.ConfirmUserIdChangeRequestFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const status = error?.payload?.error?.status;
          if (status) {
            if (status == 'CODE_EXPIRED') {
              this.router.navigate(['my-account/user-id-expired']);
            } else if (status == 'NOT_FOUND') {
              this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
            } else if (status == 'INVALID_PASSWORD') {
              const modalDataIncorrect: ModalAlertData = {
                title: 'Incorrect password',
                content: {
                  content:
                    "That password isn't recognised. Please try again, making sure caps lock is off",
                },
                alertType: AlertType.ERROR,
                closeButtonLabel: 'Close',
              };
              this.modalDialogService.openModal(
                new OpenAlertModal(modalDataIncorrect, '600px', '200px', true)
              );
            } else {
              this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
            }
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );

  expiredUserIdLinkRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.ExpiredUserIdVerificationLinkRequest>(
        PersonalDetailsActionTypes.ExpiredUserIdVerificationLinkRequest
      ),
      exhaustMap(
        (
          action: PersonalDetailsActions.ExpiredUserIdVerificationLinkRequest
        ) => {
          const token = this.recaptchaV3Service
            .execute('getToken')
            .pipe(take(1))
            .pipe(filter((token) => !!token));

          this.store.dispatch(
            new PersonalDetailsActions.SetUserIdRequestEmail(
              action.payload.email
            )
          );
          return token.pipe(
            mergeMap((t) => {
              return this.accountService
                .requestVerificationLink(action.payload, t)
                .pipe(
                  map(
                    (response: unknown) =>
                      new PersonalDetailsActions.ExpiredUserIdVerificationLinkRequestSuccess(
                        {
                          response,
                        }
                      )
                  ),
                  catchError((error) => {
                    return of(
                      new PersonalDetailsActions.ExpiredUserIdVerificationLinkRequestFailure(
                        error
                      )
                    );
                  })
                );
            })
          );
        }
      )
    )
  );

  updateContactDetailsSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateContactDetailsSuccess>(
          PersonalDetailsActionTypes.UpdateContactDetailsSuccess
        ),
        tap(() => {
          const modalDataSuccess: ModalAlertData = {
            title: 'Success',
            content: { content: 'Personal details updated' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataSuccess, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  ExpiredUserIdVerificationLinkRequestFailure$: Observable<Action> =
    createEffect(
      () =>
        this.actions$.pipe(
          ofType<PersonalDetailsActions.ExpiredUserIdVerificationLinkRequestFailure>(
            PersonalDetailsActionTypes.ExpiredUserIdVerificationLinkRequestFailure
          ),
          tap((response) => {
            const status = response?.payload?.error?.status;
            if (status == ErrorStatus.ALREADY_CONFIRMED) {
              this.router.navigate([`/${ErrorRoutesEnum.DASHBOARD_ROUTE}`]);
            } else {
              this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
            }
          })
        ),
      { dispatch: false }
    );

  updateUserIdSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateUserIdSuccess>(
          PersonalDetailsActionTypes.UpdateUserIdSuccess
        ),
        tap(() => {
          this.router.navigate(['my-account/my-details/user-id-verification']);
        })
      ),
    { dispatch: false }
  );

  updateUserIdFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateUserIdFailure>(
          PersonalDetailsActionTypes.UpdateUserIdFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const status = error?.payload?.error?.status;
          if (status) {
            if (status == 'INVALID_PASSWORD') {
              const modalError: ModalAlertData = {
                title: 'Incorrect password',
                content: {
                  content: "That password isn't recognised. Please try again.",
                },
                alertType: AlertType.ERROR,
                closeButtonLabel: 'Close',
                isPrimaryCTA: true,
              };
              this.modalDialogService.openModal(
                new OpenAlertModal(modalError, '600px', '200px', true)
              );
              this.analyticsService.push({
                event: 'genericGAEvent',
                genericpagename: 'myDetails',
                pagename: 'myDetails',
                eventCategory: 'myDetails',
                eventAction: 'change email - flow - password',
                eventLabel: 'continue - existing password success',
                clientName: 'DG My Account',
              });
            } else if (
              status == 'INVALID_USERNAME' ||
              status == 'USERNAME_EXISTS'
            ) {
              this.analyticsService.push({
                event: 'genericGAEvent',
                genericpagename: 'myDetails',
                pagename: 'myDetails',
                eventCategory: 'myDetails',
                eventAction: 'change edit username',
                eventLabel: 'pop up - Please check your details',
                clientName: 'DG My Account',
              });
              this.modalDialogService.openModal(new OpenUserIdExistsModel());
            }
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );

  updateContactDetailsFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateContactDetailsFailure>(
          PersonalDetailsActionTypes.UpdateContactDetailsFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { status, message } = error?.payload?.error;
          if (error?.payload?.status === 400 && status && message) {
            const modalError: ModalAlertData = {
              title: 'Something went wrong',
              content: message,
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalError, '600px', '200px', true)
            );
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );

  updateCorrespondenceAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateCorrespondenceAddress>(
        PersonalDetailsActionTypes.UpdateCorrespondenceAddress
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateCorrespondenceAddress) =>
        this.personalDetailsHttpService
          .updateCorrespondenceAddress(action.payload)
          .pipe(
            map(
              (response: unknown) =>
                new PersonalDetailsActions.UpdateCorrespondenceAddressSuccess({
                  response,
                })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateCorrespondenceAddressFailure(
                  error
                )
              );
            })
          )
      )
    )
  );

  updateCorrespondenceAddressSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateCorrespondenceAddressSuccess>(
          PersonalDetailsActionTypes.UpdateCorrespondenceAddressSuccess
        ),
        tap(() => {
          const modalDataSuccess: ModalAlertData = {
            title: 'Success',
            content: { content: 'Personal address updated successfully' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };

          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataSuccess, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateCorrespondenceAddressFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateContactDetailsFailure>(
          PersonalDetailsActionTypes.UpdateCorrespondenceAddressFailure
        ),
        tap(() => {
          this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  updatePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdatePassword>(
        PersonalDetailsActionTypes.UpdatePassword
      ),
      exhaustMap((action: PersonalDetailsActions.UpdatePassword) =>
        this.accountService.updatePassword(action.payload.password).pipe(
          mapTo(new PersonalDetailsActions.UpdatePasswordSuccess()),
          catchError((error) =>
            of(new PersonalDetailsActions.UpdatePasswordFailure(error))
          )
        )
      )
    )
  );

  updateMarketingPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateMarketingPreference>(
        PersonalDetailsActionTypes.UpdateMarketingPreference
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateMarketingPreference) =>
        this.personalDetailsHttpService
          .updateMarketingPreference(action.payload.preference)
          .pipe(
            mapTo(
              new PersonalDetailsActions.UpdateMarketingPreferenceSuccess({
                response: action.payload.preference,
              })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateMarketingPreferenceFailure(
                  error
                )
              );
            })
          )
      )
    )
  );

  updatePasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdatePasswordFailure>(
          PersonalDetailsActionTypes.UpdatePasswordFailure
        ),
        tap(() => {
          const modalData: ModalAlertData = {
            title: 'Something went wrong',
            content: { content: 'Your request could not be processed.' },
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updatePasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdatePasswordSuccess>(
          PersonalDetailsActionTypes.UpdatePasswordSuccess
        ),
        tap(() => {
          const modalData: ModalAlertData = {
            title: 'Success',
            content: {
              content: 'Your password has been successfully changed.',
            },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateMarketingPreferenceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateMarketingPreferenceFailure>(
          PersonalDetailsActionTypes.UpdateMarketingPreferenceFailure
        ),
        tap(() => {
          const modalData: ModalAlertData = {
            title: 'Something went wrong',
            content: { content: 'Your request could not be processed.' },
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateMarketingPreferenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateMarketingPreferenceSuccess>(
          PersonalDetailsActionTypes.UpdateMarketingPreferenceSuccess
        ),
        tap(() => {
          const modalData: ModalAlertData = {
            title: 'Success',
            content: {
              content:
                'Your marketing preference has been successfully changed.',
            },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );

          this.store.dispatch(new PersonalDetailsActions.GetPersonDetails());
          this.store.dispatch(
            new PersonalDetailsActions.GetMarketingPreference()
          );
        })
      ),
    { dispatch: false }
  );

  updateDocumentPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateDocPreference>(
        PersonalDetailsActionTypes.UpdateDocPreference
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateDocPreference) =>
        this.personalDetailsHttpService
          .updateDocPreference(action.payload)
          .pipe(
            map(
              (response: unknown) =>
                new PersonalDetailsActions.UpdateDocPreferenceSuccess({
                  response,
                })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateDocPreferenceFailure(error)
              );
            })
          )
      )
    )
  );

  updateDocumentPreferenceSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateDocPreferenceSuccess>(
          PersonalDetailsActionTypes.UpdateDocPreferenceSuccess
        ),
        tap(() => {
          const modalDataSuccess: ModalAlertData = {
            title: 'Success',
            content: { content: 'Document Preference updated' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataSuccess, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateDocumentPreferenceFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateDocPreferenceFailure>(
          PersonalDetailsActionTypes.UpdateDocPreferenceFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { status, message } = error?.payload?.error;
          if (error?.payload?.status === 400 && status && message) {
            const modalError: ModalAlertData = {
              title: 'Something went wrong',
              content: message,
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalError, '600px', '200px', true)
            );
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );
}
