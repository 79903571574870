import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dgxScrollToElement]',
})
export class ScrollToElementDirective {
  @Input() dgxScrollToElement: HTMLElement;

  @HostListener('click') onClick() {
    const isValid = this.dgxScrollToElement instanceof HTMLElement;
    if (!isValid) {
      throw new Error(
        'Not a valid HTMLElement, trying using a template variable eg. #InfoSection'
      );
    } else {
      this.dgxScrollToElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
