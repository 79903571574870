import { EqualValidatorDirective } from './equal-validator.directive';
import { InputTextDirective } from './input-text.directive';
import { LoadedGADirective } from './loaded-ga.directive';

export const directives: any[] = [
  EqualValidatorDirective,
  InputTextDirective,
  LoadedGADirective,
];

export * from './equal-validator.directive';
export * from './input-text.directive';
export * from './show-password.directive';
