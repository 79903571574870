import { Injectable } from '@angular/core';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';

@Injectable({ providedIn: 'root' })
export class LoginTrackingService {
  constructor(private analyticsService: AnalyticsService) {}

  ctaSentEmailLinkPasswordPageButton(action: string): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: action,
      eventLabel: 'password-reset-page',
    });
  }

  userClicksBackToLoginOnConfirmEmailAddressEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'Back to login',
      clientName: 'DG My Account',
    });
  }

  userGetHelpWithAccount(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'match attempt limit reached',
      eventLabel: 'modal shown',
    });
  }
  // Mapped
  loadLoginPageEvent(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'user-login',
      genericpagename: 'user-login',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  loadCreateAccountPageEvent(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'user-create-account-entry-email',
      genericpagename: 'user-registration',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  loadConfirmEmailPageEvent(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'user-email-confirmed-thank-you-page',
      genericpagename: 'user-registration',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }
  loadExpiredConfirmPageEvent(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'user-expired-link',
      genericpagename: 'user-registration',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  loadForgotPasswordPage(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'forgot-password-page',
      genericpagename: 'forgot-password-page',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  loadSentEmailLinkPasswordPage() {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'password-reset',
      genericpagename: 'password-reset',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  userClicksShowAllAnswearsOnForgotPasswordPageEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'forgot password',
      eventLabel: 'Show all answers',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  loadPagePlanNumberVerificationEvent(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'user-plan-number',
      genericpagename: 'user-registration',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  loadPersonalDetailsAddressMatchPageEvent(): void {
    this.analyticsService.push({
      event: 'spa-pageview',
      pagename: 'user-personal-details-form',
      genericpagename: 'user-registration',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  // Mapped Not Fully
  //'check your details'|'Check your details again'|'Let's try one more'|'Get help with your account'
  modalOpenedCheckyourDetailsEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'errors pop-up shown', // string
      eventLabel: "Let's try one more",
      pagename: 'user-plan-number',
      genericpagename: 'user-registration',
      category: 'my-account-dgx',
      clientName: 'DG My Account',
    });
  }

  //Mapped
  successfullyCreatedAccountEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'Account successfully created',
    });
  }

  //Mapped
  userClicksEditButtonInModal(section: string, cancel: string): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: `errors plan number pop-up edit ${cancel}`,
      eventLabel: `edit - ${section}`,
    });
  }

  // Mapped
  userClicksSeeTipsOnWhatToCheck(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'errors pop-up tips',
      eventLabel: ' extend tips to check',
    });
  }

  // Mapped
  userClicksOnResendNewEmailConfirmationLink(cta: string): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: cta,
    });
  }

  //Mapped
  userPlanNumberVerificationSuccessEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'plan number - success',
      clientName: 'DG My Account',
    });
  }
  checkDetailsSuccess(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'check your details - success',
      clientName: 'DG My Account',
    });
  }
  checkDetailsNoneSuccess(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'check your details - unsucess',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userPlanNumberVerificationFailedEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'plan number unsucess',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userSawErrorModalWhenVerificationFailedEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'plan number - error - popup',
      eventLabel: 'wrong plan number',
    });
  }

  // Mapped
  userSawErrorModalWhenMaxAttemptsreachedToVerifyAccountFailedEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'plan number - error - popup',
      eventLabel: 'wrong plan number - call us',
    });
  }

  // Mapped
  userSuccessfullyVerifedAddressOnAccountCreationEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'personal details - success',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userFailedToVerifyAddressOnAccountCreationEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'personal details unsucess',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userClicksShowAllAnswearsOnAddressVerificationPageEvent(page: string): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: `${page} - Show all answers`,
      clientName: 'DG My Account',
    });
  }

  userClicksTooltipOnAccountRegistrationEvent(tooltipType: string): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'personal details - tool tip',
      eventLabel: tooltipType,
      clientName: 'DG My Account',
    });
  }

  userExpandsAccordionOnAccountRegistrationEvent(accordionType: string): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Personal Details - accordions',
      eventLabel: accordionType,
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userDecidedToEnterAddressManuallyEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'personal details - address',
      eventLabel: 'enter the address manualy',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userClicksContinueOnThanksforConfirmingPageEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'Continue to personal details',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userClicksResendEmailOnConfirmEmailPageEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'Resend email',
      clientName: 'DG My Account',
    });
  }
  userClicksResendEmailOnConfirmEmailNewPageEvent(
    action: string,
    label: string
  ): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: action,
      eventLabel: label,
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userClicksBackToLoginOnConfirmEmailPageEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'Back to login',
      clientName: 'DG My Account',
    });
  }
  userExpiredLinkBackToLogin(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'expired link',
      eventLabel: 'Back to login',
      clientName: 'DG My Account',
    });
  }
  userClicksBackToLoginOnExpiredLinkEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'expired link',
      eventLabel: 'back to login',
    });
  }

  // Mapped
  userClicksContinueOnCreateAccountPageEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'email submitted',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userClicksBackToLoginAccountOnCreateAccountPageEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'Log in to My Account',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userClicksCreateAccountButtonEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: 'button-click',
      clientName: 'DG My Account',
    });
  }

  // Mapped
  userSuccessfullyLoggedInEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-login-journey-success',
      eventAction: 'user-authentication-response-success',
      eventLabel: 'user-login-page',
    });
  }

  // Mapped
  userFailedToLogInEvent(): void {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-login-journey-errors',
      eventAction: 'Your details are not recognized',
      eventLabel: 'user-login-page',
      clientName: 'DG My Account',
    });
  }
}
