import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsImage } from '@domgen/dgx-fe-cms';
import { UpsellCardDetails } from '../../models/upsell-card';

@Component({
  selector: 'cms-repair-upsell',
  templateUrl: './repair-upsell-card.component.html',
  styleUrls: ['./repair-upsell-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsUpsellRepairComponent {
  @Input() cardDetails: UpsellCardDetails;
  @Input() image: CmsImage;

  navigate() {
    window.open(this.cardDetails.buttonUrl, '_blank');
  }
}
