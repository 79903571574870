import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgxCmsModule } from '@domgen/dgx-fe-cms';
import { CmsUpsellPlumbingComponent } from './components/plumbing/plumbing-upsell-card.component';
import { CmsUpsellRepairComponent } from './components/repair/repair-upsell-card.component';
import { UpsellContainerComponent } from './components/upsell-container.component';
import { LogoutComponent } from './pages/logout/logout.component';

@NgModule({
  declarations: [
    LogoutComponent,
    CmsUpsellRepairComponent,
    CmsUpsellPlumbingComponent,
    UpsellContainerComponent,
  ],
  exports: [
    LogoutComponent,
    CmsUpsellRepairComponent,
    CmsUpsellPlumbingComponent,
    UpsellContainerComponent,
  ],
  imports: [CommonModule, DgxCmsModule],
  providers: [],
})
export class MyAccountFeatureCmsModule {}
