import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import {
  LoginRoutesEnum,
  NewLoginAndRegRoutesEnum,
} from '@athome-myaccount/my-account/util-login';

@Component({
  selector: 'athome-myaccount-username-already-exists',
  templateUrl: './modal-dialog-username-already-exists.component.html',
  styleUrls: ['./modal-dialog-username-already-exists.component.scss'],
})
export class ModalDialogUsernameAlreadyExistsComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<ModalDialogUsernameAlreadyExistsComponent>,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.setGATagging('email has an account - popup shown');
  }

  handleBackToLogin(): void {
    this.setGATagging('email has an account - popup shown - Back to login');
    this.router.navigateByUrl(LoginRoutesEnum.LOGIN_PAGE);
    this.dialogRef.close();
  }

  handleResetPassword(): void {
    this.setGATagging('email has an account - popup shown - Reset password');
    this.router.navigateByUrl(
      NewLoginAndRegRoutesEnum.REQUEST_PASSWORD_RESET_PAGE
    );
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }

  setGATagging(label: string) {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventCategory: 'user-registration-journey',
      eventAction: 'Create an account',
      eventLabel: label,
      clientName: 'DG My Account',
    });
  }
}
