import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import { LoginApiEnum } from '@athome-myaccount/my-account/util-login';
import { catchError, map, Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class clearAuthCookiesResolver implements Resolve<any> {
  constructor(
    private http: HttpClient,
    @Inject(CONFIG) private readonly env: AppConfig
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
    }),
  };
  baseUrl = `${this.env.api}/${this.env.apiVersion}`;

  resolve(): Observable<any> {
    return this.clearAuthCookies();
  }

  private clearAuthCookies() {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };
    return this.http
      .post(this.baseUrl + LoginApiEnum.SIGNOUT, {}, mergedHttpOptions)
      .pipe(
        map((response: any) => response.result),
        catchError((error) => of(error))
      );
  }
}
