import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-elements',
  templateUrl: './form-elements.component.html',
  styleUrls: ['./form-elements.component.scss'],
})
export class FormElementsComponent {
  @Input() group: UntypedFormGroup;
  @Input() data;
  @Input() validate;
  @Input() results;

  isFormValid() {
    return this.group.status === 'VALID';
  }
}
