import { generateRandomPersonalDetails } from '@athome-myaccount/my-account/util-account';
import {
  IMyDetailsEditSectionsState,
  MyDetailsEditSectionsState,
  PersonDetails,
} from '@athome-myaccount/my-account/util-login';
import {
  IUserSignUpAndMatchPayload,
  RequestPasswordErrorResponse,
  User,
  UserMatchPayload,
  UserMatchSuccessResponse,
} from '@athome-myaccount/my-account/util-shared';
import {
  Failure,
  InProgress,
  NotAsked,
  RemoteData,
  Success,
} from 'ngx-remotedata';
import { UserActions, UserActionTypes } from './user.actions';

/* NgRx */
// State for this feature (User)
export interface UserState {
  loading: boolean;
  loginFormCredentials: any;
  createNewAccountFormCredentials: any;
  userMatch: UserMatchPayload;
  createNewAccountResponse: RemoteData<any, Error | any>;
  userAuthenticationResponse: RemoteData<any, Error | any>;
  verifyAddressResponse: RemoteData<UserMatchSuccessResponse, Error>;
  verifyPlanNumberResponse: RemoteData<UserMatchSuccessResponse, Error>;
  verifyUserEmailInboxResponse: RemoteData<any, Error>;
  requestVerificationTokenResponse: RemoteData<any, Error>;
  resetPasswordResponse: RemoteData<any, Error>;
  requestVerificationCodeResponse: RemoteData<any, Error | any>;
  requestForgotPasswordResponse: RemoteData<
    any,
    RequestPasswordErrorResponse | Error
  >;
  isUserVerificationComplete: boolean;
  error: any;
  loggedIn: boolean;
  user: User;
  myDetailsEditSectionsState: IMyDetailsEditSectionsState;
  personalDetails: PersonDetails;
  signUpAndMatchPayload: IUserSignUpAndMatchPayload;
  signUpAttempts: number;
  correlationId: string;
}

const initialUserState: UserState = {
  loading: false,
  loginFormCredentials: null,
  createNewAccountFormCredentials: null,
  userMatch: null,
  createNewAccountResponse: NotAsked.of(),
  userAuthenticationResponse: NotAsked.of(),
  verifyAddressResponse: NotAsked.of(),
  verifyPlanNumberResponse: NotAsked.of(),
  requestVerificationCodeResponse: NotAsked.of(),
  verifyUserEmailInboxResponse: NotAsked.of(),
  requestForgotPasswordResponse: NotAsked.of(),
  resetPasswordResponse: NotAsked.of(),
  requestVerificationTokenResponse: NotAsked.of(),
  isUserVerificationComplete: false,
  myDetailsEditSectionsState: new MyDetailsEditSectionsState(),
  personalDetails: generateRandomPersonalDetails,
  error: null,
  loggedIn: false,
  user: null,
  signUpAndMatchPayload: null,
  signUpAttempts: 0,
  correlationId: null,
};

export function reducer(
  state = initialUserState,
  action: UserActions
): UserState {
  switch (action.type) {
    case UserActionTypes.UserAuthentication:
      return {
        ...state,
        loginFormCredentials: action.payload,
        userAuthenticationResponse: InProgress.of(),
        loading: true,
      };
    case UserActionTypes.UserAuthenticationSuccess:
      return {
        ...state,
        userAuthenticationResponse: Success.of(action.payload),
        loading: false,
      };
    case UserActionTypes.CreateAccount:
      return {
        ...state,
        createNewAccountFormCredentials: action.payload.createAccountPayload,
        createNewAccountResponse: InProgress.of(),
        loading: true,
      };
    case UserActionTypes.CreateAccountSuccess:
      return {
        ...state,
        createNewAccountResponse: Success.of(action.payload),
        loading: false,
      };
    case UserActionTypes.VerifyAddress:
      return {
        ...state,
        userMatch: action.payload.userMatch,
        verifyAddressResponse: InProgress.of(),
      };

    case UserActionTypes.RequestVerificationCode:
      return {
        ...state,
        requestVerificationCodeResponse: InProgress.of(),
        loading: true,
      };

    case UserActionTypes.RequestVerificationCodeSuccess:
      return {
        ...state,
        requestVerificationCodeResponse: Success.of(action.payload),
        loading: false,
      };

    case UserActionTypes.RequestVerificationCodeFailure:
      return {
        ...state,
        requestVerificationCodeResponse: Failure.of(action.payload.error),
        loading: false,
      };

    case UserActionTypes.RequestVerificationToken:
      return {
        ...state,
        requestVerificationTokenResponse: InProgress.of(),
        loading: true,
      };

    case UserActionTypes.RequestVerificationTokenSuccess:
      return {
        ...state,
        requestVerificationTokenResponse: Success.of(action.payload),
        loading: false,
      };

    case UserActionTypes.RequestVerificationTokenFailure:
      return {
        ...state,
        requestVerificationTokenResponse: Failure.of(action.payload.error),
        loading: false,
      };

    case UserActionTypes.VerifyUserEmailInbox:
      return {
        ...state,
        verifyUserEmailInboxResponse: InProgress.of(),
        loading: true,
      };

    case UserActionTypes.VerifyUserEmailInboxSuccess:
      return {
        ...state,
        verifyUserEmailInboxResponse: Success.of(action.payload),
        loading: false,
      };

    case UserActionTypes.VerifyUserEmailInboxFailure:
      return {
        ...state,
        verifyUserEmailInboxResponse: Failure.of(action.payload.error),
        error: action.payload.error,
        loading: false,
      };

    case UserActionTypes.VerifyConfirmationCode:
      return { ...state, loading: true };

    case UserActionTypes.VerifyConfirmationCodeSuccess:
    case UserActionTypes.VerifyConfirmationCodeFailure:
      return { ...state, loading: false };

    case UserActionTypes.RequestForgottenPassword:
      return {
        ...state,
        requestForgotPasswordResponse: InProgress.of(),
        loading: true,
      };

    case UserActionTypes.RequestForgottenPasswordSuccess:
      return {
        ...state,
        requestForgotPasswordResponse: Success.of(action.payload),
        loading: false,
      };

    case UserActionTypes.VerifyAddressSuccess:
      return {
        ...state,
        verifyAddressResponse: Success.of(
          action.payload.userMatchSuccessResponse
        ),
        isUserVerificationComplete: true,
      };

    case UserActionTypes.VerifyPlanNumber:
      return {
        ...state,
        verifyPlanNumberResponse: InProgress.of(),
        loading: true,
      };

    case UserActionTypes.VerifyPlanNumberSuccess:
      return {
        ...state,
        verifyPlanNumberResponse: Success.of(
          action.payload.userMatchSuccessResponse
        ),
        isUserVerificationComplete: true,
        loading: false,
      };

    case UserActionTypes.VerifyPlanNumberFailure:
      return {
        ...state,
        verifyPlanNumberResponse: Failure.of(action.payload.error),
        error: action.payload.error,
        loading: false,
      };

    case UserActionTypes.VerifyAddressFailure:
      return {
        ...state,
        verifyAddressResponse: Failure.of(action.payload.error),
        error: action.payload.error,
        loading: false,
      };

    case UserActionTypes.RequestForgottenPasswordFailure:
      return {
        ...state,
        requestForgotPasswordResponse: Failure.of(action.payload.error),
        error: action.payload.error,
        loading: false,
      };

    case UserActionTypes.LogOut:
      return {
        ...state,
        loggedIn: false,
      };

    case UserActionTypes.LogIn:
      return {
        ...state,
        loggedIn: true,
        user: action.payload.decodedUser,
      };

    case UserActionTypes.UserAuthenticationFailure:
      return {
        ...state,
        error: action.payload.error,
        userAuthenticationResponse: Failure.of(action.payload.error),
        loading: false,
      };

    case UserActionTypes.CreateAccountFailure:
      return {
        ...state,
        error: action.payload.error,
        createNewAccountResponse: Failure.of(action.payload.error),
        loading: false,
      };

    case UserActionTypes.ResetPassword:
      return {
        ...state,
        resetPasswordResponse: InProgress.of(),
      };

    case UserActionTypes.ResetPasswordSuccess:
      return {
        ...state,
        resetPasswordResponse: Success.of(action.payload),
      };

    case UserActionTypes.ResetPasswordFailure:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        resetPasswordResponse: Failure.of(action.payload.error),
      };

    case UserActionTypes.ResetUserState:
      return { ...initialUserState };

    case UserActionTypes.ResetFormCredentials:
      return {
        ...state,
        loginFormCredentials: null,
        createNewAccountFormCredentials: null,
      };

    case UserActionTypes.UpdateMyDetailsActiveEditSection:
      return {
        ...state,
        myDetailsEditSectionsState: action.payload.editSection,
      };

    case UserActionTypes.UpdatePersonalDetails:
      return {
        ...state,
        personalDetails: action.payload.personalDetails,
      };

    case UserActionTypes.SetSignUpAndMatchPayload:
      return {
        ...state,
        signUpAndMatchPayload: action.payload,
        signUpAttempts: state.signUpAttempts + 1,
      };

    case UserActionTypes.SetCorrelationId:
      return {
        ...state,
        correlationId: action.payload.correlationId,
      };

    case UserActionTypes.ResetUserResponses:
      return {
        ...state,
        userAuthenticationResponse: NotAsked.of(),
        verifyAddressResponse: NotAsked.of(),
        verifyPlanNumberResponse: NotAsked.of(),
        requestVerificationCodeResponse: NotAsked.of(),
        verifyUserEmailInboxResponse: NotAsked.of(),
        requestForgotPasswordResponse: NotAsked.of(),
        createNewAccountResponse: NotAsked.of(),
        resetPasswordResponse: NotAsked.of(),
      };

    default:
      return state;
  }
}
