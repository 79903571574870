export const mockEnvironment = {
  production: false,
  claimRedirectQueryParams: 'athome_aws_bundle=[BUNDLE]',
  accountLoginUrl: '/my-account/my-plans',
  externalMaintenanceUrl: 'https://domesticandgeneral.tmtx.co.uk/en.html',
  apiVersion: 'v1',
  loginRegVersion: 'v1',
  notAllowedUrlsForUnauthorizedRedirect: [
    'identity/signin',
    'email/resend',
    'plans/issuereports',
    '/cancel',
    'changeuserid/confirm',
    'user-id-expired',
  ],
  debugCookieName: 'ATHOME_DEBUG',
  cleanLastUsedEmail: true,
  vokeraBrands: ['vokera', 'pro-combi', 'sabre'],
};
