import {
  ConfirmUserIdChangePayload,
  GetMarketingPreferenceResponse,
  GetPersonalDetailsResponse,
  MarketingPreferenceUpdate,
  RequestVerificationLinkPayload,
  UpdateContactDetailsPayload,
  UpdateCorrespondenceAddressPayload,
  UpdateDocPreferencePayload,
  UpdateUserIdDetailsPayload,
  ValidateUserIdLink,
} from '@athome-myaccount/my-account/util-login';
import { Action } from '@ngrx/store';

export enum PersonalDetailsActionTypes {
  GetPersonDetails = '[Personal Details] Get Person Details',
  GetPersonDetailsSuccess = '[Personal Details] Get Person Details Success',
  GetPersonDetailsFailure = '[Personal Details] Get Person Details Failure',

  UpdateContactDetails = '[Personal Details] Update Contact Details',
  UpdateContactDetailsSuccess = '[Personal Details] Update Contact Details Success',
  UpdateContactDetailsFailure = '[Personal Details] Update Contact Details Failure',

  UpdateUserIdDetails = '[Personal Details] Update User Id',
  UpdateUserIdRequest = '[Personal Details] Update User Id Request',
  UpdateUserIdSuccess = '[Personal Details] Update User Id Success',
  UpdateUserIdFailure = '[Personal Details] Update user Id Failure',

  ExpiredUserIdVerificationLinkRequest = '[Personal Details] Expired User Id Verification Request',
  ExpiredUserIdVerificationLinkRequestSuccess = '[Personal Details] Expired User Id Verification Request Success',
  ExpiredUserIdVerificationLinkRequestFailure = '[Personal Details] Expired User Id Verification Request Failure',

  ValidateUserIdLinkRequest = '[Personal Details] Validate User Id Link Request',
  ValidateUserIdLinkRequestSuccess = '[Personal Details] Validate User Id Link Request Success',
  ValidateUserIdLinkRequestFailure = '[Personal Details] Validate User Id Link Request Failure',

  ConfirmUserIdChangeRequest = '[Personal Details] Confirm User Id Change Request',
  ConfirmUserIdChangeRequestSuccess = '[Personal Details] Confirm User Id Change Request Success',
  ConfirmUserIdChangeRequestFailure = '[Personal Details] Confirm User Id Change Request Failure',

  SetUserIdRequestEmail = '[Personal Details] Set UserId Request Email',
  ResetUserIdRequestEmail = '[Personal Details] Reset UserId Request Email',
  UpdateCorrespondenceAddress = '[Personal Details] Update Correspondence Address',
  UpdateCorrespondenceAddressSuccess = '[Personal Details] Update Correspondence Address Success',
  UpdateCorrespondenceAddressFailure = '[Personal Details] Update Correspondence Address Failure',

  UpdatePassword = '[Personal Details] Update user password',
  UpdatePasswordSuccess = '[Personal Details] Update user password success',
  UpdatePasswordFailure = '[Personal Details] Update user password failure',

  GetMarketingPreference = '[Personal Marketing Preference] Get Person Marketing Preference',
  GetMarketingPreferenceSuccess = '[Personal Marketing Preference] Get Person Marketing Preference Success',
  GetMarketingPreferenceFailure = '[Personal Marketing Preference] Get Person Marketing Preference Failure',

  UpdateMarketingPreference = '[Personal Details] Update user MarketingPreference',
  UpdateMarketingPreferenceSuccess = '[Personal Details] Update user MarketingPreference success',
  UpdateMarketingPreferenceFailure = '[Personal Details] Update user MarketingPreference failure',

  ResetPersonalDetailsState = '[Personal Details] ResetPersonalDetailsState',

  UpdateDocPreference = '[Personal Details] Update Document Preference',
  UpdateDocPreferenceSuccess = '[Personal Details] Update Document Preference Success',
  UpdateDocPreferenceFailure = '[Personal Details] Update Document Preference Failure',
}

export class GetPersonDetails implements Action {
  readonly type = PersonalDetailsActionTypes.GetPersonDetails;
}

export class GetPersonDetailsSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.GetPersonDetailsSuccess;
  constructor(public payload: { response: GetPersonalDetailsResponse }) {}
}

export class GetPersonDetailsFailure implements Action {
  readonly type = PersonalDetailsActionTypes.GetPersonDetailsFailure;
  constructor(public payload: { error: any }) {}
}

export class ValidateUserIdLinkRequest implements Action {
  readonly type = PersonalDetailsActionTypes.ValidateUserIdLinkRequest;
  constructor(public payload: ValidateUserIdLink) {}
}

export class ValidateUserIdLinkRequestSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.ValidateUserIdLinkRequestSuccess;
  constructor(public payload: { response: any }) {}
}

export class ValidateUserIdLinkRequestFailure implements Action {
  readonly type = PersonalDetailsActionTypes.ValidateUserIdLinkRequestFailure;
  constructor(public payload: { error: any }) {}
}

export class ConfirmUserIdChangeRequest implements Action {
  readonly type = PersonalDetailsActionTypes.ConfirmUserIdChangeRequest;
  constructor(public payload: ConfirmUserIdChangePayload) {}
}

export class ConfirmUserIdChangeRequestSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.ConfirmUserIdChangeRequestSuccess;
  constructor(public payload: { response: any }) {}
}

export class ConfirmUserIdChangeRequestFailure implements Action {
  readonly type = PersonalDetailsActionTypes.ConfirmUserIdChangeRequestFailure;
  constructor(public payload: { error: any }) {}
}

export class ExpiredUserIdVerificationLinkRequest implements Action {
  readonly type =
    PersonalDetailsActionTypes.ExpiredUserIdVerificationLinkRequest;
  constructor(public payload: RequestVerificationLinkPayload) {}
}

export class ExpiredUserIdVerificationLinkRequestSuccess implements Action {
  readonly type =
    PersonalDetailsActionTypes.ExpiredUserIdVerificationLinkRequestSuccess;
  constructor(public payload: { response: any }) {}
}

export class ExpiredUserIdVerificationLinkRequestFailure implements Action {
  readonly type =
    PersonalDetailsActionTypes.ExpiredUserIdVerificationLinkRequestFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateUserIdDetails implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateUserIdDetails;
  constructor(public payload: UpdateUserIdDetailsPayload) {}
}

export class UpdateUserIdRequest implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateUserIdRequest;
  constructor(public payload: UpdateUserIdDetailsPayload) {}
}

export class UpdateUserIdSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateUserIdSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateUserIdFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateUserIdFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateContactDetails implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateContactDetails;
  constructor(public payload: UpdateContactDetailsPayload) {}
}

export class UpdateContactDetailsSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateContactDetailsSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateContactDetailsFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateContactDetailsFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateCorrespondenceAddress implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateCorrespondenceAddress;
  constructor(public payload: UpdateCorrespondenceAddressPayload) {}
}

export class UpdateCorrespondenceAddressSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateCorrespondenceAddressSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateCorrespondenceAddressFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateCorrespondenceAddressFailure;
  constructor(public payload: { error: any }) {}
}

export class ResetPersonalDetailsState implements Action {
  readonly type = PersonalDetailsActionTypes.ResetPersonalDetailsState;
}

export class UpdatePassword implements Action {
  readonly type = PersonalDetailsActionTypes.UpdatePassword;
  constructor(public payload: { password: string }) {}
}

export class UpdatePasswordSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdatePasswordSuccess;
}

export class UpdatePasswordFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdatePasswordFailure;
  constructor(public payload: Error) {}
}

export class GetMarketingPreference implements Action {
  readonly type = PersonalDetailsActionTypes.GetMarketingPreference;
}
export class GetMarketingPreferenceSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.GetMarketingPreferenceSuccess;
  constructor(public payload: { response: GetMarketingPreferenceResponse }) {}
}

export class GetMarketingPreferenceFailure implements Action {
  readonly type = PersonalDetailsActionTypes.GetMarketingPreferenceFailure;
  constructor(public payload: Error) {}
}

export class UpdateMarketingPreference implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateMarketingPreference;
  constructor(public payload: { preference: MarketingPreferenceUpdate }) {}
}

export class UpdateMarketingPreferenceSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateMarketingPreferenceSuccess;
  constructor(public payload: { response: MarketingPreferenceUpdate }) {}
}

export class UpdateMarketingPreferenceFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateMarketingPreferenceFailure;
  constructor(public payload: Error) {}
}

export class UpdateDocPreference implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateDocPreference;
  constructor(public payload: UpdateDocPreferencePayload) {}
}

export class UpdateDocPreferenceSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateDocPreferenceSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateDocPreferenceFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateDocPreferenceFailure;
  constructor(public payload: { error: any }) {}
}

export class SetUserIdRequestEmail implements Action {
  readonly type = PersonalDetailsActionTypes.SetUserIdRequestEmail;
  constructor(public email: string) {}
}

export class ResetUserIdRequestEmail implements Action {
  readonly type = PersonalDetailsActionTypes.ResetUserIdRequestEmail;
}

export type PersonalDetailsActions =
  | GetPersonDetails
  | GetPersonDetailsSuccess
  | GetPersonDetailsFailure
  | UpdateContactDetailsSuccess
  | UpdateContactDetailsFailure
  | UpdateCorrespondenceAddressSuccess
  | UpdateCorrespondenceAddressFailure
  | UpdateContactDetails
  | UpdateCorrespondenceAddress
  | ResetPersonalDetailsState
  | UpdatePassword
  | UpdatePasswordFailure
  | UpdatePasswordSuccess
  | GetMarketingPreference
  | GetMarketingPreferenceSuccess
  | GetMarketingPreferenceFailure
  | UpdateMarketingPreference
  | UpdateMarketingPreferenceSuccess
  | UpdateMarketingPreferenceFailure
  | UpdateDocPreferenceSuccess
  | UpdateDocPreferenceFailure
  | UpdateDocPreference
  | UpdateUserIdDetails
  | UpdateUserIdSuccess
  | UpdateUserIdFailure
  | ExpiredUserIdVerificationLinkRequest
  | ExpiredUserIdVerificationLinkRequestSuccess
  | ExpiredUserIdVerificationLinkRequestFailure
  | ValidateUserIdLinkRequest
  | ValidateUserIdLinkRequestSuccess
  | ValidateUserIdLinkRequestFailure
  | ConfirmUserIdChangeRequest
  | ConfirmUserIdChangeRequestSuccess
  | ConfirmUserIdChangeRequestFailure
  | SetUserIdRequestEmail
  | ResetUserIdRequestEmail;
