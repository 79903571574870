import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeWhile } from 'rxjs/operators';
import { AuthLibFacade } from '../../+state/auth.facade';
import { ApiResponseTypeEnum } from '../../common/enums/api-response-type.enum';
import {
  AuthModuleConfigForms,
  AUTH_FORMS,
} from '../../common/tokens/auth-config-token';

@Component({
  selector: 'athome-myaccount-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  isComponentAlive = true;
  loginForm: UntypedFormGroup;
  loginResponse$: Observable<ApiResponseTypeEnum> = this.facade.logInResponse$;
  submitted = false;
  fieldTextType: boolean;

  @Output() forgotPasswordLinkClicked = new EventEmitter();
  @Output() loginResponseSuccess = new EventEmitter();
  @Output() loginResponseError = new EventEmitter();
  @Output() formValueChange = new EventEmitter<any>();

  constructor(
    @Inject(AUTH_FORMS) public forms: AuthModuleConfigForms,
    private facade: AuthLibFacade,
    private formBuilder: UntypedFormBuilder
  ) {}

  get f(): any {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.initForm();
    this.onFormValueChange();
    this.onLoginResponse();
  }

  initForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.loginForm.valid
      ? this.facade.logInUser({
          email: this.loginForm.get('email').value,
          password: this.loginForm.get('password').value,
        })
      : this.loginForm.markAllAsTouched();
  }

  onFormValueChange(): void {
    this.loginForm.valueChanges
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .subscribe((data) => {
        this.formValueChange.emit(data);
      });
  }

  onLoginResponse(): void {
    this.loginResponse$
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          (responseStatus: ApiResponseTypeEnum) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe((responseStatus: ApiResponseTypeEnum) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.loginResponseSuccess.emit();
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR) {
          this.loginResponseError.emit();
        }
      });
  }

  onForgotYourPasswordClick(): void {
    this.forgotPasswordLinkClicked.emit();
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  ngOnDestroy(): void {
    this.facade.resetApiResponseState();
    this.isComponentAlive = false;
  }
}
