<ng-container>
  <div *ngIf="cardDetails" class="plumbing-container">
    <section>
      <h5>{{ cardDetails.title }}</h5>
    </section>
    <section>
      <img
        class="plumbing-image"
        *ngIf="cardDetails.cardImage | cmsImage as contentImage"
        [src]="contentImage.src"
        [alt]="contentImage.alt"
      />
    </section>
    <section>
      <button (click)="navigate()" class="plumbing-button">
        {{ cardDetails.buttonText }}
      </button>
    </section>
  </div>
</ng-container>
