import { Injectable } from '@angular/core';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { LoginDataLayer } from '@athome-myaccount/my-account/util-shared';
import { NotifyToastService } from '../services/notify-toast.service';

@Injectable({ providedIn: 'root' })
export class LoginComponentHelper {
  constructor(
    private _toastService: NotifyToastService,
    private _analytics: AnalyticsService
  ) {}

  /**
   * handleUserStateSuccess
   * Handles Routing AND / OR Toast Service error messages
   * This function is SOMETIMES used with this._router.navigateByUrl('')
   * E.G.:  this._router.navigateByUrl(
              this._loginHelper.handleUserStateSuccess(response.user_state)
            );
   * SCENARIOS:
   *   1: Confirmation email has been sent. Used on forgot-password page & login
   *   2 & 3: Login flow - when a user has dropped off and re-enters the jounry flow via login page (/my-account)
   *   4: Migrated account flow - first time user tries to login (/my-account)
   *   5: User is validated - straight to dashboard
   *   6: TBC
   *   7: Catch all
   */
  public handleUserStateSuccess(state: string) {
    switch (state) {
      // 1.
      case 'CONFIRMATION_REQ':
        this._toastService.create({
          msg: [
            'Confirmation of your account is required - please check your email for a verification link',
          ],
          theme: 'error',
          icon: 'error',
        });
        return '/forgot-password';

      // 2.
      case 'ADDRESS_CHECK_REQ':
        return 'my-account/verification/address';

      // 3.
      case 'PLAN_CHECK_REQ':
        return 'my-account/verification/plan-number';

      // 4.
      case 'PASSWORD_RESET_REQ':
        this._toastService.create({
          title: '',
          msg: ['An email with a verification link has been sent'],
          theme: 'error',
          icon: 'error',
        });
        return '';

      // 5.
      case 'COMPLETE':
        return 'my-plans';

      // 6.
      case 'LOCKED':
        return '404'; // TODO to be confirmed

      // 7.
      default:
        return '404';
    }
  }

  /**
   * handleUserStateError
   * Handles Routing AND / OR Toast Service error messages
   * This function is SOMETIMES used with this._router.navigateByUrl('')
   * E.G.:  this._router.navigateByUrl(
              this._loginHelper.handleUserStateError(err.err_type)
            );
   * SCENARIOS:
   *   1: Error on login page my-account && forgotten password
   *   2: Error on create-account) page
   *   3: Error on verify-email when user is already confirmed
   *   4: Error on password-reset) page - invalid code.
   *   5: Error on password-reset
   *   6: Generic error - too many requests
   *   7: Catch all
   */
  public handleUserStateError(state: string) {
    switch (state) {
      // 1.
      case 'UserNotFoundException':
        this._toastService.create({
          msg: ['User does not exist '],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 2.
      case 'UsernameExistsException':
        this._toastService.create({
          msg: ['User already exists'],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 3.
      case 'InvalidParameterException':
        this._toastService.create({
          msg: ['User is already confirmed - please go to login page '],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 4.
      case 'CodeMismatchException':
      case 'ExpiredCodeException':
        this._toastService.create({
          msg: [
            'Your code has expired or is invalid - a new verification link has been sent - please check your email',
          ],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 5.
      case 'NotAuthorizedException':
        this._toastService.create({
          msg: ['Incorrect username or password'],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 6.
      // Geneic error
      case 'LimitExceededException':
        this._toastService.create({
          msg: [
            'LimitExceededException - too many requests - handle this accordingly',
          ],
          theme: 'error',
          icon: 'error',
        });
        return;

      // 7.
      default:
        this._toastService.create({
          msg: ['Unexpected Error - handle this accordingly'],
          theme: 'error',
          icon: 'error',
        });
        return '404';
    }
  }

  /**
   * handleDataLayer
   * simple helper func
   */
  public handleDataLayer(data: LoginDataLayer) {
    this._analytics.push(data);
  }
}
