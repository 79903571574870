import { AfterViewInit, Directive, Input } from '@angular/core';
import { GAService } from '@domgen/dgx-fe-ga';

@Directive({
  selector: '[dgxLoadedGADirective]',
})
export class LoadedGADirective implements AfterViewInit {
  @Input() dgxLoadedGADirective: { [key: string]: string };

  constructor(private gaService: GAService) {}
  ngAfterViewInit(): void {
    this.gaService.tag(this.dgxLoadedGADirective);
  }
}
