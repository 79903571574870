import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AnalyticsService,
  GenericError,
} from '@athome-myaccount/my-account/data-access-shared';
import {
  ModalDialogService,
  OpenAlertModal,
  OpenUsernameExistsModal,
} from '@athome-myaccount/my-account/util-core';
import { LoginRoutesEnum } from '@athome-myaccount/my-account/util-login';
import {
  AlertType,
  ModalAlertData,
  User,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, pipe } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { MyAccountIdentityHttpService } from '../../services/my-account-identity-http.service';
import { LoginTrackingService } from '../../services/new-login-and-reg-tracking.service';
import * as newLoginAndRegActions from '../actions/new-login-and-reg.actions';
import { NewLoginAndRegFacadeService } from '../facade/new-login-and-reg.facade';

enum ConfirmPasswordFailureUserState {
  CODE_EXPIRED = 'CODE_EXPIRED',
  CODE_INCORRECT = 'CODE_INCORRECT',
}

@Injectable()
export class NewLoginAndRegEffects {
  constructor(
    private actions$: Actions,
    private identityService: MyAccountIdentityHttpService,
    private router: Router,
    private store: Store<any>,
    private modalDialogService: ModalDialogService,
    private loginTrackingService: LoginTrackingService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private facade: NewLoginAndRegFacadeService,
    private recaptchaService: ReCaptchaV3Service,
    private analyticsService: AnalyticsService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  resendNewSignUpAccountEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.ResendNewSignUpAccountEmail>(
        newLoginAndRegActions.NewLoginAndRegActionTypes
          .ResendNewSignUpAccountEmail
      ),
      switchMap((action: newLoginAndRegActions.ResendNewSignUpAccountEmail) => {
        this.spinner.show();

        return this.identityService.signUpNewAccount(action.payload.email).pipe(
          map((response: any) => {
            this.spinner.hide();

            return new newLoginAndRegActions.ResendNewSignUpAccountEmailSuccess(
              {
                success: response,
              }
            );
          }),
          catchError((error: any) => {
            this.spinner.hide();
            return of(
              new newLoginAndRegActions.ResendNewSignUpAccountEmailFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  resendNewSignUpAccountEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.ResendNewSignUpAccountEmailSuccess>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .ResendNewSignUpAccountEmailSuccess
        ),
        map(({ payload }) => {
          this.spinner.hide();
        })
      ),
    { dispatch: false }
  );

  resendNewSignUpAccountEmailFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.ResendNewSignUpAccountEmailFailure>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .ResendNewSignUpAccountEmailFailure
        ),
        map(({ payload }) => {
          this.spinner.hide();
          if (payload?.error?.error?.status === 'USERNAME_EXISTS') {
            this.modalDialogService.closeModal();
            this.modalDialogService.openModal(new OpenUsernameExistsModal());
          } else {
            this.store.dispatch(new GenericError());
          }
        })
      ),
    { dispatch: false }
  );

  signUpNewAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.SignUpNewAccount>(
        newLoginAndRegActions.NewLoginAndRegActionTypes.SignUpNewAccount
      ),
      switchMap((action: newLoginAndRegActions.SignUpNewAccount) => {
        this.loginTrackingService.userClicksContinueOnCreateAccountPageEvent();
        this.spinner.show();

        return this.identityService.signUpNewAccount(action.payload.email).pipe(
          map((response: any) => {
            return new newLoginAndRegActions.SignUpNewAccountSuccess({
              success: response,
            });
          }),
          catchError((error: any) => {
            return of(
              new newLoginAndRegActions.SignUpNewAccountFailure({ error })
            );
          })
        );
      })
    )
  );

  signUpNewAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.SignUpNewAccountSuccess>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .SignUpNewAccountSuccess
        ),
        map(({ payload }) => {
          this.spinner.hide();
          this.router.navigateByUrl(LoginRoutesEnum.VERIFY_EMAIL_PAGE);
        })
      ),
    { dispatch: false }
  );

  signUpNewAccountFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.SignUpNewAccountFailure>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .SignUpNewAccountFailure
        ),
        map(({ payload }) => {
          this.spinner.hide();
          if (payload?.error?.error?.status === 'USERNAME_EXISTS') {
            this.modalDialogService.openModal(new OpenUsernameExistsModal());
          } else {
            this.store.dispatch(new GenericError());
          }
        })
      ),
    { dispatch: false }
  );

  matchUserBasedOnAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.MatchUserBasedOnAddress>(
        newLoginAndRegActions.NewLoginAndRegActionTypes.MatchUserBasedOnAddress
      ),
      switchMap((action: newLoginAndRegActions.MatchUserBasedOnAddress) => {
        return this.identityService
          .signUpAndMatchOnAddress(action.payload.payload)
          .pipe(
            map((response: any) => {
              return new newLoginAndRegActions.MatchUserBasedOnAddressSuccess({
                success: response,
              });
            }),
            catchError((error: any) => {
              return of(
                new newLoginAndRegActions.MatchUserBasedOnAddressFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  matchUserBasedOnAddressSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.MatchUserBasedOnAddressSuccess>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .MatchUserBasedOnAddressSuccess
        ),
        map(({ payload }) => {
          this.loginTrackingService.userSuccessfullyVerifedAddressOnAccountCreationEvent();
          this.loginTrackingService.successfullyCreatedAccountEvent();
          const awsIdtCookie = this.cookieService.get('AWS_IDT');

          if (awsIdtCookie) {
            const decodedUser: User = jwt_decode(awsIdtCookie);
            this.store.dispatch(
              new newLoginAndRegActions.NewLogIn({ decodedUser })
            );
          }

          this.router.navigateByUrl('my-plans');
        })
      ),
    { dispatch: false }
  );

  matchUserBasedOnAddressFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.MatchUserBasedOnAddressFailure>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .MatchUserBasedOnAddressFailure
        ),
        map(({ payload }) => {
          this.loginTrackingService.userFailedToVerifyAddressOnAccountCreationEvent();
        })
      ),
    { dispatch: false }
  );

  matchUserBasedOnPlanNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.MatchUserBasedOnPlanNumber>(
        newLoginAndRegActions.NewLoginAndRegActionTypes
          .MatchUserBasedOnPlanNumber
      ),
      switchMap((action: newLoginAndRegActions.MatchUserBasedOnPlanNumber) => {
        return this.identityService
          .matchPersonalDetails(action.payload.payload)
          .pipe(
            map((response: any) => {
              return new newLoginAndRegActions.MatchUserBasedOnPlanNumberSuccess(
                {
                  success: response,
                }
              );
            }),
            catchError((error: any) => {
              return of(
                new newLoginAndRegActions.MatchUserBasedOnPlanNumberFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  matchUserBasedOnPlanNumberSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.MatchUserBasedOnPlanNumberSuccess>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .MatchUserBasedOnPlanNumberSuccess
        ),
        map(({ payload }) => {
          /* tslint:disable:no-empty */
        })
      ),
    { dispatch: false }
  );

  matchUserBasedOnPlanNumberFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.MatchUserBasedOnPlanNumberFailure>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .MatchUserBasedOnPlanNumberFailure
        ),
        map(({ payload }) => {
          /* tslint:disable:no-empty */
        })
      ),
    { dispatch: false }
  );

  resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.ResetUserPassword>(
        newLoginAndRegActions.NewLoginAndRegActionTypes.ResetUserPassword
      ),
      switchMap((action: newLoginAndRegActions.ResetUserPassword) => {
        this.spinner.show();
        return this.identityService
          .resetUserPassword(action.payload.email)
          .pipe(
            map((response: any) => {
              this.spinner.hide();
              return new newLoginAndRegActions.ResetUserPasswordSuccess({
                success: response,
              });
            }),
            catchError((error: any) => {
              this.spinner.hide();
              return of(
                new newLoginAndRegActions.ResetUserPasswordFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  confirmPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.ResetPasswordConfirm>(
        newLoginAndRegActions.NewLoginAndRegActionTypes.ResetPasswordConfirm
      ),
      switchMap((action: newLoginAndRegActions.ResetPasswordConfirm) => {
        return this.identityService
          .passwordResetConfirm(
            action.payload.email,
            action.payload.confirmationCode,
            action.payload.password
          )
          .pipe(
            map((response: any) => {
              return new newLoginAndRegActions.ResetPasswordConfirmSuccess({
                success: response,
              });
            }),
            catchError((error: any) => {
              return of(
                new newLoginAndRegActions.ResetPasswordConfirmFailure({
                  error,
                  payload: action.payload,
                })
              );
            })
          );
      })
    )
  );

  confirmPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.ResetPasswordConfirmFailure>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .ResetPasswordConfirmFailure
        ),
        tap(({ payload }: any) => {
          let modalData: ModalAlertData;
          let params: any;

          const userState = payload?.error?.error?.additionalInfo?.userState;

          if (userState === ConfirmPasswordFailureUserState.CODE_EXPIRED) {
            modalData = {
              title: 'This link has expired',
              content: {
                content:
                  'We’ve just emailed you a new link. This one is either too old or was already used.',
              },
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };

            params = {
              email: payload?.payload?.email,
              is_sign_up: false,
            };
          } else if (
            userState === ConfirmPasswordFailureUserState.CODE_INCORRECT
          ) {
            const errorMsg =
              'Something went wrong with the link you used, so we’ve just emailed you a new one. The email can take 5 minutes to arrive. ';
            const actionMsg =
              'If it’s not in your inbox, check your spam or junk folder.';
            modalData = {
              title: 'Check your inbox',
              content: {
                content: errorMsg + actionMsg,
              },
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };

            params = {
              email: payload?.payload?.email,
              is_sign_up: false,
            };
          } else {
            modalData = {
              title: 'SOMETHING WENT WRONG',
              content: {
                content:
                  'An unexpected error has occurred. Please try again later',
              },
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };
          }

          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );

          if (params) {
            this.store.dispatch(
              new newLoginAndRegActions.ResetUserPassword(params)
            );
          }
        })
      ),
    { dispatch: false }
  );

  requestActivationAutoAccountApiResponse$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.RequestActivationAutoAccount>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .RequestActivationAutoAccount
        ),
        switchMap(
          (action: newLoginAndRegActions.RequestActivationAutoAccount) => {
            this.spinner.show();

            return this.identityService
              .requestActivationAutoAccount(action.payload.email)
              .pipe(
                map((response: any) => {
                  this.spinner.hide();
                  return new newLoginAndRegActions.RequestActivationAutoAccountSuccess(
                    {
                      success: response,
                    }
                  );
                }),
                catchError((error: any) => {
                  this.spinner.hide();
                  return of(
                    new newLoginAndRegActions.RequestActivationAutoAccountFailure(
                      {
                        error,
                      }
                    )
                  );
                })
              );
          }
        )
      )
  );

  requestActivationAutoAccountApiResponseFailure$: Observable<Action> =
    createEffect(
      () =>
        this.actions$.pipe(
          ofType<newLoginAndRegActions.RequestActivationAutoAccountFailure>(
            newLoginAndRegActions.NewLoginAndRegActionTypes
              .RequestActivationAutoAccountFailure
          ),
          pipe(
            map((action: any) => action.payload),
            tap((payload: any) => {
              if (payload?.error?.error?.status === 'ALREADY_ACTIVATED') {
                this.router.navigateByUrl(LoginRoutesEnum.LOGIN_PAGE);
              } else {
                const httpErrorDialog = {
                  title: 'SOMETHING WENT WRONG',
                  content: {
                    content:
                      'An unexpected error has occurred. Please try again later',
                  },
                  alertType: AlertType.ERROR,
                  closeButtonLabel: 'Close',
                };
                this.modalDialogService.openModal(
                  new OpenAlertModal(httpErrorDialog, '600px', '200px', true)
                );
              }
            })
          )
        ),
      { dispatch: false }
    );

  confirmActivationAutoAccountApiResponse$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.ConfirmActivationAutoAccount>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .ConfirmActivationAutoAccount
        ),
        switchMap(
          (action: newLoginAndRegActions.ConfirmActivationAutoAccount) => {
            return this.identityService
              .confirmActivationAutoAccount(action.payload)
              .pipe(
                map((response: any) => {
                  return new newLoginAndRegActions.ConfirmActivationAutoAccountSuccess(
                    {
                      success: response,
                    }
                  );
                }),
                catchError((error: any) => {
                  return of(
                    new newLoginAndRegActions.ConfirmActivationAutoAccountFailure(
                      {
                        error,
                        payload: action.payload,
                      }
                    )
                  );
                })
              );
          }
        )
      )
  );

  confirmActivationAutoAccountApiResponseFailure$: Observable<Action> =
    createEffect(
      () =>
        this.actions$.pipe(
          ofType<newLoginAndRegActions.ConfirmActivationAutoAccountFailure>(
            newLoginAndRegActions.NewLoginAndRegActionTypes
              .ConfirmActivationAutoAccountFailure
          ),
          pipe(
            map((action) => action.payload),
            tap((payload: any) => {
              if (payload.error.error.status === 'CODE_EXPIRED') {
                const modalData: ModalAlertData = {
                  title: "The link didn't work",
                  content: {
                    content:
                      'The link may have expired. Check your emails for the new link that we’ve just sent you.',
                  },
                  alertType: AlertType.ERROR,
                  closeButtonLabel: 'Close',
                };
                this.modalDialogService.openModal(
                  new OpenAlertModal(modalData, '600px', '200px', true)
                );
                this.store.dispatch(
                  new newLoginAndRegActions.RequestActivationAutoAccount({
                    email: payload.payload.email,
                    is_sign_up: false,
                  })
                );
              } else if (
                payload?.error?.error?.status === 'ALREADY_ACTIVATED'
              ) {
                this.router.navigateByUrl(LoginRoutesEnum.LOGIN_PAGE);
              } else {
                const httpErrorDialog = {
                  title: 'SOMETHING WENT WRONG',
                  content: {
                    content:
                      'An unexpected error has occurred. Please try again later',
                  },
                  alertType: AlertType.ERROR,
                  closeButtonLabel: 'Close',
                };
                this.modalDialogService.openModal(
                  new OpenAlertModal(httpErrorDialog, '600px', '200px', true)
                );
              }
            })
          )
        ),
      { dispatch: false }
    );

  verifyActivationLink$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<newLoginAndRegActions.VerifyActivationLink>(
        newLoginAndRegActions.NewLoginAndRegActionTypes.VerifyActivationLink
      ),
      switchMap((action: newLoginAndRegActions.VerifyActivationLink) => {
        return this.identityService.verifyActivationLink(action.payload).pipe(
          map((response: any) => {
            return new newLoginAndRegActions.VerifyActivationLinkSuccess({
              success: response,
            });
          }),
          catchError((error: any) => {
            return of(
              new newLoginAndRegActions.VerifyActivationLinkFailure({
                error,
                payload: action.payload,
              })
            );
          })
        );
      })
    )
  );

  verifyActivationLinkFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<newLoginAndRegActions.VerifyActivationLinkFailure>(
          newLoginAndRegActions.NewLoginAndRegActionTypes
            .VerifyActivationLinkFailure
        ),
        pipe(
          map((action) => action.payload),
          tap((payload: any) => {
            if (
              payload?.error?.error?.status ===
              UserStateTypeEnum.ALREADY_ACTIVATED
            ) {
              this.router.navigate(['my-account']);

              const modalDataIncorrect: ModalAlertData = {
                title: 'Your account already exists',
                content: {
                  content:
                    'It looks like you already have an account linked to your email address. You can log in with your email and password now.',
                },
                alertType: AlertType.ERROR,
                closeButtonLabel: 'Close',
              };
              // this is a complete hack to stop it showing 2 modals
              // this happens when you resend the email and it comes back with an error
              // this whole design needs to be redesigned

              this.modalDialogService
                .getModal()
                .pipe(take(1))
                .subscribe((model) => {
                  if (!model) {
                    this.analyticsService.push({
                      event: 'genericGAEvent',
                      eventCategory: 'user-registration-journey',
                      eventAction: 'account-creation-link-already-used',
                      eventLabel: 'modal-shown',
                    });
                    this.modalDialogService.openModal(
                      new OpenAlertModal(
                        modalDataIncorrect,
                        '600px',
                        '200px',
                        true,
                        () => {
                          this.analyticsService.push({
                            event: 'genericGAEvent',
                            eventCategory: 'user-registration-journey',
                            eventAction: 'account-creation-link-already-used',
                            eventLabel: 'go-to-login',
                          });
                        }
                      )
                    );
                  }
                });
            } else if (
              payload?.error?.error?.status !== UserStateTypeEnum.CODE_EXPIRED
            ) {
              this.store.dispatch(new GenericError());
            }
          })
        )
      ),
    { dispatch: false }
  );
}
