import { AccordionComponent } from './accordion/accordion.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeroComponent } from './hero/hero.component';
import { LoaderComponent } from './loader/loader.component';
import { SelectComponent } from './select/select.component';
import { SvgComponent } from './svg/svg.component';

// child components
import { DashBoardErrorComponent } from '../error-handling/containers/dashboard-error/dashboard-error';
import {
  AccordionHeaderComponent,
  AccordionTabComponent,
  AccordionTemplateDirective,
} from './accordion/accordion-tab/accordion-tab.component';
import { CheckComponent } from './check/check.component';
import { CrossSellPlumbingAndDrainageComponent } from './cross-sell/plumbing-and-drainage/cross-sell-plumbing-and-drainage.component';

export const components: any[] = [
  AccordionComponent,
  AccordionTemplateDirective,
  AccordionHeaderComponent,
  AccordionTabComponent,
  FooterComponent,
  HeroComponent,
  HeaderComponent,
  LoaderComponent,
  SelectComponent,
  SvgComponent,
  DashBoardErrorComponent,
  CrossSellPlumbingAndDrainageComponent,
  CheckComponent,
];

export * from './accordion/accordion-tab/accordion-tab.component';
export * from './accordion/accordion.component';
export * from './check/check.component';
export * from './cross-sell/plumbing-and-drainage/cross-sell-plumbing-and-drainage.component';
export * from './footer/footer.component';
export * from './header/header.component';
export * from './hero/hero.component';
export * from './loader/loader.component';
export * from './select/select.component';
export * from './svg/svg.component';
