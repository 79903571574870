import { Injectable } from '@angular/core';
import { VerificationCodePayload } from '@dgx-auth-lib';
import { select, Store } from '@ngrx/store';
import { RemoteData } from 'ngx-remotedata';
import { Observable } from 'rxjs';

import {
  LogOut,
  RequestVerificationCode,
  ResetUserResponses,
  VerifyConfirmationCode,
  VerifyUserEmailInbox,
} from './user.actions';
import { UserState } from './user.reducer';
import {
  selectCreateNewAccountFormCredentials,
  selectLoadingState,
  selectLoginFormCredentials,
  selectVerificationCodeResponse,
  selectVerifyUserEmailInboxResponse,
} from './user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserFacadeService {
  loginFormCredentials$: Observable<any> = this.store.pipe(
    select(selectLoginFormCredentials)
  );
  createNewAccountFormCredentials$: Observable<any> = this.store.pipe(
    select(selectCreateNewAccountFormCredentials)
  );

  verifyUserEmailInboxRequest$: Observable<RemoteData<any, Error>> =
    this.store.pipe(select(selectVerifyUserEmailInboxResponse));

  requestVerificationCodeResponse$: Observable<RemoteData<any, Error>> =
    this.store.pipe(select(selectVerificationCodeResponse));

  selectLoadingState$: Observable<boolean> = this.store.pipe(
    select(selectLoadingState)
  );

  constructor(private store: Store<UserState>) {}

  verifyUserEmailInbox(params: VerificationCodePayload) {
    this.store.dispatch(new VerifyUserEmailInbox(params));
  }

  verifyConfirmationCode(params: VerificationCodePayload) {
    this.store.dispatch(new VerifyConfirmationCode(params));
  }

  requestVerificationCode(email: string) {
    return this.store.dispatch(new RequestVerificationCode({ email }));
  }

  resetUserResponses() {
    this.store.dispatch(new ResetUserResponses());
  }

  resetUser() {
    this.store.dispatch(new LogOut());
  }
}
