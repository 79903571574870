import { MockUtils } from './mock-utils';

export class MockName extends MockUtils {
  static #firstNames = [
    'Christian',
    'Gabriel',
    'Benjamin',
    'Jonathan',
    'Tyler',
    'Samuel',
    'Nicholas',
    'Gavin',
    'Dylan',
    'Jackson',
    'Brandon',
    'Caleb',
    'Hayden',
    'Miley',
    'Katie',
    'Jade',
    'Jordan',
    'Gabriela',
    'Amy',
    'Angela',
    'Melissa',
  ];

  static #lastNames = [
    'Kennedy',
    'Wells',
    'Alvarez',
    'Woods',
    'Mendoza',
    'Castillo',
    'Olson',
    'Webb',
    'Washington',
    'Tucker',
    'Freeman',
    'Burns',
    'Henry',
    'Vasquez',
    'Snyder',
    'Simpson',
    'Crawford',
    'Jimenez',
    'Porter',
    'Palmer',
    'Robertson',
    'Black',
    'Holmes',
    'Stone',
    'Meyer',
    'Boyd',
    'Mills',
    'Warren',
    'Fox',
    'Rose',
    'Rice',
    'Moreno',
    'Schmidt',
    'Patel',
    'Ferguson',
    'Nichols',
    'Herrera',
    'Medina',
    'Ryan',
    'Fernandez',
    'Weaver',
    'Daniels',
    'Stephens',
    'Gardner',
    'Payne',
    'Kelley',
    'Dunn',
    'Pierce',
    'Arnold',
    'Tran',
    'Spencer',
    'Peters',
    'Hawkins',
    'Grant',
    'Hansen',
    'Castro',
  ];

  static firstName = (): string => {
    const randomNumber = this.#randomNumber(this.#firstNames.length);
    return this.#firstNames[randomNumber];
  };

  static lastName = (): string => {
    const randomNumber = this.#randomNumber(this.#lastNames.length);
    return this.#lastNames[randomNumber];
  };

  static fullName = (): string => {
    return `${this.firstName()} ${this.lastName()}`;
  };

  static #randomNumber(max: number): number {
    const randomNumber = this.generateRandomNumber();
    return Math.floor(randomNumber * max);
  }
}
