import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { ModalDialogUserIdInvalidComponent } from './modals/modal-dialog-userid-invalid/modal-dialog-userid-invalid.component';
import { ModalDialogUsernameAlreadyExistsComponent } from './modals/modal-dialog-username-already-exists/modal-dialog-username-already-exists.component';
// helpers
import { FormValidationHelper } from './helpers/form-validation.helper';
import { ModalDialogAlertComponent } from './modals/modal-dialog-alert/modal-dialog-alert.component';
import { ModalDialogConfirmComponent } from './modals/modal-dialog-confirm/modal-dialog-confirm.component';
import { ModalDialogUnsupportedBrowsersComponent } from './modals/modal-dialog-unsupported-browsers/modal-dialog-unsupported-browsers.component';
import { FormBuilderModule } from './modules/formbuilder/form-builder.module';
import { MaterialModule } from './modules/material/material.module';
// pipes
import { MyAccountFeatureCmsModule } from '@athome-my-account/my-account/cms';
import { DgxCmsModule } from '@domgen/dgx-fe-cms';
import {
  CardComponentsModule,
  SectionTitleModule,
} from '@domgen/dgx-fe-components-core';
import { DgxComponentsContentModule } from '@domgen/dgx-fe-components/content';
import { DgxComponentsGridModule } from '@domgen/dgx-fe-components/grid';
import { DgxComponentsMediaModule } from '@domgen/dgx-fe-components/media';
import { DgxQmModule } from '@domgen/dgx-fe-qm';
import { FaqComponentsModule } from '@domgen/dgx-fe-support';
import { CrossSellPlumbingAndDrainageComponent } from './components/cross-sell/plumbing-and-drainage/cross-sell-plumbing-and-drainage.component';
import { IsPrimaryCTAPipe } from './pipes/primaryCTA';
import { RouteTransformerDirective } from './pipes/routeTransform';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml';
import { ServicesModule } from './services/services.module';

@NgModule({
  declarations: [
    ModalDialogAlertComponent,
    ModalDialogConfirmComponent,
    ...fromComponents.components,
    ...fromDirectives.directives,
    RouteTransformerDirective,
    SanitizeHtmlPipe,
    IsPrimaryCTAPipe,
    ModalDialogUnsupportedBrowsersComponent,
    ModalDialogUsernameAlreadyExistsComponent,
    ModalDialogUserIdInvalidComponent,
    CrossSellPlumbingAndDrainageComponent,
  ],
  exports: [
    ...fromComponents.components,
    ...fromDirectives.directives,
    RouteTransformerDirective,
    SanitizeHtmlPipe,
    IsPrimaryCTAPipe,
    TranslateModule,
    MaterialModule,
    ModalDialogUsernameAlreadyExistsComponent,
    ModalDialogUserIdInvalidComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ServicesModule.forRoot(),
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    FormBuilderModule,
    AngularSvgIconModule,
    DgxQmModule,
    DgxComponentsGridModule,
    DgxCmsModule,
    FaqComponentsModule,
    SectionTitleModule,
    DgxComponentsMediaModule,
    DgxComponentsContentModule,
    CardComponentsModule,
    MyAccountFeatureCmsModule,
  ],
  providers: [FormValidationHelper],
})
export class MyAccountFeatureSharedModule {}
