export enum LoginRoutesEnum {
  LOGIN_PAGE = '/my-account',
  VERIFY_EMAIL_PAGE = '/my-account/verify-email',
  VERIFY_EMAIL_REGISTRATION = 'my-account/verify-email?registration',
  VERIFY_PLAN_NUMBER_PAGE = 'my-account/verification/plan-number',
  MIGRATED_USER_PAGE = 'my-account/migrated-user',
  CREATE_ACCOUNT_PAGE = '/my-account/create-account',
  FORGOT_PASSWORD_PAGE = '/my-account/forgot-password',
  CREATE_NEW_ACCOUNT_PAGE = '/my-account/new-account',
  CAPTURE_PERSONAL_DETAILS = '/my-account/capture-personal-details',
  CHECK_PERSONAL_DETAILS = '/my-account/check-your-details',
  TOO_MANY_REQUESTS = '/my-account/too-many-requests',
}

export enum NewLoginAndRegRoutesEnum {
  VERIFY_EMAIL_PAGE = '/new-login-and-reg/verify-email',
  VERIFY_EMAIL_REGISTRATION = 'new-login-and-reg/verify-email?registration',
  VERIFY_PLAN_NUMBER_PAGE = 'new-login-and-reg/verification/plan-number',
  MIGRATED_USER_PAGE = 'new-login-and-reg/migrated-user',
  REQUEST_ACCOUNT_CREATION_PAGE = '/my-account/signup/request',
  REQUEST_PASSWORD_RESET_PAGE = '/my-account/passwordreset/request',
  CONFIRM_PERSONAL_DETAILS = '/my-account/confirm/personal-details',
  CONFIRM_PLAN_NUMBER = '/my-account/confirm/plan-number',
}

export enum AccountRoutesEnum {
  DASHBOARD = '/my-plans',
  MYDETAILS = '/my-account/my-details',
}
