import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import {
  ConfirmUserIdChangePayload,
  LoginApiEnum,
  NewLoginAndRegApiEnum,
  RequestVerificationLinkPayload,
  UpdateUserIdDetailsPayload,
  ValidateUserIdLink,
} from '@athome-myaccount/my-account/util-login';
import {
  ApiResponse,
  CreateAccountErrorResponse,
  CreateAccountPayload,
  CreateAccountSuccessResponse,
  LoginPayload,
  LoginSuccessResponse,
  MatchPersonalDetailsPayload,
  RequestPasswordErrorResponse,
  RequestPasswordPayload,
  RequestPasswordSuccessResponse,
  RequestVerificationCodeErrorResponse,
  RequestVerificationCodePayload,
  RequestVerificationCodeSuccessResponse,
  ResetPasswordAutoAccountPayload,
  ResetPasswordErrorResponse,
  ResetPasswordPayload,
  ResetPasswordSuccessResponse,
  SignUpAndMatchPayload,
  UserMatchErrorResponse,
  UserMatchPayload,
  UserMatchSuccessResponse,
  VerificationCodeErrorResponse,
  VerificationCodePayload,
  VerificationCodeSuccessResponse,
} from '@athome-myaccount/my-account/util-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap } from 'rxjs/operators';

@Injectable()
export class AccountService {
  constructor(
    private http: HttpClient,
    @Inject(CONFIG) private readonly env: AppConfig
  ) {}

  baseUrl = `${this.env.api}/${this.env.apiVersion}`;
  newIdentityUrl = `${this.env.identityApiUrl}/${this.env.identityApiUrlVersion}`;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
    }),
  };

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error.error); // change to error and USE CODE
  }

  createAccount(
    payload: CreateAccountPayload
  ): Observable<
    CreateAccountSuccessResponse | CreateAccountErrorResponse | any
  > {
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.CREATE_ACCOUNT_URL,
        payload,
        this.httpOptions
      )
      .pipe(
        map((response: ApiResponse<LoginSuccessResponse>) => {
          return response.result;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  refreshUserToken(): Observable<any> {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };

    return this.http
      .post(this.baseUrl + LoginApiEnum.REFRESH, mergedHttpOptions)
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  login(payload: LoginPayload): Observable<any> {
    const withCredentials = { withCredentials: true };
    const correlationIdHeader = { observe: 'response' as any };
    const mergedHttpOptions = {
      ...withCredentials,
      ...this.httpOptions,
      ...correlationIdHeader,
    };

    return this.http
      .post<any>(this.baseUrl + LoginApiEnum.LOGIN_USER_URL, payload, {
        ...mergedHttpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  requestPassword(
    payload: RequestPasswordPayload
  ): Observable<
    RequestPasswordSuccessResponse | RequestPasswordErrorResponse | any
  > {
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.REQUEST_PASSWORD_URL,
        payload,
        this.httpOptions
      )
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  resetPassword(
    payload: ResetPasswordPayload
  ): Observable<
    ResetPasswordSuccessResponse | ResetPasswordErrorResponse | any
  > {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.RESET_PASSWORD_URL,
        payload,
        mergedHttpOptions
      )
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  public verificationCode(
    payload: VerificationCodePayload
  ): Observable<
    VerificationCodeSuccessResponse | VerificationCodeErrorResponse | any
  > {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.VERIFICATION_CODE_URL,
        payload,
        mergedHttpOptions
      )
      .pipe(
        map(
          (response: ApiResponse<VerificationCodeSuccessResponse>) =>
            response.result
        ),
        catchError(this.handleError)
      );
  }

  confirmVerificationToken(email: string, token: string): Observable<any> {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };

    return this.http
      .post(
        `${this.baseUrl}${LoginApiEnum.CONFIRM_VERIFCATION_TOKEN}`,
        { token },
        mergedHttpOptions
      )
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  signUpAndMatch(payload: SignUpAndMatchPayload): Observable<any> {
    // after validation, I am moving it to an interceptor
    return this.refreshUserToken().pipe(
      mergeMap((token: string) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            accept: 'application/json',
            'X-Recaptcha-Token': token,
          }),
        };

        return this.http
          .post(
            `${this.baseUrl}${LoginApiEnum.SIGNUP_AND_MATCH}`,
            payload,
            httpOptions
          )
          .pipe(
            map((response: any) => response.result),
            catchError(this.handleError)
          );
      })
    );
  }

  matchPersonalDetails(
    token: string,
    payload: MatchPersonalDetailsPayload
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        accept: 'application/json',
        'X-Recaptcha-Token': token,
      }),
    };

    return this.http
      .post(
        `${this.baseUrl}${LoginApiEnum.MATCH_PERSONAL_DETAILS}`,
        payload,
        httpOptions
      )
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  public requestVerificationCode(
    payload: RequestVerificationCodePayload
  ): Observable<
    | RequestVerificationCodeSuccessResponse
    | RequestVerificationCodeErrorResponse
    | any
  > {
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.REQUEST_VERIFICATION_CODE_URL,
        payload,
        this.httpOptions
      )
      .pipe(
        map(
          (response: ApiResponse<RequestVerificationCodeSuccessResponse>) =>
            response.result
        ),
        catchError(this.handleError)
      );
  }

  public resetUserPassword(email: string): Observable<ApiResponse<any>> {
    return this.http.post<any>(
      this.baseUrl + LoginApiEnum.REQUEST_PASSWORD_URL,
      { email },
      this.httpOptions
    );
  }

  private getStandardRecaptchaTokenHeaders(
    recaptchaToken: string
  ): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
      'request-source': 'DandGUK',
      'request-action': 'MyAccount',
      'X-Recaptcha-Token': recaptchaToken,
    });
  }

  public requestVerificationToken(payload: {
    email: string;
    token: string;
  }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        accept: 'application/json',
        'X-Recaptcha-Token': payload.token,
      }),
    };

    const params = {
      email: payload.email,
    };

    return this.http
      .post(
        this.baseUrl + LoginApiEnum.REQUEST_VERIFICATION_TOKEN,
        params,
        httpOptions
      )
      .pipe(
        map((response: ApiResponse<any>) => response),
        catchError(this.handleError)
      );
  }

  public resetPasswordAutoAccount(
    payload: ResetPasswordAutoAccountPayload
  ): Observable<any> {
    return this.http
      .post(this.baseUrl + LoginApiEnum.AUTO_ACCOUNT, payload, this.httpOptions)
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }
  public autoAccountCheck(payload: VerificationCodePayload): Observable<any> {
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.AUTO_ACCOUNT_CHECK,
        payload,
        this.httpOptions
      )
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  public userMatch(
    payload: UserMatchPayload
  ): Observable<UserMatchSuccessResponse | UserMatchErrorResponse | any> {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.USER_MATCH_URL,
        payload,
        mergedHttpOptions
      )
      .pipe(
        map(
          (response: ApiResponse<UserMatchSuccessResponse>) => response.result
        ),
        catchError(this.handleError)
      );
  }

  public signout(): Observable<any> {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };
    return this.http
      .post(this.baseUrl + LoginApiEnum.SIGNOUT, {}, mergedHttpOptions)
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  updatePassword(password: string): Observable<any> {
    const withCredentials = { withCredentials: true };
    const mergedHttpOptions = { ...withCredentials, ...this.httpOptions };
    const payload = {
      password,
    };
    return this.http
      .post(
        this.baseUrl + LoginApiEnum.PASSWORD_UPDATE,
        payload,
        mergedHttpOptions
      )
      .pipe(
        map((response: any) => response.result),
        catchError(this.handleError)
      );
  }

  buildTokenHeader(token: string) {
    return {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        accept: 'application/json',
        'X-Recaptcha-Token': token,
        'request-source': 'DandGUK',
      }),
    };
  }

  updateUserId(
    payload: UpdateUserIdDetailsPayload,
    token: string
  ): Observable<any> {
    const httpOptions = this.buildTokenHeader(token);

    return this.http
      .post<any>(
        `${this.env.identityApiUrl}/${this.env.identityApiUrlVersion}${NewLoginAndRegApiEnum.UPDATE_USER_ID}`,
        payload,
        httpOptions
      )
      .pipe(mapTo({}));
  }

  requestVerificationLink(
    payload: RequestVerificationLinkPayload,
    token: string
  ): Observable<any> {
    const httpOptions = this.buildTokenHeader(token);

    return this.http
      .post<any>(
        `${this.env.identityApiUrl}/${this.env.identityApiUrlVersion}${NewLoginAndRegApiEnum.REQUEST_USER_ID_VERIFICATION_LINK}`,
        payload,
        httpOptions
      )
      .pipe(mapTo({}));
  }

  confirmUserIdChange(
    payload: ConfirmUserIdChangePayload,
    token: string
  ): Observable<any> {
    const httpOptions = this.buildTokenHeader(token);

    return this.http
      .post<any>(
        `${this.env.identityApiUrl}/${this.env.identityApiUrlVersion}${NewLoginAndRegApiEnum.CONFIRM_USER_ID_CHANGE}`,
        payload,
        httpOptions
      )
      .pipe(mapTo({}));
  }

  validateUserIdLink(payload: ValidateUserIdLink, token: string) {
    const httpOptions = this.buildTokenHeader(token);

    return this.http
      .post<any>(
        `${this.env.identityApiUrl}/${this.env.identityApiUrlVersion}${NewLoginAndRegApiEnum.VALIDATE_USER_ID_LINK}`,
        payload,
        httpOptions
      )
      .pipe(mapTo({}));
  }
}
