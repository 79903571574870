import { APPLIANCES_CODE_IMAGES } from '../../constants/appliance-images';
import { Item } from '../../models/appliance.model';
import { MockUtils } from '../mock-utils/mock-utils';

export const mockItem = (): Item => {
  return {
    manufacturer: MockUtils.randomArray(['HOTPOINT', 'BEKO', 'APPLE']),
    model: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
    code: null,
    code_description: MockUtils.randomObjectElement(APPLIANCES_CODE_IMAGES),
    model_number: MockUtils.randomArray(['', MockUtils.alphaNumeric()]),
  };
};
