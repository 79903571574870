export interface ISignUpAndMatchOnAddressPayload {
  confirmationCode: string;
  email: string;
  password: string;
  name: string;
  surname: string;
  addressLine1: string;
  addressLine2: string;
  postcode: string;
}

export interface IMatchPersonalDetailsPayload {
  name: string;
  surname: string;
  addressLine1: string;
  addressLine2: string;
  postcode: string;
  planNumber: string;
}
