import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as userActions from '@athome-myaccount/my-account/data-access-login';
import {
  LoginService,
  UserState,
  selectApplicationUser,
} from '@athome-myaccount/my-account/data-access-login';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { LogoutCrossSell } from '@athome-myaccount/my-account/util-account';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import { UserStateTypeEnum } from '@athome-myaccount/my-account/util-shared';
import { Store, select } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() themeClass = '';
  @Input() domain: string = this.config.domesticAndGeneralUrl;
  navOpen: boolean;

  user$ = merge(
    of(this.loginService.decodeUserFromJWTCookie()),
    this.store.pipe(select(selectApplicationUser))
  );

  isUserStatusComplete$ = this.user$.pipe(
    map((user) => this.isUserStatusComplete(user)),
    debounceTime(10)
  );

  constructor(
    private store: Store<UserState>,
    private router: Router,
    private loginService: LoginService,
    @Inject(CONFIG) private readonly config: AppConfig,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.themeClass = `header ${this.themeClass}`;
  }

  isUserStatusComplete(user) {
    if (!user) {
      return false;
    }

    return (
      user['custom:user_state'] === UserStateTypeEnum.COMPLETE ||
      this.loginService.getUserVerificationCompleteStatus()
    );
  }

  logout() {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventAction: 'interaction',
      eventCategory: this.getCurrentPage(),
      eventLabel: 'log out',
    });
    this.store.dispatch(new LogoutCrossSell());
    this.store.dispatch(new userActions.LogOut());
  }

  onNavOpenClick() {
    this.navOpen = !this.navOpen;
    this.sendGaEvent(
      this.navOpen ? 'person icon - expand' : 'person icon - collapse'
    );
  }

  onMouseLeaveHeader() {
    if (this.navOpen) {
      this.navOpen = !this.navOpen;
    }
  }

  onHomeClick() {
    this.sendGaEvent('home icon');
    this.router.navigateByUrl('my-plans');
  }

  onMyDetailsClick() {
    this.sendGaEvent('my details');
    this.router.navigateByUrl('my-account/my-details');
  }

  onDGLogoClick() {
    this.sendGaEvent('dandg logo');
    window.location.href = 'https://www.domesticandgeneral.com';
  }

  getCurrentPage() {
    const routes = this.router.url.split('/');
    return routes[routes.length - 1];
  }

  sendGaEvent(label) {
    this.analyticsService.push({
      event: 'genericGAEvent',
      eventAction: 'interaction',
      eventCategory: this.getCurrentPage(),
      eventLabel: label,
    });
  }
}
