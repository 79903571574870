import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RemoteData } from 'ngx-remotedata';
import { Observable } from 'rxjs';
import {
  IMatchPersonalDetailsPayload,
  ISignUpAndMatchOnAddressPayload,
} from '../../models/api-payloads.model';
import {
  IMatchPersonalDetailsResponse,
  ISignUpAndMatchAddressResponse,
  ISignUpNewAccountResponse,
} from '../../services/my-account-identity-http.service';
import * as newLoginAndRegActions from '../actions/new-login-and-reg.actions';
import * as fromNewLoginAndRegSelectors from '../selectors/new-login-and-reg.selectors';
import { NewLoginAndRegState } from './../reducers/new-login-and-reg.reducer';

@Injectable({
  providedIn: 'root',
})
export class NewLoginAndRegFacadeService {
  constructor(private store: Store<NewLoginAndRegState>) {}

  selectVerifyActivationLinkApiResponse$: Observable<any> = this.store.pipe(
    select(fromNewLoginAndRegSelectors.selectVerifyActivationLinkApiResponse)
  );

  signUpNewAccountApiResponse$: Observable<
    RemoteData<ISignUpNewAccountResponse, HttpErrorResponse>
  > = this.store.pipe(
    select(fromNewLoginAndRegSelectors.selectSignUpNewAccountApiResponse)
  );

  selectResetUserPasswordApiResponse$: Observable<any> = this.store.pipe(
    select(fromNewLoginAndRegSelectors.selectResetUserPasswordApiResponse)
  );

  selectConfirmPasswordResetApiResponse$: Observable<any> = this.store.pipe(
    select(fromNewLoginAndRegSelectors.selectConfirmPasswordResetApiResponse)
  );

  selectRequestActivationAutoAccountApiResponse$: Observable<any> =
    this.store.pipe(
      select(
        fromNewLoginAndRegSelectors.selectRequestActivationAutoAccountApiResponse
      )
    );

  selectConfirmActivationAutoAccountApiResponse$: Observable<any> =
    this.store.pipe(
      select(
        fromNewLoginAndRegSelectors.selectConfirmActivationAutoAccountApiResponse
      )
    );

  matchUserBasedOnPlanNumberApiResponse$: Observable<
    RemoteData<IMatchPersonalDetailsResponse, HttpErrorResponse>
  > = this.store.pipe(
    select(
      fromNewLoginAndRegSelectors.selectMatchUserBasedOnPlanNumberApiResponse
    )
  );

  matchUserBasedOnAddressApiResponse$: Observable<
    RemoteData<ISignUpAndMatchAddressResponse, HttpErrorResponse>
  > = this.store.pipe(
    select(fromNewLoginAndRegSelectors.selectMatchUserBasedOnAddressApiResponse)
  );

  signUpNewAccount(payload: { email: string }) {
    this.store.dispatch(new newLoginAndRegActions.SignUpNewAccount(payload));
  }

  matchUserBasedOnPlanNumber(payload: {
    recaptchaToken: string;
    payload: IMatchPersonalDetailsPayload;
  }) {
    this.store.dispatch(
      new newLoginAndRegActions.MatchUserBasedOnPlanNumber(payload)
    );
  }

  matchUserBasedOnAddress(payload: {
    recaptchaToken: string;
    payload: ISignUpAndMatchOnAddressPayload;
  }) {
    this.store.dispatch(
      new newLoginAndRegActions.MatchUserBasedOnAddress(payload)
    );
  }

  resetUserPassword(payload: { recaptchaToken: string; email: string }) {
    this.store.dispatch(new newLoginAndRegActions.ResetUserPassword(payload));
  }

  confirmPassword(payload: {
    email: string;
    password: string;
    confirmationCode;
  }) {
    this.store.dispatch(
      new newLoginAndRegActions.ResetPasswordConfirm(payload)
    );
  }

  activateAutoAccount(payload: {
    email: string;
    password: string;
    confirmationCode;
  }) {
    this.store.dispatch(
      new newLoginAndRegActions.ConfirmActivationAutoAccount(payload)
    );
  }
}
