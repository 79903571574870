import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationApiService } from '../services/authentication-api.service';
import {
  AuthLibActionTypes,
  CreateAccount,
  CreateAccountFailure,
  CreateAccountSuccess,
  ForgotPassword,
  ForgotPasswordFailure,
  ForgotPasswordSuccess,
  LogIn,
  LogInFailure,
  LogInSuccess,
  RequestVerificationCode,
  RequestVerificationCodeFailure,
  RequestVerificationCodeSuccess,
  ResetPassword,
  ResetPasswordFailure,
  ResetPasswordSuccess,
  SignOut,
  SignOutFailure,
  SignOutSuccess,
  VerificationCode,
  VerificationCodeFailure,
  VerificationCodeSuccess,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private cookieService: CookieService,
    private authApiService: AuthenticationApiService
  ) {}

  LogIn$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.LogIn),
      map((action: LogIn) => action.payload),
      switchMap((payload: any) =>
        this.authApiService.login(payload).pipe(
          map(
            () =>
              new LogInSuccess(jwt_decode(this.cookieService.get('AWS_IDT')))
          ),
          catchError((error: HttpErrorResponse) => of(new LogInFailure(error)))
        )
      )
    )
  );

  ForgotPassword$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.ForgotPassword),
      map((action: ForgotPassword) => action.payload),
      switchMap((payload: any) =>
        this.authApiService.requestNewPassword(payload).pipe(
          map(() => new ForgotPasswordSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(new ForgotPasswordFailure(error))
          )
        )
      )
    )
  );

  RequestVerificationCode$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.RequestVerificationCode),
      map((action: RequestVerificationCode) => action.payload),
      switchMap((payload: any) =>
        this.authApiService.requestVerificationCode(payload).pipe(
          map(() => new RequestVerificationCodeSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(new RequestVerificationCodeFailure(error))
          )
        )
      )
    )
  );

  VerificationCode$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.VerificationCode),
      map((action: VerificationCode) => action.payload),
      switchMap((payload: any) =>
        this.authApiService.processVerificationCode(payload).pipe(
          map(() => new VerificationCodeSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(new VerificationCodeFailure(error))
          )
        )
      )
    )
  );

  ResetPassword$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.ResetPassword),
      map((action: ResetPassword) => action.payload),
      switchMap((payload: any) =>
        this.authApiService.resetPassword(payload).pipe(
          map(() => new ResetPasswordSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(new ResetPasswordFailure(error))
          )
        )
      )
    )
  );

  SignOut$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.SignOut),
      map((action: SignOut) => action),
      switchMap(() =>
        this.authApiService.logout().pipe(
          map(() => new SignOutSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(new SignOutFailure(error))
          )
        )
      )
    )
  );

  CreateAccount$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthLibActionTypes.CreateAccount),
      map((action: CreateAccount) => action.payload),
      switchMap((payload: any) =>
        this.authApiService.createAccount(payload).pipe(
          map(() => new CreateAccountSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(new CreateAccountFailure(error))
          )
        )
      )
    )
  );

  // TODO ask Chrisitian for Api documentation to check the status
  // @Effect()
  // CheckUserSession$ = this.actions.pipe(
  //   ofType(AuthLibActionTypes.LogIn),
  //   map((action: LogIn) => action.payload),
  //   switchMap((payload: any) =>
  //     this.authApiService.login(payload, payload).pipe(
  //       map(() => new LogInSuccess()),
  //       catchError((error: HttpErrorResponse) => of(new LogInFailure(error)))
  //     )
  //   )
  // );
}
