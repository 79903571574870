import { User } from '@athome-myaccount/my-account/util-shared';
import { MockName } from './mock-name';
import { MockUtils } from './mock-utils';

export class MockUser extends MockName {
  static #emailClients = ['gmail.com', 'yahoo.com', 'outlook.com', 'aol.com'];
  static userName = `${this.firstName()} ${this.lastName()}`;
  static phoneNumber = MockUtils.randomNumber().toString().slice(2, 13);
  static password = MockUtils.randomNumber()
    .toString(36)
    .replace('.', '!')
    .replace(/[a-z]/, 'Z');

  static emailAddress = (): string => {
    const randomNumber = this.#randomNumber(this.#emailClients.length);
    return `${this.firstName()}-${this.lastName()}@${
      this.#emailClients[randomNumber]
    }`;
  };

  static user = (): User => {
    return {
      sub: 'sub-value', // get proper value
      iss: 'iss-value', // get proper value
      aud: 'aud-value', // get proper value
      event_id: 'D&G 1245',
      token_use: 'token-12589',
      auth_time: MockUtils.randomNumber(),
      name: this.userName,
      exp: MockUtils.randomNumberRange(1, 200),
      iat: MockUtils.randomNumberRange(10, 5000),
      family_name: this.lastName(),
      email: this.emailAddress(),
      'custom:wcs_created_at': 'custom wcs',
      'custom:digital_id': 'digital id',
      'custom:user_state': 'user state',
      'cognito:username': 'user name',
    };
  };

  static #randomNumber(max: number): number {
    return Math.floor(MockUtils.randomNumber(max));
  }
}
