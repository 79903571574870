import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgxQmModule } from '@domgen/dgx-fe-qm';
import { ShowPasswordDirective } from '../../directives';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FieldsetComponent } from './components/fieldset/fieldset.component';
import { FormElementsComponent } from './components/form-elements/form-elements.component';
import { HintComponent } from './components/hint/hint.component';
import { InputAutoCompleteComponent } from './components/input-autocomplete/input-autocomplete.component';
import { InputComponent } from './components/input/input.component';
import { LabelComponent } from './components/label/label.component';
import { LegendComponent } from './components/legend/legend.component';
import { PasswordStrengthCheckerComponent } from './components/password-strength-checker/password-strength-checker.component';
import { RadiobuttonHintComponent } from './components/radiobutton-hint/radiobutton-hint.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { SelectComponent } from './components/select/select.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ValidationErrorFormControlComponent } from './components/validation-error-formcontrol/validation-error-formcontrol.component';
import * as fromDirectives from './directives';
import { FormsComponent } from './forms/forms.component';
import { ForbiddenValidatorDirective } from './services/forbidden.name.directive';

@NgModule({
  imports: [
    CommonModule,
    DgxQmModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    CheckboxComponent,
    RadiobuttonComponent,
    TextareaComponent,
    LabelComponent,
    FieldsetComponent,
    ButtonComponent,
    LegendComponent,
    HintComponent,
    ValidationErrorFormControlComponent,
    InputComponent,
    FormElementsComponent,
    FormsComponent,
    SelectComponent,
    RadiobuttonHintComponent,
    InputAutoCompleteComponent,
    PasswordStrengthCheckerComponent,
    ShowPasswordDirective,
    ForbiddenValidatorDirective,
    AddressLookupComponent,
    ...fromDirectives.directives,
  ],
  exports: [
    CheckboxComponent,
    RadiobuttonComponent,
    InputAutoCompleteComponent,
    PasswordStrengthCheckerComponent,
    TextareaComponent,
    LabelComponent,
    FieldsetComponent,
    ButtonComponent,
    LegendComponent,
    HintComponent,
    ValidationErrorFormControlComponent,
    InputComponent,
    FormElementsComponent,
    FormsComponent,
    SelectComponent,
    ShowPasswordDirective,
    ...fromDirectives.directives,
  ],
  providers: [DatePipe],
})
export class FormBuilderModule {}
