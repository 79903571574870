export interface MarketingPreferenceDetails {
  clientCode: string;
  clientCodeDescription: string;
  clientGroupCode: string;
  clientGroupCodeDescription: string;
  email: boolean | null;
  telephone: boolean | null;
  post: boolean | null;
  isEdited?: boolean;
}

export interface PersonDetails {
  title: string;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  landline: string;
  address: string;
  marketingPreferences: MarketingPreferenceDetails;
}
