<ng-container>
  <div *ngIf="cards" style="display: flex; flex-direction: row; gap: 2vh">
    <div *ngFor="let app of cards | cmsContent">
      <cms-repair-upsell
        *ngIf="app.cardType === 'repair'"
        [cardDetails]="app"
        [image]="app.cardImage"
      >
      </cms-repair-upsell>
      <cms-plumbing-upsell
        *ngIf="app.cardType === 'plumbing'"
        [cardDetails]="app"
        [image]="app.cardImage"
      >
      </cms-plumbing-upsell>
    </div>
  </div>
</ng-container>
