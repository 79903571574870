import {
  PaymentMethod,
  PlanState,
  PlanType,
  RepairAction,
  RepairPayment,
  RepairPaymentType,
  ServiceAction,
} from '../state/plan-detail.types';
import { Referral } from '../state/plan.model';

export interface PlanDetailViewModel {
  number: string;
  state: PlanState;
  startDate: Date;
  renewalDate: Date;
  duration: string;
  repairHistory?: RepairDetailViewModel[];
  paymentDetails: PaymentDetailViewModel;
  item: ItemViewModel;
  repairerDetails?: RepairerDetailsViewModel;
  activeRepair?: ActiveClaimDetailViewModel;
  activeService?: ActiveClaimDetailViewModel;
  multiPlanNumber?: string;
  multiPlanState?: PlanState;
  openRepairId?: string;
  openServiceId?: string;
  repairAction: RepairAction;
  serviceAction: ServiceAction;
  plan_contract_type_code?: string;
  email: string;
  planOwnerType: PlanType;
  inWarrantyContactName?: string;
  inWarrantyContactTel?: string;
  repairPayment?: RepairPayment;
  repairPaymentType?: RepairPaymentType;
  directReferral: Referral;
}

export interface ICallBackTrackingModel {
  callbackSlot: string;
  faultDate: string;
  description: string;
}

export interface RepairDetailViewModel {
  reference: string;
  claim_type?: string;
  status: string;
  callbackData?: ICallBackTrackingModel;
}

export interface PlanDetailCta {
  text: string;
  clickable: boolean;
}

export interface ActiveClaimDetailViewModel {
  date: Date;
  reference: string;
  status: string;
  additionalInformation?: string;
  rebookable: boolean;
}

export interface PaymentDetailViewModel {
  method: PaymentMethod;
  isMultiPlan: boolean;
  cardPaymentDetail: CardPaymentDetailViewModel;
  directDebitDetail: DirectDebitPaymentDetailViewModel;
}

export interface DirectDebitPaymentDetailViewModel {
  accountHolderName: string;
  accountNumber: string;
  sortCode: string;
  paymentSchedule: PaymentScheduleViewModel[];
}

export interface PaymentScheduleViewModel {
  date: Date;
  amount: string;
}

export interface CardPaymentDetailViewModel {
  fee: string;
}

export interface RepairerDetailsViewModel {
  repairer: string;
  phone: string;
  logo: string;
  email: string;
}

export interface ItemViewModel {
  code: string;
  code_description: string;
  image?: string;
  imei?: string;
  manufacturer: string;
  model?: string;
  model_number: string;
}

export interface CallbackData {
  description: string;
  faultDate: string;
  callbackSlot: string;
}

export interface OpenRepair {
  claim_type: string;
  claim_status: string;
  rebookable: boolean;
  cancelable: boolean;
  callbackData: CallbackData;
}

export function getProductName(planDetailViewModel: PlanDetailViewModel) {
  const productName = `${(
    planDetailViewModel.item.manufacturer || ''
  ).toLowerCase()} ${(
    planDetailViewModel.item.code_description || ''
  ).toLowerCase()}`;
  return productName;
}
