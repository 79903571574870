import { Inject, Injectable } from '@angular/core';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
  public isComingFromMobile: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isComingFromMobile$ = this.isComingFromMobile.asObservable();
  constructor(@Inject(CONFIG) private readonly config: AppConfig) {}
  public _scripts: ScriptModel[] = [
    {
      name: 'optimizely',
      src: 'https://cdn.optimizely.com/js/20062280733.js',
      loaded: true,
      attributeKey: null,
      attributeValue: null,
      hasError: false,
      error: '',
    },
    {
      name: 'trustpilot',
      src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      loaded: false,
      attributeKey: null,
      attributeValue: null,
      hasError: false,
      error: '',
    },
    {
      name: 'googletagmanager',
      src: 'https://www.googletagmanager.com/gtm.js?id=GTM-TZK6S7',
      loaded: true,
      attributeKey: null,
      attributeValue: null,
      hasError: false,
      error: '',
    },
    {
      name: 'chatwidget',
      src: 'https://attachuk.imi.chat/widget/js/imichatinit.js',
      loaded: false,
      attributeKey: null,
      attributeValue: null,
      hasError: false,
      error: '',
    },
    {
      name: 'cookiepro',
      src: this.config.cookieProSrc,
      loaded: false,
      attributeKey: 'data-domain-script',
      attributeValue: this.config.cookieProDomainScript,
      hasError: false,
      error: '',
    },
  ];

  loadScripts(tag: string, scripts: string[]) {
    const scriptsToLoadPromises: any[] = [];

    scripts.forEach((scriptName: string) => {
      const existingScript = this._scripts.find((s) => s.name == scriptName);
      if (existingScript && !existingScript.loaded) {
        scriptsToLoadPromises.push(this.loadScript(tag, existingScript));
      }
    });

    return Promise.all(scriptsToLoadPromises);
  }

  checkIsComingFromMobile(): boolean {
    let isComingFromMobile: boolean;
    this.isComingFromMobile$
      .pipe(take(1))
      .subscribe((isFromMobile) => (isComingFromMobile = isFromMobile));
    return isComingFromMobile;
  }

  /**
   * Lazy load a single script
   * @param tag
   * @param {ScriptModel} script
   * @param loadOnce
   * @returns {Promise<any>}
   */
  loadScript(tag, script: ScriptModel): Promise<any> {
    return new Promise((resolve, _reject) => {
      // load script tag
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = script.src;

      if (script.attributeKey && script.attributeValue) {
        scriptElement.setAttribute(script.attributeKey, script.attributeValue);
      }

      scriptElement.onload = () => {
        script.loaded = true;
        resolve('Script is resolved successfully.');
      };

      scriptElement.onerror = (error: any) => {
        script.loaded = true;
        script.hasError = true;
        script.error = 'Loaded Error:' + error.toString();
        _reject('Script is not resolved successfully.');
      };

      this._scripts.forEach((s) =>
        s.name === script.name ? { ...this._scripts, ...script } : s
      );

      document.getElementsByTagName(tag)[0].appendChild(scriptElement);
    });
  }
}

export interface ScriptModel {
  name: string;
  src: string;
  loaded: boolean;
  hasError: boolean;
  error: string;
  attributeKey?: string;
  attributeValue?: string;
}
