<form *ngIf="pageItems" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div
    [ngClass]="{
      'form-group': true,
      'form-group--error': useValidation && form.invalid
    }"
  >
    <h1 class="heading-xl">
      <span *ngIf="pageItems.caption" [class]="pageItems.caption.classes">{{
        pageItems.caption.text
      }}</span>
      {{ pageItems.header }}
    </h1>
    <div
      class="form-group"
      [class]="group.classes"
      *ngFor="let group of pageItems.groups"
    >
      <app-fieldset
        *ngIf="group.fieldset"
        [classes]="'fieldset'"
        [group]="form"
        [data]="group.fieldset"
        [validate]="useValidation"
      >
      </app-fieldset>

      <div
        [ngClass]="{
          'remember-me':
            group?.checkbox?.control === 'rememberMe' ||
            group?.checkbox?.control === 'rememberMeNewLoginAndReg'
        }"
      >
        <app-form-elements
          [group]="form"
          [validate]="useValidation"
          [data]="group"
          [groups]="data.meta.groups"
        ></app-form-elements>
        <p
          (click)="onForgotYourPasswordClick()"
          class="forgot-password"
          *ngIf="group?.checkbox?.control === 'rememberMe'"
        >
          Forgot your password?
        </p>

        <p
          (click)="onForgotYourPasswordClick()"
          class="forgot-password"
          *ngIf="group?.checkbox?.control === 'rememberMeNewLoginAndReg'"
        >
          Forgot your password?
        </p>
      </div>
    </div>
  </div>
</form>
