import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  // AppComponent,
  AoDmaFeatureShellModule,
  AoDmaFeatureShellRoutingModule,
} from '@athome-myaccount/ao-dma/feature-shell';
import {
  MyAccountFeatureSharedModule,
  UserAuthGuard,
} from '@athome-myaccount/my-account/feature-shared';
import { SharedFeatureAuthenticationModule } from '@dgx-auth-lib';
import {
  HeaderModule,
  SharedAoComponentsModule,
} from '@domgen/dgx-ui-whitelabel-components';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxSpinnerModule } from 'ngx-spinner';
import { gaTagsConfig } from './ga-tags-config/ga-tags-config';

import { environment } from '../environments/environment';

import { DgxCmsModule } from '@domgen/dgx-fe-cms';
import { DomainId } from '@domgen/dgx-fe-common';
import { DgxConfigModule } from '@domgen/dgx-fe-config';
import {
  DgxGAModule,
  GACountryCode,
  GA_CONFIG,
  mockGaHttpErrorMapping,
} from '@domgen/dgx-fe-ga';
import { DgxI18nModule } from '@domgen/dgx-fe-i18n';
import { DgxLoggingModule, LoggingLevel } from '@domgen/dgx-fe-logging';
import { DgxSeoModule } from '@domgen/dgx-fe-seo';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { AppComponent } from './app.component';

const authLibConfig: any = {
  urlsConfigJsonPath: '/config.json',
  forms: {
    loginForm: {
      emailLabel: 'Email',
      requiredEmailValidation: 'Email is required',
      incorrectEmailFormatValidation: 'You must provide a valid email address',
      passwordLabel: 'Password',
      showPasswordLabel: 'Show',
      hidePasswordLabel: 'Hide',
      requiredPasswordValidation: 'Password is required',
      logInButtonLabel: 'Log in',
      forgotPasswordLinkLabel: 'Forgot your password?',
    },
    forgotPasswordForm: {
      emailLabel: 'Email',
      requiredEmailValidation: 'Email is required',
      incorrectEmailFormatValidation: 'You must provide a valid email address',
      emailPlaceholderLabel: '',
      submitButtonLabel: 'Submit',
      showBackToLoginButton: true,
      backToLoginButtonLabel: 'Back To Login',
    },
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AoDmaFeatureShellRoutingModule,
    MyAccountFeatureSharedModule,
    SharedAoComponentsModule,
    HeaderModule,
    AoDmaFeatureShellModule.forRoot(environment, { gaTagsConfig }),
    StoreDevtoolsModule.instrument({
      name: 'Domestic & General',
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Turned off for now
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production
    // }),
    SharedFeatureAuthenticationModule.forRoot(authLibConfig),
    RecaptchaV3Module,
    NgxSpinnerModule,
    DgxGAModule.forRoot(),
    DgxCmsModule.forRoot(),
    DgxConfigModule.forRoot(),
    DgxI18nModule.forRoot(),
    DgxGAModule,
    DgxLoggingModule.forRoot({
      level: LoggingLevel.Error,
      silent: environment.production,
    }),
    DgxSeoModule.forRoot(),
  ],
  providers: [
    UserAuthGuard,
    {
      provide: GA_CONFIG,
      useValue: {
        clientBrandCode: undefined,
        countryCode: GACountryCode.GreatBritain,
        debug: false,
        domainId: DomainId.Ao,
        httpErrorMapping: mockGaHttpErrorMapping,
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
