import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'isPrimaryCTA',
})
export class IsPrimaryCTAPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any): any {
    return `btn ${value ? 'btn--primary' : 'btn--secondary'}`;
  }
}
